import React, { useEffect, useRef, useState } from 'react';
import { delay, motion, useAnimationControls } from 'framer-motion';
import SearchInput from '../../Input/SearchInput';

const Testimonial_items = ({image, title, founder}) => {
    const [ hover, setHover ] = useState(false);

    return (
        <div className='w-full h-fit cursor-pointer' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <div className='w-full h-fit flex justify-center items-center'>
                <img src={image} className={'w-full sm:w-40 h-40 object-cover rounded-md sm:rounded-full transition duration-300 ' + (hover ? 'translate-y-0 sm:-translate-y-3' : 'translate-y-0')}></img>
            </div>
            
            <p className={'notosan-semi mt-4 line-clamp-2 ' + (hover ? 'underline text-web-gold' : 'text-web-black')}>{title}</p>
            <p className={'notosan-reg text-web-black text-sm line-clamp-2 ' + (hover ? 'text-web-gold' : 'text-web-black')}>โดย {founder.join(', ')}</p>
        </div>
    )
}

const Admin_testimonial_item = (props) => {
    const [ active, setActive ] = useState(false);

    return (
        <div className='relative w-full h-full' onClick={() => setActive(!active)}>
            <motion.div className='w-full h-fit'
            variants={props.motion_obj}
            initial={'hidden'}
            animate={props.motion_control}
            custom={props.motion_index}>
                <Testimonial_items image={props.image} title={props.title} founder={props.founder}/>
            </motion.div>

            <div className={'absolute top-0 left-0 w-full h-full rounded-md flex justify-center items-center bg-black/50 z-10 ' + (active ? 'block' : 'hidden')}>
                <div className='w-fit h-fit flex gap-3'>
                    <Admin_testimonial_item_icon_favorite active={active} favorite={props.favorite} handle_clicked={props.handle_clicked_favorite}/>
                </div>
            </div>
        </div>
    )
}

const Admin_testimonial_item_icon_favorite = ({active, favorite, handle_clicked}) => {
    const [ hover, setHover ] = useState(false);

    const animation_controls = useAnimationControls();
    const ani_obj = {
        hidden: {
            opacity: 0,
            y: 0,
            transition: { duration: 0 }
        },
        visible: (index) => ({
            opacity: 1,
            y: -2,
            transition: { duration: 0.5, delay: index * 0.1 }
        })
    }

    useEffect(() => {
        animation_controls.start((active) ? "visible" : "hidden")
    }, [active]);

    return (
        <motion.div className='w-fit h-fit flex flex-col items-center cursor-pointer z-20' 
        onClick={() => {
            handle_clicked();
        }}
        onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        variants={ani_obj}
        initial={"hidden"}
        animate={animation_controls}
        custom={0}>
            <div className={'w-10 h-10 rounded-full bg-white flex justify-center items-center shadow transition duration-300 ' + (hover ? '-translate-y-1.5' : '')}>
                <div className={'w-full h-full justify-center items-center ' + (favorite ? 'flex' : 'hidden')}>
                    <box-icon name='heart' type='solid' color="#ef4444" ></box-icon>
                </div>

                <div className={'w-full h-full justify-center items-center ' + (favorite ? 'hidden' : 'flex')}>
                    <box-icon name='heart' ></box-icon>
                </div>
            </div>

            <div className={'w-fit h-fit transition duration-300 ' + (hover ? 'opacity-1' : 'opacity-0')}>
                <p className='notosan-reg text-white text-sm text-center pt-2'>ผลงานเด่น</p>
            </div>
        </motion.div>
    )
}

const ModalTestimonial_admin_add_favorite = ({isShow, handle_close, handle_submit, favor_items, items}) => {
    const animation_control = useAnimationControls();
    const ani_obj = {
        hidden: {
            opacity: 0,
            y: 10,
            transition: { duration: 0.2 }
        },
        visible: (index) => ({
            opacity: 1,
            y: 0,
            transition: { duration: 0.8, delay: index * 0.1 }
        })
    }

    const [ favorite_items, setFavorite_items ] = useState([]);
    const [ filter_items, setFilter_items ] = useState([]);
    const [ backup_search_value_for_filter, setBackup_search_value_for_filter ] = useState('');

    useEffect(() => {
        if(isShow) {
            setFavorite_items([...favor_items]);
            setFilter_items([...items]);

            //add animation into page
            animation_control.start("hidden");

            setTimeout(() => {
                animation_control.start("visible");
            }, 500);
        }
    }, [isShow]);

    useEffect(() => {
        //add animation into page
        animation_control.start("hidden");

        setTimeout(() => {
            animation_control.start("visible");
        }, 500);
    }, [favorite_items, filter_items]);

    function handle_filter_items(value) {
        //normal filter process
        let new_result = [];
                    
        if(value === '') {
            new_result = [...items];
        }
        else {
            for(let i = 0; i < items.length; i++) {
                const project_title = items[i].title;

                if(project_title.toLowerCase().includes(value)) {
                    new_result.push(items[i]);
                    continue;
                }

                const project_founder = [...items[i].founder];
                for(let j = 0; j < project_founder.length; j++) {
                    const founder_name = project_founder[j];

                    if(founder_name.toLowerCase().includes(value)) {
                        new_result.push(items[i]);
                        break;
                    }
                }
            }
        }

        setFilter_items([...new_result]);
    }

    return (
        <>
            { /* Modal confirm submit */ }
            <div className={'absolute top-0 left-0 w-full h-full z-30 ' + (isShow ? 'flex justify-center items-center' : 'hidden')}>
                { /* testimonial modal in classlist is used to remove scrollbar as code imported from css file */ }
                <div className='testimonial modal w-11/12 h-full overflow-y-scroll'>
                    <div className='relative w-full h-fit bg-white drop-shadow-md rounded-md px-6 sm:px-20 py-20 sm:py-28 my-10 sm:my-20'>
                        <h3 className='text-web-gold text-2xl notosan-med'>โครงการ/ผลงานเด่น</h3>
                        <div className='w-full h-fit grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-7 gap-x-2 sm:gap-x-5 gap-y-10 my-10'>
                            {
                                favorite_items.map((x, i) => {
                                    return (
                                        <Admin_testimonial_item 
                                        key={i}
                                        image={x.image} 
                                        title={x.title} 
                                        founder={x.founder}
                                        favorite={x.favorite === '1'} 
                                        motion_obj={ani_obj}
                                        motion_control={animation_control}
                                        motion_index={Math.ceil(i/7)}
                                        handle_clicked_favorite={() => {
                                            if(favorite_items.length === 1) {
                                                alert('ไม่สามารถลบรายการ ต้องมีโครงการ/ผลงานเด่นอย่างน้อย 1 รายการ');
                                                return;
                                            }

                                            const favorite_index = favorite_items.findIndex(x => x.id === favorite_items[i].id);
                                            let new_favorite_items = [...favorite_items];
                                            new_favorite_items.splice(favorite_index, 1);
                                            setFavorite_items(new_favorite_items);

                                            const items_index = filter_items.findIndex(x => x.id === favorite_items[i].id);
                                            let new_result = [...filter_items];
                                            new_result[items_index].favorite = '0';
                                            setFilter_items(new_result);
                                        }}/>
                                    )
                                })
                            }
                        </div>

                        <hr className='w-full py-10'/>

                        <h3 className='text-web-gold text-2xl notosan-med'>โครงการ/ผลงานทั้งหมด</h3>
                        <p className='notosan-reg text-web-black text-start mt-6'>กรุณากดเลือกรายการที่ต้องการ แล้วกดปุ่ม <span><box-icon name='heart' size='xs'></box-icon></span> เพื่อเลือกแสดงรายการดังกล่าวเป็นรายการเด่น ท่านสามารถเพิ่มได้สูงสุด 10 รายการ</p>
                        
                        <div className='w-full h-fit flex justify-end'>
                            <div className='w-full lg:w-96 h-fit pt-6'>
                                <SearchInput placeholder={'ค้นหาคำสำคัญ'} handle_search={(value) => {
                                    setBackup_search_value_for_filter(value);
                                    handle_filter_items(value);
                                }}/>
                            </div>
                        </div>
                        
                        <div className='w-full h-fit grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-7 gap-x-2 sm:gap-x-5 gap-y-10 my-10'>
                            {
                                filter_items.map((x, i) => {
                                    return (
                                        <Admin_testimonial_item 
                                            key={i}
                                            image={x.image} 
                                            title={x.title} 
                                            founder={x.founder} 
                                            favorite={x.favorite === '1'} 
                                            motion_obj={ani_obj}
                                            motion_control={animation_control}
                                            motion_index={Math.ceil(i/7)}
                                            handle_clicked_favorite={() => {
                                                const index = filter_items.findIndex(x => x.id === filter_items[i].id);
                                                let selectedItem = {...filter_items[index]};
                                                
                                                //decide like or unlike event
                                                if(selectedItem.favorite === '0') {
                                                    //like event
                                                    if(favorite_items.length === 10) {
                                                        alert('ไม่สามารถเพิ่มโครงการ/ผลงานเด่นเกิน 10 รายการ โปรดลบบางรายการเพื่อเพิ่มรายการที่ต้องการ');
                                                        return;
                                                    }
                                                    
                                                    let new_favorite_items = [...favorite_items];
                                                    selectedItem.favorite = '1';
                                                    new_favorite_items.push(selectedItem);
                                                    setFavorite_items(new_favorite_items);
                                                }
                                                else {
                                                    //unlike event
                                                    if(favorite_items.length === 1) {
                                                        alert('ไม่สามารถลบรายการ ต้องมีโครงการ/ผลงานเด่นอย่างน้อย 1 รายการ');
                                                        return;
                                                    }

                                                    let new_favorite_items = [...favorite_items];
                                                    selectedItem.favorite = '0';
                                                    new_favorite_items.splice(favorite_items.findIndex(x => x.id === filter_items[i].id), 1);
                                                    setFavorite_items(new_favorite_items);
                                                }

                                                let new_result = [...items];
                                                new_result[index].favorite = (new_result[index].favorite === '1') ? '0' : '1';
                                                setFilter_items(new_result);
                                                handle_filter_items(backup_search_value_for_filter);
                                            }}/>
                                    )
                                })
                            }
                        </div>

                        <div className={'flex flex-col-reverse sm:grid sm:grid-cols-2 gap-3 w-full h-auto min-h-12 mt-4'}>
                            <button className='notosan-med w-full h-full bg-gray-300 hover:bg-gray-500 py-3 rounded-md' onClick={handle_close}>ยกเลิก</button>
                            <button type='button' onClick={() => handle_submit(favorite_items)}
                            className={'notosan-med w-full h-full py-3 rounded-md bg-mahidol-blue hover:bg-mahidol-yellow text-white hover:text-web-black cursor-pointer'}>ยืนยัน</button>
                        </div>
                    </div>
                </div>
            </div>

            { /* Modal backdrop */ }
            <div className={'absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-20 ' + (isShow ? 'block' : 'hidden')}></div>
        </>
    )
}

export default ModalTestimonial_admin_add_favorite