import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Quote from '../Components/Other/Quote';
import title_decorate from '../../assets/decorate/title_decorate.svg';

const Text_with_separate_highlight = ({texts, indent, margin}) => {
    const [ center, setCenter ] = useState(false);
    const [ paragraph_margin, setParagraph_margin ] = useState(0);

    useEffect(() => {
        if(texts) {
            let is_center = false;

            for(let i = 0; i < texts.length; i++) {
                const text = texts[i];

                if(text.includes('<CENTER>')) {
                    is_center = true;
                    break;
                }
            }

            setCenter(is_center);
        }
    }, [texts]);

    useEffect(() => {
        if(margin) {
            setParagraph_margin(parseInt(margin) * 50);
        }
    }, [margin]);

    return (
        <div className={'w-auto h-fit my-4 ' + (center ? 'text-center' : '')} style={{marginLeft: paragraph_margin + 'px'}}>
            <p style={{textIndent: (indent ? '50px' : '0px')}}>
                {
                    texts.map((x, i) => {
                        const text_exclude_center = x.replace(/<CENTER>/g, '');

                        return (
                            <span key={i} className='w-fit h-fit'>
                                <Text_with_separate_link text={text_exclude_center} index={i}/>
                            </span>
                        )
                    })
                }
            </p>
        </div>
    )
}

 //Indent not required in this component
 //Bullet with Indent equal to normal text with fixed bullet in text
const Bullet_with_separate_highlight = ({texts, bullet, margin}) => {
    const [ paragraph_margin, setParagraph_margin ] = useState(0);

    useEffect(() => {
        if(margin) {
            setParagraph_margin(parseInt(margin) * 30);
        }
    }, [margin]);

    return (
        <div className='w-auto h-fit flex gap-1.5 my-4' style={{marginLeft: paragraph_margin + 'px'}}>
            <p className='notosan-reg text-web-black'>{bullet}</p>
            
            <p className={'grow max-w-full overflow-hidden'}>
                {
                    texts.map((x, i) => {
                        return (
                            <span key={i} className='w-auto h-fit'>
                                <Text_with_separate_link text={x} index={i}/>
                            </span>
                        )
                    })
                }
            </p>
        </div>
    )
}

const Text_with_separate_link = ({text, index}) => {
    const texts = text.split("<LINK>");

    const Link_text = ({str}) => {
        const [ obj_link, setObj_link ] = useState({});
    
        useEffect(() => {
            const texts = str.split("<URL>"); //this return 3 length array [ '', url, text ]
            
            setObj_link({
                url: texts[1],
                text: texts[2],
            })
        }, [str]);
    
        return (
            <Link to={obj_link.url} target="_blank" rel="noopener noreferrer" className={'notosan-reg text-web-gold text-wrap hover:underline'}>{obj_link.text}</Link>
        )
    }

    return (
        <>
            {
                texts.map((x, i) => {
                    return (
                        (i%2 === 1) ?
                            <span key={i}><Link_text str={x}/></span>
                        :
                            <span key={i} className={'notosan-reg ' + (index%2 === 1 ? 'text-web-gold' : 'text-web-black')}>{x}</span>
                    )
                })
            }
        </>
    )
}

const KM_paragraph = ({details, last}) => {
    const [ details_reorder_by_paragraph, setDetails_reorder_by_paragraph ] = useState([]); //Object array
    const [width, setWidth] = useState(window.innerWidth);
    
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if(details) {
            if(Array.isArray(details) && details.length > 0) {
                const sort = details.sort((a, b) => a.paragraph - b.paragraph);
                setDetails_reorder_by_paragraph([...sort]);
            }
        }
    }, [details]);

    return (
        <div className='w-full h-fit'>
            {
                details_reorder_by_paragraph.map((x, i) => {
                    const texts_highlight = x.detail?.split("<HL>");

                    return (
                        <div key={i} className='w-full h-fit'>
                            {
                                (texts_highlight && x.type === 'text') ? 
                                    <Text_with_separate_highlight texts={texts_highlight} indent={x.indent} margin={x.margin}/>
                                :
                                    null
                            }
                            
                            {
                                (texts_highlight && x.type === 'bullet_number') ? 
                                    <Bullet_with_separate_highlight texts={texts_highlight} bullet={x.bullet + '.'} margin={x.margin}/>
                                :
                                    null
                            }

                            {
                                (texts_highlight && x.type === 'bullet_point') ? 
                                    <Bullet_with_separate_highlight texts={texts_highlight} bullet={'•'} indent={x.indent} margin={x.margin}/>
                                :
                                    null
                            }

                            {
                                (texts_highlight && x.type === 'bullet_dash') ? 
                                    <Bullet_with_separate_highlight texts={texts_highlight} bullet={'-'} indent={x.indent} margin={x.margin}/>
                                :
                                    null
                            }

                            {
                                (x.type === 'space') ?
                                    <div className='w-full h-1.5'></div>
                                :
                                    null
                            }

                            {
                                (x.type === 'image') ?
                                    <div className='w-full sm:w-4/6 h-fit mx-auto overflow-visible mt-14 mb-4'>
                                        <img src={x.url} className='w-full h-auto object-cover rounded-sm' style={{maxHeight: '500px'}}/>
                                        
                                        {
                                            (x.detail) ? 
                                                <p className='w-full notosan-reg text-web-black/70 text-sm text-center mt-4'>{x.detail}</p>
                                            :
                                                null
                                        }
                                    </div>
                                :
                                    null
                            }

                            {
                                (x.type === 'quote') ?
                                    <div className='w-4/6 h-fit mx-auto mt-20'>
                                        <img src={title_decorate} className='w-28 h-auto mx-auto mt-4 mb-8'/>
                                        <Quote quote={x.detail}/>
                                    </div>
                                :
                                    null
                            }

                            {
                                (x.type === 'video') ?
                                    <div className='w-full h-fit mt-14 mb-4'>
                                        <div className='h-fit mx-auto' style={{maxWidth: '840px', height: ((width > 500) ? '473px' : '200px')}}>
                                            <iframe width="100%" height="100%" src={x.url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                        
                                        {
                                            (x.detail) ? 
                                                <p className='w-full notosan-reg text-web-black/70 text-sm text-center mt-4'>{x.detail}</p>
                                            :
                                                null
                                        }
                                    </div>
                                :
                                    null
                            }
                        </div>
                    )
                })
            }

            {
                (last) ?
                    null
                :
                    <div className='w-full h-fit flex justify-center items-center'>
                        <hr className='w-3/4 mt-12'/>
                    </div>
            }
        </div>
    )
}

export default KM_paragraph