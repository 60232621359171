import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import default_items from '../Admin/Admin_KM/KM_list_new';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import cover from '../../assets/cover/cover_news.png';
import KM_paragraph from './KM_paragraph';
import Cover from '../Components/Header/Cover';

const Author_name = ({info, last}) => {
    const Author_name_with_modal = ({info}) => {
        const [ hover, setHover ] = useState(false);
        const [ position_right, setPosition_right ] = useState(false); //popover is placed on the right, change to bottom when component is place on right of the screen
        const ref = useRef(null);
    
        useEffect(() => {
            const handlePosition = () => {
                if(ref.current) {
                    const rect = ref.current.getBoundingClientRect();
                    const screenWidth = window.innerWidth;
    
                    if(Math.abs(screenWidth - rect.right) < 300) {
                        setPosition_right(false);
                    }
                    else {
                        setPosition_right(true);
                    }
                }
            }
    
            setPosition_right(false);
            handlePosition();
            window.addEventListener('resize', handlePosition);
    
            return () => window.removeEventListener('resize', handlePosition);
        }, []);
    
        return (
            <span className='relative'>
                <span ref={ref} className='inline-block notosan-med text-web-gold cursor-pointer hover:text-web-gold-bold hover:underline'
                onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>{info.prefix + info.name}</span>
                
                <div className={'absolute translate-y-full w-fit h-fit ' + (hover ? 'z-10 ' : 'z-0 ') + (position_right ? 'bottom-0 left-0' : 'bottom-0 right-0')}>
                    <div className={'w-72 h-fit bg-white border border-gray-200 drop-shadow-md rounded-md px-4 py-5 mt-2 transition duration-300 ' + (hover ? 'opacity-100' : 'opacity-0')}>
                        <div className='w-14 h-14 min-w-14 rounded-full overflow-hidden'>
                            <img src={info.image} className='w-full h-full object-cover'/>
                        </div>
    
                        <p className='notosan-semi text-web-black my-2'>{info.prefix + info.name}</p>
                        <p className='notosan-reg text-web-black/50 text-xs'>{info.position}</p>
                    </div>
                </div>
            </span>
        )
    }

    return (
        <>
            <Author_name_with_modal info={info}/>

            {
                (last ? null : <span className='notosan-med text-web-gold mr-3'>,</span>)
            }
        </>
    )
}

const KM_detail_new = () => {
    const {id} = useParams();

    const [ res_title, setRes_title ] = useState({});
    const [ res_author, setRes_author ] = useState({});
    const [ res_detail, setRes_detail ] = useState({});

    const [ breadcrumbs, setBreadcrumbs ] = useState([]);
    const [ image_cover, setImageCover ] = useState(null);
    const [ date, setDate ] = useState(null);
    const [ title, setTitle ] = useState('');
    const [ head_author, setHead_author ] = useState([]); //header can contain only 1 obj, using array to able to check condition if empty or not
    const [ authors, setAuthors ] = useState([]); //authors can contain multiple obj
    const [ details, setDetails ] = useState([]);

    useEffect(() => {
        if(id && default_items.length > 0) {
            const index = default_items.findIndex(x => parseInt(x.id) === parseInt(id));

            if(index !== -1) {
                setImageCover(default_items[index]['image']);

                const date = new Date(default_items[index]['date']);
                const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Asia/Bangkok' };
                const formattedDate = date.toLocaleDateString('th-TH', options);
                setDate(formattedDate);

                setTitle(default_items[index]['title']);
                setHead_author([...default_items[index]['author']]);

                setDetails([...default_items[index]['detail']]);
            }
        }
    }, [id, default_items]);

    useEffect(() => {
        setBreadcrumbs([
            {
                label: 'หน้าแรก',
                url: '/',
            },
            {
                label: 'การจัดการความรู้',
                url: '/km',
            },
            {
                label: title,
                url: '',
            },
        ]);
    }, [title]);

    return (
        <div className='relative w-screen h-screen z-0'>
            { /* Content of Home */ }
            <div className='w-screen h-screen overflow-y-scroll'>
                <Header/>
                <Cover breadcrumbs={breadcrumbs} cover={cover}/>

                <div className='mx-10 sm:mx-20 xl:mx-48 pt-20 pb-28'>
                    <img src={image_cover} className='w-full h-auto min-h-96 object-cover rounded-xl'/>

                    <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med mt-16'>{title}</h3>

                    <p className='w-full h-fit my-4'>
                        <span className='notosan-med text-web-gold'>โพสต์วันที่ : {date}</span>
                        <span className='notosan-med text-web-gold mx-4'>|</span>

                        {
                            head_author.map((x, i) => {
                                return (
                                    <Author_name key={i} info={x} last={!authors.length > 0}/>
                                )
                            })
                        }

                        {
                            authors.map((x, i) => {
                                return (
                                    <Author_name key={i} info={x} last={i === authors.length - 1}/>
                                )
                            })
                        }
                    </p>

                    <div className='w-32 h-1.5 bg-web-gold my-8'></div>

                    {
                        details.map((x, i) => {
                            return (
                                <div key={i} className='w-full h-fit mt-16 mb-6'>
                                    <h3 className='text-web-gold text-2xl notosan-med'>{x.topic}</h3>
                                    <KM_paragraph details={x.detail} last={i === details.length - 1}/>
                                </div>
                            )
                        })
                    }
                </div>

                <Footer/>
            </div>

            { /* Any modal */ }
        </div>
    )
}

export default KM_detail_new