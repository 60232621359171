import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import cover from '../../assets/cover/cover_admin.png';
import title_decorate from '../../assets/decorate/title_decorate.svg';
import NewsCard from '../Components/Card/NewsCard';

import { UilEye } from '@iconscout/react-unicons';
import { UilEyeSlash } from '@iconscout/react-unicons'

import { motion } from "framer-motion";
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { php_list } from '../../php/php_list';
import ModalConfirmForm from '../Components/Modal/ModalConfirmForm/ModalConfirmForm';
import ActivityCard from '../Components/Card/ActivityCard';
import KMCard from '../Components/Card/KMCard';
import Cover from '../Components/Header/Cover';

const Activity_item = ({activity, handle_add, handle_edit, handle_delete}) => {
    return (
        <div className={'w-full h-24 sm:h-16 border border-gray-300 rounded-md flex justify-start items-center overflow-hidden my-4'}>
            <div className='w-2 h-full bg-web-gold'></div>
            <div className='grow flex justify-between flex-col sm:flex-row px-6'>
                <p className='notosan-reg text-center'>{activity}</p>
                
                <div className='w-full sm:w-fit h-full flex justify-center sm:justify-start items-center gap-6 mt-4 sm:mt-0'>
                    <p className='notosan-reg text-web-black hover:text-web-gold cursor-pointer' onClick={handle_add}>เพิ่ม</p>
                    <p className='notosan-reg text-web-black hover:text-web-gold cursor-pointer' onClick={handle_edit}>แก้ไข</p>
                    <p className='notosan-reg text-web-black hover:text-web-gold cursor-pointer' onClick={handle_delete}>ลบ</p>
                </div>
            </div>
        </div>
    )
}

const Modal_for_identify = ({isShow, handle_submit}) => {
    const [ username, setUsername ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ show_password, setShowPassword ] = useState(false);

    const navigate = useNavigate();

    function check_authorised() {
        let authorised = (username.trim() === 'wander' && password.trim() === 'wanderpass');
        return authorised;
    }

    return (
        <div className={isShow ? 'block' : 'hidden'}>
            <div className='absolute top-0 left-0 w-screen h-screen flex justify-center items-center z-50'>
                <motion.div className='w-fit h-fit max-w-3xl max-h-full bg-white rounded-md px-10 md:px-28 py-20 sm:py-28 mx-4 md:mx-0 shadow-md overflow-auto'
                initial={{opacity: 0, y: 20}}
                whileInView={{opacity: 1, y: 0, transition:{duration: 0.5}}}
                viewport={{once:true}}>
                    <h3 className='text-mahidol-blue text-3xl sm:text-4xl notosan-med text-center'>กรุณาล็อกอินเข้าระบบ</h3>
                    <p className='notosan-reg text-mahidol-web-black my-8'>ขออภัย เนื้อหาที่ท่านกำลังพยายามเข้าถึงอนุญาติให้เฉพาะผู้ที่มีส่วนเกี่ยวข้องเท่านั้น กรุณาล็อกอินเพื่อเข้าถึงเนื้อหาเหล่านี้</p>

                    <p className='notosan-reg text-mahidol-web-black'>กรุณาระบุ Username</p>
                    <input className='w-full h-hit border border-gray-300 rounded-sm py-1.5 px-4 my-4 focus:outline focus:outline-mahidol-blue focus:outline-2 focus:outline-offset-1'
                    type='text' placeholder='กรุณาระบุ Username' onChange={(e) => setUsername(e.target.value)}/>

                    <p className='notosan-reg text-mahidol-web-black'>กรุณาระบุ Password</p>
                    <div className='relative w-full h-fit'>
                        <input className='w-full h-hit border border-gray-300 rounded-sm py-1.5 px-4 my-4 focus:outline focus:outline-mahidol-blue focus:outline-2 focus:outline-offset-1'
                        type={show_password ? 'text' : 'password'} placeholder='กรุณาระบุ Password' onChange={(e) => setPassword(e.target.value)}/>

                        <div className='absolute top-0 right-0 w-fit h-full flex justify-center items-center cursor-pointer mr-4 z-10'>
                            <UilEye size={20} color={"#808080"} className={show_password ? 'hidden' : 'block'} onClick={() => setShowPassword(true)}/>
                            <UilEyeSlash size={20} color={"#808080"} className={show_password ? 'block' : 'hidden'} onClick={() => setShowPassword(false)}/>
                        </div>
                    </div>

                    <div className={'flex flex-col-reverse sm:grid sm:grid-cols-2 gap-3 w-full h-auto min-h-12 mt-8'}>
                        <button className='notosan-med w-full h-full bg-gray-300 hover:bg-gray-500 py-3 rounded-md' onClick={() => navigate('/')}>กลับไปหน้าแรก</button>
                        <button onClick={() => handle_submit(check_authorised())}
                        className={'notosan-med w-full h-full py-3 rounded-md ' + (username.length > 0 && password.length > 0 ? 'bg-mahidol-blue hover:bg-mahidol-yellow text-white hover:text-web-black cursor-pointer' : 'bg-gray-300 text-gray-500 cursor-not-allowed')}>ยืนยัน</button>
                    </div>
                </motion.div>
            </div>

            <div className='absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-40'></div>
        </div>
    )
}

const Modal_news = ({isShow, handle_close, handle_submit}) => {
    const [ page, setPage ] = useState(1);
    const [ db, setDB ] = useState([]);
    const [ items, setItems ] = useState([]);
    const [ selectedID, setSelectedID ] = useState(-1);

    useEffect(() => {
        let abortcontroller = new AbortController();

        if(isShow) {
            axios.get(php_list.php_news_read_all, { signal: abortcontroller.signal })
            .then((response) => {
                // Handle the success response
                console.log(response.data);

                if (response.data.success) {
                    setDB([...response.data.results]);
                } 
                else {
                    console.log('Error loading data: ' + response.data.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }

        //reset selection (selection for update) everytime modal opened
        setSelectedID(-1);

        return () => abortcontroller.abort();
    }, [isShow]);

    useEffect(() => {
        const items_per_page = 8;
        const index_start = (page * items_per_page) - items_per_page; //Or (page * 8) - 8
        const index_end = (page * items_per_page > db.length) ? db.length : page * items_per_page;

        setItems(db?.slice(index_start, index_end));
    }, [page, db]);

    return (
        <div className={isShow ? 'block' : 'hidden'}>
            <div className='absolute top-0 left-0 w-screen h-screen flex justify-center items-center z-50'>
                <div className='w-11/12 h-5/6 max-h-full bg-white rounded-md px-10 md:px-28 py-8 sm:py-16 shadow-md overflow-auto'>
                    <div className='w-full h-fit min-h-full flex flex-col'>
                        <h3 className='text-mahidol-blue text-3xl sm:text-4xl notosan-med'>กรุณาเลือกข่าว</h3>

                        <div className={'grow ' + (items.length === 0 ? 'flex items-center' : '')}>
                            {
                                (items.length === 0) ?
                                    <div className='w-full h-fit flex justify-center'>
                                        { /* Loading Spinder */ }
                                        <div className={'grid grid-cols-1 gap-10 w-full h-auto min-h-12'}>
                                            <div className='flex justify-center items-center w-full h-full bg-pink-bold rounded-md cursor-not-allowed'>
                                                <div role="status">
                                                    <svg aria-hidden="true" className="w-6 h-6 text-gray-300 animate-spin dark:text-gray-600 fill-mahidol-blue" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    <div className='w-full h-fit my-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5'>
                                        {
                                            items.map((x, i) => {
                                                return (
                                                    <div key={i} onClick={() => setSelectedID(x.id)}
                                                    className={'border border-gray-300 px-8 pt-6 rounded-xl cursor-pointer ' + (selectedID === x.id ? 'outline outline-mahidol-blue outline-6 outline-offset-1' : '')}>
                                                        <NewsCard title={x.title} date={x.date} image={x.cover} detail={x.detail}/>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                            }
                        </div>

                        <div className={'grid grid-cols-1 sm:grid-cols-2 gap-3 w-full h-auto min-h-12'}>
                            <button className='notosan-med w-full h-full bg-gray-300 hover:bg-gray-500 py-3 rounded-md' onClick={handle_close}>ยกเลิก</button>
                            <button onClick={() => handle_submit(selectedID)}
                            className={'notosan-med w-full h-full py-3 rounded-md ' + (selectedID !== -1 ? 'bg-mahidol-blue hover:bg-mahidol-yellow text-white hover:text-web-black cursor-pointer' : 'bg-gray-300 text-gray-500 cursor-not-allowed')}>เลือก</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-40'></div>
        </div>
    );
}

const Modal_activities = ({isShow, handle_close, handle_submit}) => {
    const [ page, setPage ] = useState(1);
    const [ db, setDB ] = useState([]);
    const [ items, setItems ] = useState([]);
    const [ selectedID, setSelectedID ] = useState(-1);

    useEffect(() => {
        let abortcontroller = new AbortController();

        if(isShow) {
            axios.get(php_list.php_activity_read_all, { signal: abortcontroller.signal })
            .then((response) => {
                // Handle the success response
                console.log(response.data);

                if (response.data.success) {
                    setDB([...response.data.results]);
                } 
                else {
                    console.log('Error loading data: ' + response.data.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }

        //reset selection (selection for update) everytime modal opened
        setSelectedID(-1);

        return () => abortcontroller.abort();
    }, [isShow]);

    useEffect(() => {
        const items_per_page = 8;
        const index_start = (page * items_per_page) - items_per_page; //Or (page * 8) - 8
        const index_end = (page * items_per_page > db.length) ? db.length : page * items_per_page;

        setItems(db?.slice(index_start, index_end));
    }, [page, db]);

    return (
        <div className={isShow ? 'block' : 'hidden'}>
            <div className='absolute top-0 left-0 w-screen h-screen flex justify-center items-center z-50'>
                <div className='w-11/12 h-5/6 max-h-full bg-white rounded-md px-10 md:px-28 py-8 sm:py-16 shadow-md overflow-auto'>
                    <div className='w-full h-fit min-h-full flex flex-col'>
                        <h3 className='text-mahidol-blue text-3xl sm:text-4xl notosan-med'>กรุณาเลือกกิจกรรม</h3>

                        <div className={'grow ' + (items.length === 0 ? 'flex items-center' : '')}>
                            {
                                (items.length === 0) ?
                                    <div className='w-full h-fit flex justify-center'>
                                        { /* Loading Spinder */ }
                                        <div className={'grid grid-cols-1 gap-10 w-full h-auto min-h-12'}>
                                            <div className='flex justify-center items-center w-full h-full bg-pink-bold rounded-md cursor-not-allowed'>
                                                <div role="status">
                                                    <svg aria-hidden="true" className="w-6 h-6 text-gray-300 animate-spin dark:text-gray-600 fill-mahidol-blue" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    <div className='w-full h-fit my-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5'>
                                        {
                                            items.map((x, i) => {
                                                return (
                                                    <div key={i} onClick={() => setSelectedID(x.id)}
                                                    className={'border border-gray-300 px-8 pt-6 rounded-xl cursor-pointer ' + (selectedID === x.id ? 'outline outline-mahidol-blue outline-6 outline-offset-1' : '')}>
                                                        <ActivityCard title={x.title} dateStart={x.dateStart} dateEnd={x.dateEnd} image={x.cover}/>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                            }
                        </div>

                        <div className={'grid grid-cols-1 sm:grid-cols-2 gap-3 w-full h-auto min-h-12'}>
                            <button className='notosan-med w-full h-full bg-gray-300 hover:bg-gray-500 py-3 rounded-md' onClick={handle_close}>ยกเลิก</button>
                            <button onClick={() => handle_submit(selectedID)}
                            className={'notosan-med w-full h-full py-3 rounded-md ' + (selectedID !== -1 ? 'bg-mahidol-blue hover:bg-mahidol-yellow text-white hover:text-web-black cursor-pointer' : 'bg-gray-300 text-gray-500 cursor-not-allowed')}>เลือก</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-40'></div>
        </div>
    );
}

const Modal_km = forwardRef(({isShow, handle_close, handle_submit}, ref) => {
    const [ page, setPage ] = useState(1);
    const [ db, setDB ] = useState([]);
    const [ items, setItems ] = useState([]);
    const [ selectedID, setSelectedID ] = useState(-1);

    useEffect(() => {
        refresh_items();
    }, [isShow]);

    useEffect(() => {
        const items_per_page = 8;
        const index_start = (page * items_per_page) - items_per_page; //Or (page * 8) - 8
        const index_end = (page * items_per_page > db.length) ? db.length : page * items_per_page;

        setItems(db?.slice(index_start, index_end));
    }, [page, db]);

    useImperativeHandle(ref, () => ({
        refresh_items: () => refresh_items(),
    }));

    function refresh_items() {
        let abortcontroller = new AbortController();

        if(isShow) {
            axios.get(php_list.php_km_read_title, { signal: abortcontroller.signal })
            .then((response) => {
                // Handle the success response
                console.log(response.data.results);

                if (response.data.success) {
                    setDB([...response.data.results]);
                } 
                else {
                    console.log('Error loading data: ' + response.data.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }

        //reset selection (selection for update) everytime modal opened
        setSelectedID(-1);

        return () => abortcontroller.abort();
    }

    return (
        <div className={isShow ? 'block' : 'hidden'}>
            <div className='absolute top-0 left-0 w-screen h-screen flex justify-center items-center z-50'>
                <div className='w-11/12 h-5/6 max-h-full bg-white rounded-md px-10 md:px-28 py-8 sm:py-16 shadow-md overflow-auto'>
                    <div className='w-full h-fit min-h-full flex flex-col'>
                        <h3 className='text-mahidol-blue text-3xl sm:text-4xl notosan-med'>กรุณาเลือกกิจกรรม</h3>

                        <div className={'grow ' + (items.length === 0 ? 'flex items-center' : '')}>
                            {
                                (items.length === 0) ?
                                    <div className='w-full h-fit flex justify-center'>
                                        { /* Loading Spinder */ }
                                        <div className={'grid grid-cols-1 gap-10 w-full h-auto min-h-12'}>
                                            <div className='flex justify-center items-center w-full h-full bg-pink-bold rounded-md cursor-not-allowed'>
                                                <div role="status">
                                                    <svg aria-hidden="true" className="w-6 h-6 text-gray-300 animate-spin dark:text-gray-600 fill-mahidol-blue" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    <div className='w-full h-fit my-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5'>
                                        {
                                            items.map((x, i) => {
                                                return (
                                                    <div key={i} onClick={() => setSelectedID(x.id)}
                                                    className={'w-full h-full rounded-xl ' + (selectedID === x.id ? 'outline outline-mahidol-blue outline-6 outline-offset-1' : '')}>
                                                        <KMCard info={x}/>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                            }
                        </div>

                        <div className={'grid grid-cols-1 sm:grid-cols-2 gap-3 w-full h-auto min-h-12'}>
                            <button className='notosan-med w-full h-full bg-gray-300 hover:bg-gray-500 py-3 rounded-md' onClick={handle_close}>ยกเลิก</button>
                            <button onClick={() => handle_submit(selectedID)}
                            className={'notosan-med w-full h-full py-3 rounded-md ' + (selectedID !== -1 ? 'bg-mahidol-blue hover:bg-mahidol-yellow text-white hover:text-web-black cursor-pointer' : 'bg-gray-300 text-gray-500 cursor-not-allowed')}>เลือก</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-40'></div>
        </div>
    );
})

const Admin = () => {
    const [ show_modal_for_identify, setShow_modal_for_identify ] = useState(true);
    const [ show_modal_news, setShow_modal_news ] = useState(false);
    const [ modal_news_mode, setModal_news_mode ] = useState([
        {
            mode: 'update',
            available: false,
        },
        {
            mode: 'delete',
            available: false,
        },
    ]);

    const [ show_modal_activities, setShow_modal_activities ] = useState(false);
    const [ modal_activities_mode, setModal_activities_mode ] = useState([
        {
            mode: 'update',
            available: false,
        },
        {
            mode: 'delete',
            available: false,
        },
    ]);

    const [ show_modal_km, setShow_modal_km ] = useState(false);
    const [ modal_km_mode, setModal_km_mode ] = useState([
        {
            mode: 'update',
            available: false,
        },
        {
            mode: 'delete',
            available: false,
        },
    ]);

    const [ modal_selectedID, setModal_selectedID ] = useState(-1); //This state is shared between modal

    const [ is_SubmitConfirm_news, setIsSubmitConfirm_news ] = useState(false);
    const [ is_SubmitLoading_news, setIsSubmitLoading_news ] = useState(false);
    const [ is_SubmitSuccess_news, setIsSubmitSuccess_news ] = useState(null);
    const [ modal_submit_process_news, setModal_submit_process_news ] = useState("โปรดรอสักครู่");

    const [ is_SubmitConfirm_activity, setIsSubmitConfirm_activity ] = useState(false);
    const [ is_SubmitLoading_activity, setIsSubmitLoading_activity ] = useState(false);
    const [ is_SubmitSuccess_activity, setIsSubmitSuccess_activity ] = useState(null);
    const [ modal_submit_process_activity, setModal_submit_process_activity ] = useState("โปรดรอสักครู่");

    const [ is_SubmitConfirm_km, setIsSubmitConfirm_km ] = useState(false);
    const [ is_SubmitLoading_km, setIsSubmitLoading_km ] = useState(false);
    const [ is_SubmitSuccess_km, setIsSubmitSuccess_km ] = useState(null);
    const [ modal_submit_process_km, setModal_submit_process_km ] = useState("โปรดรอสักครู่");

    const navigate = useNavigate();

    const authorised = useParams();

    const ref_modal_km = useRef(null);

    const breadcrumbs = [
        {
            label: 'หน้าแรก',
            url: '/',
        },
        {
            label: 'ผู้จัดการระบบ',
            url: '',
        },
    ];

    useEffect(() => {
        if(authorised !== 'authorised') {
            setShow_modal_for_identify(true);
        }
    }, []);

    function handle_delete_news() {
        axios.post(php_list.php_news_delete, JSON.stringify({
            id: modal_selectedID,
        }))
        .then(response => {
            // Handle the success response
            console.log(response.data);

            if (response.data.success) {
                setIsSubmitLoading_news(false);
                setIsSubmitSuccess_news('success');
            } else {
                setIsSubmitLoading_news(false);
                setIsSubmitSuccess_news('failed');
            }
        })
        .catch(errors => {
            // Handle the error
            console.error('Error:', errors);

            setIsSubmitLoading_news(false);
            setIsSubmitSuccess_news('failed');
        })
        .finally(() => {
            setModal_selectedID(-1);
            setModal_news_mode([
                {
                    mode: 'update',
                    available: false,
                },
                {
                    mode: 'delete',
                    available: false,
                },
            ]);
        });
    }

    function handle_delete_activity() {
        axios.post(php_list.php_activity_delete, JSON.stringify({
            id: modal_selectedID,
        }))
        .then(response => {
            // Handle the success response
            console.log(response.data);

            if (response.data.success) {
                setIsSubmitLoading_activity(false);
                setIsSubmitSuccess_activity('success');
            } else {
                setIsSubmitLoading_activity(false);
                setIsSubmitSuccess_activity('failed');
            }
        })
        .catch(errors => {
            // Handle the error
            console.error('Error:', errors);

            setIsSubmitLoading_activity(false);
            setIsSubmitSuccess_activity('failed');
        })
        .finally(() => {
            setModal_selectedID(-1);
            setModal_activities_mode([
                {
                    mode: 'update',
                    available: false,
                },
                {
                    mode: 'delete',
                    available: false,
                },
            ]);
        });
    }

    return (
        <div className='relative w-screen h-screen z-0'>
            { /* Content of Home */ }
            <div className='w-screen h-screen overflow-y-scroll'>
                <Header/>
                <Cover breadcrumbs={breadcrumbs} cover={cover}/>

                <div className='mx-10 sm:mx-20 xl:mx-48 py-28'>
                    <div className='w-full h-fit flex flex-col justify-center mt-6 mb-12'>
                        <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med text-center'>ข่าวสารเกี่ยวกับศูนย์นโยบายและการจัดการสุขภาพ</h3>
                        <img src={title_decorate} className='w-28 h-auto self-center my-4'/>
                    </div>

                    <h3 className='text-web-gold text-2xl sm:text-4xl text-center sm:text-start notosan-med mb-8'>โปรดเลือกการทำงานของผู้จัดการระบบ</h3>
                    <Activity_item activity='ข่าวสารองค์กร' 
                    handle_add={() => navigate('/admin/news/add/authorised')} 
                    handle_edit={() => {
                        let mode = [...modal_news_mode];
                        mode[modal_news_mode.findIndex(x => x.mode === 'update')].available = true;
                        setModal_news_mode(mode);

                        setShow_modal_news(true);
                    }} 
                    handle_delete={() => {
                        let mode = [...modal_news_mode];
                        mode[modal_news_mode.findIndex(x => x.mode === 'delete')].available = true;
                        setModal_news_mode(mode);

                        setShow_modal_news(true);
                    }}/>
                    
                    <Activity_item activity='ปฏิทินกิจกรรม' 
                    handle_add={() => navigate('/admin/activities/add/authorised')} 
                    handle_edit={() => {
                        let mode = [...modal_activities_mode];
                        mode[modal_activities_mode.findIndex(x => x.mode === 'update')].available = true;
                        setModal_activities_mode(mode);

                        setShow_modal_activities(true);
                    }} 
                    handle_delete={() => {
                        let mode = [...modal_activities_mode];
                        mode[modal_activities_mode.findIndex(x => x.mode === 'delete')].available = true;
                        setModal_activities_mode(mode);

                        setShow_modal_activities(true);
                    }}/>

                    <Activity_item activity='ผลงานขององค์กร' 
                    handle_add={() => navigate('/admin/testimonial/authorised')} 
                    handle_edit={() => navigate('/admin/testimonial/authorised')} 
                    handle_delete={() => navigate('/admin/testimonial/authorised')}/>

                    <Activity_item activity='การจัดการความรู้ (KM)' 
                    handle_add={() => navigate('/admin/km/add/authorised')} 
                    handle_edit={() => {
                        let mode = [...modal_km_mode];
                        mode[modal_km_mode.findIndex(x => x.mode === 'update')].available = true;
                        setModal_km_mode(mode);

                        setShow_modal_km(true);
                    }} 
                    handle_delete={() => {
                        let mode = [...modal_km_mode];
                        mode[modal_activities_mode.findIndex(x => x.mode === 'delete')].available = true;
                        setModal_km_mode(mode);

                        setShow_modal_km(true);
                    }}/>
                </div>

                <Footer form={false}/>
            </div>

            { /* Any modal */ }
            <Modal_for_identify isShow={show_modal_for_identify}
            handle_submit={(authorised) => {
                setShow_modal_for_identify(!authorised);
            }}/>

            <Modal_news isShow={show_modal_news} 
            handle_close={() => setShow_modal_news(false)} 
            handle_submit={(selectedID) => {
                const is_update = modal_news_mode[modal_news_mode.findIndex(x => x.mode === 'update')].available;
                const is_delete = modal_news_mode[modal_news_mode.findIndex(x => x.mode === 'delete')].available;

                setModal_selectedID(selectedID);

                if(is_update) {
                    navigate('/admin/news/add/authorised/' + selectedID);
                }

                if(is_delete) {
                    setShow_modal_news(false);
                    setIsSubmitConfirm_news(true);
                }
            }}/>

            <Modal_activities isShow={show_modal_activities} 
            handle_close={() => setShow_modal_activities(false)} 
            handle_submit={(selectedID) => {
                const is_update = modal_activities_mode[modal_activities_mode.findIndex(x => x.mode === 'update')].available;
                const is_delete = modal_activities_mode[modal_activities_mode.findIndex(x => x.mode === 'delete')].available;

                setModal_selectedID(selectedID);

                if(is_update) {
                    navigate('/admin/activities/add/authorised/' + selectedID);
                }

                if(is_delete) {
                    setShow_modal_activities(false);
                    setIsSubmitConfirm_activity(true);
                }
            }}/>

            <Modal_km 
            ref={ref_modal_km}
            isShow={show_modal_km}
            handle_close={() => setShow_modal_km(false)}
            handle_submit={(selectedID) => {
                const is_update = modal_km_mode[modal_km_mode.findIndex(x => x.mode === 'update')].available;
                const is_delete = modal_km_mode[modal_km_mode.findIndex(x => x.mode === 'delete')].available;

                setModal_selectedID(selectedID);

                if(is_update) {
                    navigate('/admin/km/add/authorised/' + selectedID);
                }

                if(is_delete) {
                    axios.post(php_list.php_km_delete, JSON.stringify({
                        id: selectedID,
                    }))
                    .then(response => {
                        // Handle the success response
                        console.log(response.data);

                         if (response.data.success) {
                            if(ref_modal_km.current) {
                                ref_modal_km.current.refresh_items();
                            }

                            //setShow_modal_km(false);
                            setIsSubmitConfirm_km(true);
                        }
                    })
                    .catch(errors => {
                        // Handle the error
                        console.error('Error:', errors);
            
                        //setShow_modal_km(false);
                        setIsSubmitConfirm_km(false);
                    });
                }
            }}/>

            { /* Modal confirm for deleting news */ }
            <ModalConfirmForm isShow={is_SubmitConfirm_news} 
            isLoading={is_SubmitLoading_news} 
            isSuccess={is_SubmitSuccess_news} 
            process={modal_submit_process_news} 
            onConfirm={handle_delete_news} onCancel={() => {
                //Reset this modal state
                setIsSubmitConfirm_news(false);
                setIsSubmitLoading_news(false);
                setIsSubmitSuccess_news(null);

                //Rest news modal state
                //SelectedID is shared between modal
                setModal_selectedID(-1);
                setModal_news_mode([
                    {
                        mode: 'update',
                        available: false,
                    },
                    {
                        mode: 'delete',
                        available: false,
                    },
                ]);
            }}
            label={"ยืนยันการลบข้อมูลใช่หรือไม่?"}
            label_success={{
                title: 'ลบข้อมูลสำเร็จ',
                subtitle: null,
            }}
            label_failed={{
                title: 'ล้มเหลว พบปัญหาในการส่งข้อมูล',
                subtitle: null,
            }}/>

            { /* Modal confirm for deleting activity */ }
            <ModalConfirmForm isShow={is_SubmitConfirm_activity} 
            isLoading={is_SubmitLoading_activity} 
            isSuccess={is_SubmitSuccess_activity} 
            process={modal_submit_process_activity} 
            onConfirm={handle_delete_activity} onCancel={() => {
                //Reset this modal state
                setIsSubmitConfirm_activity(false);
                setIsSubmitLoading_activity(false);
                setIsSubmitSuccess_activity(null);

                //Rest activity modal state
                //SelectedID is shared between modal
                setModal_selectedID(-1);
                setModal_activities_mode([
                    {
                        mode: 'update',
                        available: false,
                    },
                    {
                        mode: 'delete',
                        available: false,
                    },
                ]);
            }}
            label={"ยืนยันการลบข้อมูลใช่หรือไม่?"}
            label_success={{
                title: 'ลบข้อมูลสำเร็จ',
                subtitle: null,
            }}
            label_failed={{
                title: 'ล้มเหลว พบปัญหาในการส่งข้อมูล',
                subtitle: null,
            }}/>

            { /* Modal confirm for deleting km */ }
            <ModalConfirmForm isShow={is_SubmitConfirm_km} 
            isLoading={is_SubmitLoading_km} 
            isSuccess={is_SubmitSuccess_km} 
            process={modal_submit_process_km} 
            onConfirm={handle_delete_activity} onCancel={() => {
                //Reset this modal state
                setIsSubmitConfirm_km(false);
                setIsSubmitLoading_km(false);
                setIsSubmitSuccess_km(null);

                //Rest activity modal state
                //SelectedID is shared between modal
                setModal_selectedID(-1);
                setModal_km_mode([
                    {
                        mode: 'update',
                        available: false,
                    },
                    {
                        mode: 'delete',
                        available: false,
                    },
                ]);
            }}
            label={"ยืนยันการลบข้อมูลใช่หรือไม่?"}
            label_success={{
                title: 'ลบข้อมูลสำเร็จ',
                subtitle: null,
            }}
            label_failed={{
                title: 'ล้มเหลว พบปัญหาในการส่งข้อมูล',
                subtitle: null,
            }}/>
        </div>
    )
}

export default Admin