import React, { useEffect, useRef, useState } from 'react';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import cover from '../../assets/cover/cover_contact.jpg';
import title_decorate from '../../assets/decorate/title_decorate.svg';
import rama_front from '../../assets/testimonial/rama_front.jpg';
import Cover from '../Components/Header/Cover';

const Location = () => {
  return (
    <div className='w-full h-fit grid grid-cols-1 lg:grid-cols-2 gap-10 items-stretch'>
      <div className='w-full h-fit'>
        <div className='w-fit h-fit flex gap-3 items-center'>
            <h3 className='text-web-gold text-lg sm:text-xl notosan-med'>ตำแหน่งที่ตั้ง</h3>
            <div className='w-20 h-0.5 bg-web-gold'></div>
        </div>
        <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med mt-3'>โรงพยาบาลรามาธิบดี</h3>
        <p className='notosan-reg text-web-black mt-6'>คณะแพทยศาสตร์โรงพยาบาลรามาธิบดี มหาวิทยาลัยมหิดล ตั้งอยู่ที่ 270 ถนนพระรามที่ 6 แขวงทุ่งพญาไท เขตราชเทวี กทม. 10400</p>
        
        <div className='w-fit h-fit flex mt-2'>
          <p className='notosan-reg text-web-black'><b>ประชาสัมพันธ์โรงพยาบาลรามาธิบดี</b></p>
          <p className='notosan-reg text-web-black mx-2'><b>:</b></p>
          <a href="tel:+022011000" className='notosan-reg text-web-black hover:text-web-gold hover:underline'>0-2201-1000</a>
        </div>

        <div className='w-fit h-fit flex mt-2'>
          <p className='notosan-reg text-web-black'><b>ประชาสัมพันธ์ศูนยการแพทย์สิริกิติ์</b></p>
          <p className='notosan-reg text-web-black mx-2'><b>:</b></p>
          <a href="tel:+0220110913" className='notosan-reg text-web-black hover:text-web-gold hover:underline'>0-2201-1091-3</a>
        </div>

        <div className='w-fit h-fit flex mt-2'>
          <p className='notosan-reg text-web-black'><b>ประชาสัมพันธ์ศูนย์การแพทย์สมเด็จพระเทพรัตน์</b></p>
          <p className='notosan-reg text-web-black mx-2'><b>:</b></p>
          <a href="tel:+022003000" className='notosan-reg text-web-black hover:text-web-gold hover:underline'>0-2200-3000</a>
        </div>

        <div className='w-fit h-fit flex gap-3 items-center mt-12'>
            <h3 className='text-web-gold text-lg sm:text-xl notosan-med'>ตำแหน่งที่ตั้ง</h3>
            <div className='w-20 h-0.5 bg-web-gold'></div>
        </div>
        <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med mt-3'>ศูนย์นโยบายและการจัดการสุขภาพ</h3>
        <p className='notosan-reg text-web-black mt-6'>ศูนย์นโยบายและการจัดการสุขภาพ ตั้งอยู่ที่ชั้น 8 ของอาคารสมเด็จพระเทพรัตน์</p>

        <div className='w-fit h-fit flex mt-4'>
          <p className='notosan-reg text-web-black'><b>เบอร์ติดต่อ</b></p>
          <p className='notosan-reg text-web-black mx-2'><b>:</b></p>
          <a href="tel:+022003832" className='notosan-reg text-web-black hover:text-web-gold hover:underline'>02-2003832</a>
        </div>
      </div>

      <img src={rama_front} className='w-full h-full object-cover rounded-md'/>
    </div>
  )
}

const Transportation = () => {
  const [ items, setItems ] = useState([
    {
      title: "รถไฟฟ้าบีทีเอส",
      detail: [
        {
          type: "text",
          detail: "จากสถานีอนุสาวรีย์ชัยสมรภูมิ สามารถเดินทางต่อด้วยรถประจำทางหรือรถมอเตอร์ไซต์รับจ้าง กรณีเดินทางต่อด้วยรถประจำทาง ให้รอรถบริเวณรพ.ราชวิถี (เกาะราชวิถี) เพื่อขึ้นรถประจำทาง สาย 18, 28, 108, 125, 515, 539, 542 ผ่านแยกตึกชัย ลงป้ายสถาบันประสาทวิทยาติดกับอาคารสมเด็จพระเทพรัตน์",
        },
        {
          type: "text",
          detail: "กรณีเดินทางต่อด้วยรถมอเตอร์ไซต์รับจ้าง สามารถเดินทางผ่านซอยโยธี มาลงปากซอยโยธีฝั่งองค์การเภสัชกรรมเดินเท้าข้ามสะพายลอยมายังโรงพยาบาลรามาธิบดี",
        },
      ],
      selected: false,
    },
    {
      title: "รถยนต์",
      detail: [
        {
          type: "text",
          detail: "สามารถเดินทางจากอนุสาวรีย์ชัยสมรภูมิ โดยขับไปตามถนนราชวิถีชิดขวาผ่านโรงพยาบาลราชวิถีและโรงพยาบาลพระมงกุฎเกล้า เมื่อพบสัญญาณไฟจราจร ให้เลี้ยวขวาแล้วตรงไปผ่านองค์การเภสัชกรรมจะพบโรงพยาบาลรามาธิบดีอยู่ฝั่งตรงข้าม ให้กลับรถที่จุดกลับรถเพื่อเดินทางมายังโรงพยาบาลรามาธิบดี",
        },
        {
          type: "text",
          detail: "กรณีเดินทางมาจากเขตปทุมวัน สามารถเดินทางโดยเริ่มต้นจากเริ่มแยกปทุมวัน ขับขึ้นเหนือตรงไปเรื่อยๆ ตามเส้นทางรถไฟฟ้าบีทีเอสจนถึงอนุสาวรีย์ชัยสมรภูมิ จากนั้นให้ขับตามเส้นทางจากอนุสาวรีย์ชัยสมรภูมิไปยังโรงพยาบาลรามาธิบดี",
        },
        {
          type: "text",
          detail: "กรณีเดินทางมาจากจากฝั่งธนฯ เริ่มต้นด้วยการขับรถข้ามแม่น้ำเจ้าพระยาผ่านสะพานกรุงธน (ซังฮี้) แล้วขับตรงไปเรื่อยๆ ตามถนนราชวิถีผ่านมหาวิทยาลัยราชภัฏสวนสุนันทาและมหาวิทยาลัยสวนดุสิต เมื่อขับเลยพระตำหนักจิตรลดารโหฐาน ให้ขับต่อไปชิดขวาจนพบสัญญาณไฟจราจรจะพบอาคารสมเด็จพระเทพรัตน์อยู่ทางขวามือ หากต้องการขับต่อไปยังโรงพยาบาลรามาธิบดี ให้รอสัญญาณไฟเพื่อเลี้ยวขวาแล้วขับตรงไปผ่านองค์การเภสัชกรรมจะพบโรงพยาบาลรามาธิบดีอยู่ฝั่งตรงข้าม ให้กลับรถที่จุดกลับรถเพื่อเดินทางมายังโรงพยาบาลรามาธิบดี",
        },
      ],
      selected: false,
    },
    {
      title: "รถประจำทาง",
      detail: [
        {
          type: "text",
          detail: "สามารถเดินทางมายังโรงพยาบาลรามาธิบดีด้วยรถประจำทาง สาย 8, 18, 28, 63, 92, 97, 108, 125, 157, 171, 509, 515, 539, 542, 538, R26E ทั้งนี้ สามารถเดินทางไปกลับระหว่างโรงพยาบาลรามาธิบดีและสถาบันการแพทย์จักรีนฤบดินทร์ด้วยรถประจำทาง สาย R26E",
        },
      ],
      selected: false,
    },
    {
      title: "รถไฟ",
      detail: [
        {
          type: "text",
          detail: "สามารถเดินทางด้วยรถไฟมายังสถานีโรงพยาบาลรามาธิบดี จากนั้น เดินข้ามถนนจากชานชาลาเพื่อเข้าสู่โรงพยาบาลรามาธิบดี โดยหมายเลขขบวนรถและเวลาจอดโดยประมาณของแต่ละขบวนมีดังนี้",
        },
        {
          type: "table",
          headers: ["ขบวนรถ", "เวลาจอดโดยประมาณ"],
          detail: [
            [ "233 กรุงเทพ - สุรินทร์", "11.49 น." ],
            [ "211 กรุงเทพ - ตะพานหิน", "13.04 น." ],
            [ "207 กรุงเทพ - นครสวรรค์", "14.12 น." ],
            [ "315 กรุงเทพ - ลพบุรี", "16.22 น. จันทร์ - ศุกร์" ],
            [ "301 กรุงเทพ - ลพบุรี", "16.41 น. เสาร์ - อาทิตย์" ],
            [ "313 กรุงเทพ - บ้านภาชี", "17.01 น." ],
            [ "341 กรุงเทพ - แก่งคอย", "17.25 น. จันทร์ - ศุกร์" ],
            [ "343 กรุงเทพ - แก่งคอย", "17.25 น. เสาร์ - อาทิตย์" ],
            [ "317 กรุงเทพ - ลพบุรี", "17.35 น." ],
            [ "305 กรุงเทพ - อยุธยา", "18.21 น." ],
          ],
        },
      ],
      selected: false,
    },
  ]);

  return (
    <>
      <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med mt-3 mb-8'>การเดินทาง</h3>

      <div className='w-full h-fit rounded-md overflow-hidden'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d18433.737494109297!2d100.53778605107364!3d13.765847185444404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e2994da99aa1fd%3A0xc4dd9b398f456bcf!2z4LiE4LiT4Liw4LmB4Lie4LiX4Lii4Lio4Liy4Liq4LiV4Lij4LmM4LmC4Lij4LiH4Lie4Lii4Liy4Lia4Liy4Lil4Lij4Liy4Lih4Liy4LiY4Li04Lia4LiU4Li1IOC4oeC4q-C4suC4p-C4tOC4l-C4ouC4suC4peC4seC4ouC4oeC4q-C4tOC4lOC4pQ!5e0!3m2!1sth!2sth!4v1741334219479!5m2!1sth!2sth" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      
      <p className='notosan-reg text-web-black mt-6 mb-10 indent-16'>ผู้ใช้บริการสามารถเลือกเดินทางไปยังโรงพยาบาลรามาธิบดีได้จากหลายช่องทาง โรงพยาบาลตั้งอยู่ใกล้กับเส้นทางคมนาคมหลักและมีตัวเลือกการเดินทางหลากหลาย ไม่ว่าจะเป็นการเดินทางโดยรถยนต์ส่วนตัว รถประจำทาง หรือบริการขนส่งสาธารณะ เช่น รถไฟฟ้าบีทีเอสและรถไฟ นอกจากนี้ ยังมีจุดจอดรถรองรับการใช้รถส่วนตัวและพื้นที่จอดสำหรับผู้ที่ต้องการนำรถมาเอง การเลือกใช้ขนส่งสาธารณะยังช่วยลดความแออัดของการจราจรในช่วงเวลาเร่งด่วนอีกด้วย</p>

      {
        items.map((x, i) => {
          return (
            <Transportation_item key={i} title={x.title} detail={x.detail} selected={x.selected}
            handle_clicked={() => {
              let result = [...items];

              result.forEach((x, j) => {
                if(j === i) {
                  let prev = x.selected;
                  x.selected = !prev;
                }
                else {
                  x.selected = false;
                }
              });

              setItems(result);
            }}/>
          )
        })
      }
    </>
  )
}

const Transportation_item = ({title, detail, selected, handle_clicked}) => {
  const [ hover, setHover ] = useState(false);
  const [ active, setActive ] = useState(false);
  const [ detail_height, setDetail_height ] = useState(0);
  const ref_detail = useRef(null);

  useEffect(() => {
    setActive(selected);

    if(ref_detail.current) {
      setDetail_height(selected ? ref_detail.current.scrollHeight : 0);
    }
  }, [selected]);

  return (
    <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={handle_clicked}
    className='w-full h-fit px-4 sm:px-10 py-4 border border-gray-300 rounded-md my-4 cursor-pointer'>
      <div className='w-full h-fit flex justify-between items-center'>
        <h3 className={'w-fit h-fit text-lg notosan-med ' + ((hover || active) ? 'text-web-gold' : 'text-web-black')}>{title}</h3>

        <div className={'w-fit h-fit transition duration-500 flex items-center ' + (active ? 'rotate-180' : 'rotate-0')}>
          <box-icon name='chevron-down' ></box-icon>
        </div>
      </div>

      <div ref={ref_detail} className='w-full h-fit overflow-hidden transition-all duration-500' style={{height: detail_height + "px"}}>
        {
          detail.map((x, i) => {
            if(x.type === "text") {
              return (
                <p key={i} className='notosan-reg text-web-black my-4 indent-10'>{x.detail}</p>
              )
            }
            else if(x.type === "table") {
              return (
                <div className='w-full h-fit overflow-x-scroll sm:overflow-x-visible'>
                  <Table headers={x.headers} details={x.detail}/>
                </div>
              )
            }
          })
        }
      </div>
    </div>
  )
}

const Table = ({headers, details}) => {
  return (
    <table className="table-auto w-full h-fit border border-separate border-gray-300 rounded-md">
      <thead>
        <tr>
          {
            headers.map((x, i) => {
              return (
                <th key={i} className='border-b border-gray-300 py-2 text-nowrap sm:text-wrap'>{x}</th>
              );
            })
          }
        </tr>
      </thead>

      <tbody>
        {
          details.map((x, i) => {
            return (
              <tr key={i}>
                {
                  x.map((y, j) => {
                    return (
                      <td key={j} className={'px-4 sm:px-10 py-2 text-nowrap sm:text-wrap ' + ((i !== details.length - 1) ? 'border-b border-gray-300' : '')}>{y}</td>
                    )
                  })
                }
              </tr>
            )
          })
        }
      </tbody>
    </table>
  )
}

const Path_between_building = () => {
  return (
    <>
      <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med mt-20 mb-8'>การเดินทางระหว่างอาคาร</h3>
      <p className='notosan-reg text-web-black indent-10'>ในการเดินทางระหว่างโรงพยาบาลรามาธิบดีและศูนย์การแพทย์พระเทพพระรัตน์ สามารถเลือกเดินทางได้หลายวิธี สามารถขับรถระหว่างสถานที่ผ่านถนนพระราม 6 หรือเลือกเดินมาตามทางเท้ทางเชื่อม Sky Walk หน้าโรงพยาบาล บนทางเชื่อมระหว่างอาคารมีบริการรถรับส่งเพื่ออำนวยความสะดวกให้กับผู้ที่ต้องการเดินทางโดยไม่ต้องเดินในระยะทางที่ไกล ทั้งนี้ ท่านสามารถขึ้นไปยังทางเชื่อมผ่านอาคารศูนย์การแพทย์สิริกิติ์ โรงพยาบาลรามาธิบดี</p>
    </>
  )
}

const Contact = () => {
  const breadcrumbs = [
    {
        label: 'หน้าแรก',
        url: '/',
    },
    {
        label: 'ติดต่อเรา',
        url: '',
    },
  ];

  return (
    <div className='relative w-screen h-screen z-0'>
      { /* Content of Home */ }
      <div className='w-screen h-screen overflow-y-scroll'>
        <Header/>
        <Cover breadcrumbs={breadcrumbs} cover={cover}/>

        <div className='mx-10 sm:mx-20 xl:mx-48 py-14 sm:py-28'>
          <div className='w-full h-fit flex flex-col justify-center my-6'>
              <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med text-center'>ติดต่อเรา</h3>
              <img src={title_decorate} className='w-28 h-auto self-center my-4'/>
          </div>

          <Location/>

          <hr className='w-3/4 h/fit ml-auto mr-auto mt-14 mb-20'/>

          <Transportation/>
        </div>

        <Footer form={false}/>
      </div>

      { /* Any modal */ }
    </div>
  )
}

export default Contact