const default_items = [
    {
        id: 0,
        image: 'https://drive.google.com/thumbnail?id=1NuDGZY4mtGFuETQZ6IhVPh3haKS5_wZV&sz=w1500',
        title: 'จากงานวิจัย สู่นโยบายชี้นำสังคมเกี่ยวกับเด็กและเยาวชน',
        date: '2022-04-07',
        author : [
            {
                prefix: 'รศ.นพ.',
                name: 'อดิศักดิ์ ผลิตผลการพิมพ์',
                image: 'https://drive.google.com/thumbnail?id=1aLOsqNZ1js9iGT_sUFQvSsZ6buqz2-a4&sz=w75',
                position: 'ผู้อำนวยการ สถาบันแห่งชาติเพื่อการพัฒนาเด็กและครอบครัว มหาวิทยาลัยมหิดล',
            },
        ],
        detail: [
            {
                topic: 'ที่มา',
                detail: [
                    {
                        paragraph: 0,
                        type: 'text',
                        indent: true,
                        margin: 0,
                        url: null,
                        bullet: null,
                        detail: 'มาจากงานประจำที่ดูแลเวชศาสตร์ผู้ป่วยเด็กและวัยรุ่น ทั้งปกติและฉุกเฉิน รวมทั้งงานระบบบริการ และงานวิจัยที่ทำก็จะเลือกประเด็นเฉพาะที่เราสนใจ เมื่อปี 2538 เช่น การให้วัคซีน ติดตามการเจริญเติบโตจากการขาดสารอาหาร เด็กติดเชื้อปอดอักเสบ เป็นต้น ปัญหาเก่าเปลี่ยนไปหรือยัง โดยดูเรื่องความปลอดภัย มีการบาดเจ็บ และความรุนแรง เมื่อศึกษาจากสถิติการตายของเด็ก กลับพบว่า มีแนวโน้มสูงขึ้น ขณะที่รายงานของกระทรวงสาธารณสุขประจำปีกลับไม่มีข้อมูลตรงส่วนนี้มากพอ เป็นการรายงานภาพรวมโดยมีตัวชี้วัด ได้แก่ อุบัติเหตุจากการจราจร การทำร้ายซึ่งกันและกัน การฆ่าตัวตายและอื่นๆ เมื่อกลับมาดูคู่มือในการปฏิบัติงานของแผนกตรวจก็ไม่มีข้อแนะนำเรื่องความปลอดภัยในเด็กและความรุนแรงในเด็กเลย',
                    },
                ]
            },
            {
                topic: 'แรงบันดาลใจ',
                detail: [
                    {
                        paragraph: 0,
                        type: 'text',
                        indent: true,
                        margin: 0,
                        url: null,
                        bullet: null,
                        detail: 'จากการศึกษาในช่วงแรก เริ่มจากไปศึกษาถึงสาเหตุ ย้อนหลังกว่า 30 ปี โดยดูข้อมูลจากกระทรวงสาธารณสุขและกระทรวงมหาดไทย <HL>เมื่อเปรียบเทียบกับประเทศทางยุโรป พบว่า ก็มีเหตุการณ์นี้เกิดขึ้นก่อนเรากว่า 40 ปี และสามารถมีมาตรการลดอุบัติการณ์ลงได้กว่าร้อยละ 30<HL> จึงเกิดความสนใจที่ต้องขับเคลื่อนเรื่องนี้ ให้เด็กได้รับผลประโยชน์สูงสุด โดยได้ไปศึกษาดูงานกับนักวิจัยอเมริกา ชื่อ เฟรเดอริก ลิบาร่า ที่ไปศึกษากรณีอุบัติเหตุในเด็กในประเทศสวีเดน เนื่องจากจำนวนเด็กเสียชีวิตที่ประเทศนี้น้อยกว่าประเทศสหรัฐอเมริกา ซึ่งได้กุมารแพทย์แกนนำคนหนึ่ง ชื่อ ดร แบงน่า เบอแฮมสแตม ประมาณ 2 ปี ถึงได้กลับมาเริ่มงานในประเทศไทย',
                    },
                ]
            },
            {
                topic: 'จุดเริ่มต้นของการผลักดันงาน',
                detail: [
                    {
                        paragraph: 0,
                        type: 'text',
                        indent: true,
                        margin: 0,
                        url: null,
                        bullet: null,
                        detail: 'เริ่มต้นจากความตั้งใจ เห็นตัวอย่างที่ ดร แบงน่า ดำเนินการ จึงเห็นโอกาสในการทำเรื่องนี้ต่อในประเทศไทย โดยใช้กระบวนการแบบเดียวกัน คือ การทำงานร่วมกับเครือข่ายภาคสังคมแบบบูรณาการร่วมกับการวิจัยทางวิทยาศาสตร์ ประกอบกับได้รับแรงบันดาลใจจากการสอนของครูอาจารย์ 2 ท่าน ที่ได้นำความรู้ไปใช้แก้ไขปัญหาจริงให้กับสังคม มากกว่าการตั้งรับในการรักษาทางคลินิก',
                    },
                    {
                        paragraph: 1,
                        type: 'bullet_number',
                        indent: false,
                        margin: 1,
                        url: null,
                        bullet: 1,
                        detail: 'ศาสตราจารย์เกียรติคุณ แพทย์หญิงวันดี วราวิทย์ ในการสอนวิธีการคิดค้นสารเกลือแร่ เพื่อแก้ปัญหาให้กับสังคมในการป้องกันอุจาระร่วงในเด็ก จนทำให้ลดการตายของเด็ก ซึ่งชาวบ้านสามารถทำได้เองด้วย (ประวัติอ้างอิงอาจารย์วันดี: <LINK><URL>https://www.rama.mahidol.ac.th/ram/th/content/oralhistory_b1<URL>https://www.rama.mahidol.ac.th/ram/th/content/oralhistory_b1<LINK>)',
                    },
                    {
                        paragraph: 2,
                        type: 'bullet_number',
                        indent: false,
                        margin: 1,
                        url: null,
                        bullet: 2,
                        detail: 'ศาสตราจารย์เกียรติคุณ นายแพทย์ไกรสิทธิ์ ตันติศิรินทร์ ในการสอนเรื่องโภชนาการในเด็ก โดยเชื่อมโยงกับการแก้ปัญหาให้กับสังคม ในการแก้ไขภาวะโภชนาการในเด็ก โดยการให้ชาวบ้านตำข้าวถั่วงาให้เด็กกิน และวัดการเจริญเติบโต (ประวัติอ้างอิงอาจารย์ไกรสิทธิ์: <LINK><URL>https://museum.li.mahidol.ac.th/th/MU-10th-prototype/index.php/kraisid-tontisirin-1/<URL>https://museum.li.mahidol.ac.th/th/MU-10th-prototype/index.php/kraisid-tontisirin-1/<LINK>)',
                    },
                    {
                        paragraph: 3,
                        type: 'space',
                        indent: false,
                        margin: 0,
                        url: null,
                        bullet: null,
                        detail: '',
                    },
                    {
                        paragraph: 4,
                        type: 'text',
                        indent: true,
                        margin: 0,
                        url: null,
                        bullet: null,
                        detail: 'ซึ่งเมื่อไปดูถึงปัจจัยหลักที่เกี่ยวกับการเสียชีวิตของเด็ก มีอยู่ด้วยกัน 3 ข้อ คือ',
                    },
                    {
                        paragraph: 6,
                        type: 'bullet_number',
                        indent: false,
                        margin: 2,
                        url: null,
                        bullet: 1,
                        detail: 'ทักษะของเด็ก',
                    },
                    {
                        paragraph: 7,
                        type: 'bullet_number',
                        indent: false,
                        margin: 2,
                        url: null,
                        bullet: 2,
                        detail: 'ทักษะของผู้ปกครอง รวมทั้งผู้ใหญ่ที่อยู่แวดล้อมเด็ก',
                    },
                    {
                        paragraph: 8,
                        type: 'bullet_number',
                        indent: false,
                        margin: 2,
                        url: null,
                        bullet: 3,
                        detail: 'สิ่งแวดล้อมรอบตัวเด็กที่ไม่ถูกต้อง',
                    },
                    {
                        paragraph: 9,
                        type: 'space',
                        indent: false,
                        margin: 0,
                        url: null,
                        bullet: null,
                        detail: '',
                    },
                    {
                        paragraph: 10,
                        type: 'text',
                        indent: true,
                        margin: 0,
                        url: null,
                        bullet: null,
                        detail: '<CENTER>จึงเกิดแรงบันดาลใจที่ต้องการจะไปแก้สาเหตุของปัจจัยเหล่านี้ เพื่อให้เด็กที่เติบโตมาได้มีโอกาสเป็นผู้ใหญ่และสร้างคุณประโยชน์ให้กับประเทศได้ต่อไป<CENTER>',
                    },
                ]
            },
            {
                topic: 'มีวิธีจัดการอย่างไรในการประสานความร่วมมือกับภาคส่วนต่างๆ ให้มีความลงตัวที่สุด',
                detail: [
                    {
                        paragraph: 0,
                        type: 'bullet_number',
                        indent: false,
                        margin: 0,
                        url: null,
                        bullet: 1,
                        detail: 'องค์กรต้องเห็นความสำคัญของเรื่องนี้อย่างจริงจัง และนับเป็นผลงาน เช่น Social Engagement มีทุนให้ดำเนินการมากขึ้นจากกระทรวง อว.',
                    },
                    {
                        paragraph: 1,
                        type: 'bullet_number',
                        indent: false,
                        margin: 0,
                        url: null,
                        bullet: 2,
                        detail: 'องค์กรมีบุคลากรที่ทำด้านนี้มาอย่างยาวนาน เป็นตัวอย่างที่ดีมากมาย',
                    },
                    {
                        paragraph: 2,
                        type: 'bullet_number',
                        indent: false,
                        margin: 0,
                        url: null,
                        bullet: 3,
                        detail: 'ต้องมีความเข้าใจ เครือข่ายภาคสังคม จัดกลุ่มทั้งแรงบวกและแรงลบ ให้รอบด้าน',
                    },
                    {
                        paragraph: 3,
                        type: 'bullet_number',
                        indent: false,
                        margin: 0,
                        url: null,
                        bullet: 4,
                        detail: 'วิธีจัดการกับแรงบวก คือ การให้ข้อมูลงานวิจัยทางวิทยาศาสตร์สนับสนุน',
                    },
                    {
                        paragraph: 4,
                        type: 'bullet_number',
                        indent: false,
                        margin: 0,
                        url: null,
                        bullet: 5,
                        detail: 'วิธีจัดการกับแรงลบ คือ การให้ข้อมูลเชิงสังคมและการสื่อสารช่วยสนับสนุน',
                    },
                    {
                        paragraph: 5,
                        type: 'bullet_number',
                        indent: false,
                        margin: 0,
                        url: null,
                        bullet: 6,
                        detail: 'การทำงานเชิงสังคมจะไม่มีการเปลี่ยนแปลงอย่างทันที จำเป็นต้องอาศัยเวลา เปรียบเสมือนบันไดวน ที่เห็นเป้าหมายอยู่ข้างหน้าแต่กว่าจะไปก็ต้องใช้เวลา แต่เราไม่ควรสนใจอีกนานแค่ไหนกว่าจะไปถึง ให้เรามองลงมาข้างล่างดูว่าเราได้เดินมาสูงมากขึ้นเท่าใดแล้ว เพื่อเป็นกำลังใจให้ก้าวต่อไปข้างหน้า',
                    },
                ]
            },
            {
                topic: 'มีวิธีในการสร้างและจัดการทีมเข้ามาช่วยงานได้อย่างไร',
                detail: [
                    {
                        paragraph: 0,
                        type: 'bullet_number',
                        indent: false,
                        margin: 0,
                        url: null,
                        bullet: 1,
                        detail: 'เริ่มจากเพื่อนร่วมงานในสาขาวิชาเดียวกัน เช่น ได้อาจารย์จิราพร ที่มีความเชี่ยวชาญด้าน MRI สมอง หรือเรื่องอื่นๆ ที่เกี่ยวข้องมาช่วยกัน ซึ่งง่ายกว่า',
                    },
                    {
                        paragraph: 1,
                        type: 'bullet_number',
                        indent: false,
                        margin: 0,
                        url: null,
                        bullet: 2,
                        detail: 'ส่วนหน่วยงานภายนอก มีด้วยกัน 2 วิธี และควรทำไปด้วยกัน ได้แก่',
                    },
                    {
                        paragraph: 2,
                        type: 'bullet_number',
                        indent: false,
                        margin: 1,
                        url: null,
                        bullet: 2.1,
                        detail: 'การแทรกตัวเข้าไปทำงานร่วมกับหน่วยงานที่เกี่ยวข้องโดยตรง เช่น ทำกิจกรรม หรืองานวิจัย เป็นต้น นำไปสู่การเข้าร่วมเป็นคณะอนุกรรมการต่างๆ พัฒนาความชำนาญจนมีชื่อเสียงและผลงานเป็นที่ประจักษ์มากขึ้นเรื่อยๆ วิธีนี้เหมาะสมกับอาจารย์รุ่นใหม่ที่ต้องการสร้างผลงานด้วย',
                    },
                    {
                        paragraph: 3,
                        type: 'bullet_number',
                        indent: false,
                        margin: 1,
                        url: null,
                        bullet: 2.2,
                        detail: 'การใช้โดยอ้อม หรือ วิธีป่าล้อมเมือง โดยการใช้สื่อมีเดียต่างๆ ให้ข้อมูลในเชิงบวกอย่างสม่ำเสมอ ไม่ควรให้ข้อมูลโต้แย้งกับหน่วยงานต่างๆ ในเชิงลบ วิธีนี้เหมาะกับอาจารย์ที่มีชื่อเสียงระดับหนึ่งแล้ว เช่น ใช้วิธีแถลงงานร่วมกันกับหน่วยงานที่เกี่ยวข้อง ไม่ทำให้เข้าเสียชื่อเสียง และสร้างความมุ่งมั่นในการแก้ไขปัญหาให้ต่อไป',
                    },
                    {
                        paragraph: 4,
                        type: 'bullet_number',
                        indent: false,
                        margin: 0,
                        url: null,
                        bullet: 3,
                        detail: 'ควรให้เจ้าของหน่วยงานภายนอกเป็นประธานคณะกรรมการหรือคณะทำงาน ส่วนตัวอาจารย์ควรเป็นรองประธานแทน ส่วนตำแหน่งเลขานุการคณะกรรมการหรือคณะทำงาน ก็ควรให้มีทีมงานของเราเข้าไปทำหน้าที่นี้ด้วย เพื่อให้การประสานงานในเรื่องดังกล่าวไปได้อย่างคล่องตัว ทั้งนี้ควรอยู่ในระยะสั้น จุดประกายให้กับทีมเขา และปล่อยให้เขาทำงานเองได้ต่อไป',
                    },
                    {
                        paragraph: 5,
                        type: 'bullet_number',
                        indent: false,
                        margin: 0,
                        url: null,
                        bullet: 4,
                        detail: 'การจะสร้างความยั่งยืนให้หน่วยงานนั้นทำด้วยตัวเอง คือ การให้หน่วยงานเหล่านั้นหาคนที่สนใจทำงานด้านนั้นอย่างจริงจังเข้ามาทำงานต่อไป',
                    },
                    {
                        paragraph: 6,
                        type: 'text',
                        indent: true,
                        margin: 0,
                        url: null,
                        bullet: null,
                        detail: 'ตัวอย่างงานที่ผลักดันให้หน่วยงานที่เกี่ยวข้องดำเนินการต่อได้สำเร็จอย่างเป็นรูปธรรม เรื่องการป้องกันการจมน้ำในเด็ก โดย สำนักโรคไม่ติดต่อ กรมควบคุมโรค กระทรวงสาธารณสุข จากที่เคยมีอัตราการตาย 15 คนต่อแสนต่อปี ปัจจุบัน ลดลงได้แล้วครึ่งหนึ่ง ซึ่งได้ตั้งเป้าให้จำนวนลดลงอีกครึ่งหนึ่งให้ได้ต่อไป',
                    },
                ]
            },
            {
                topic: 'สิ่งที่เปลี่ยนแปลงไปจากเดิม',
                detail: [
                    {
                        paragraph: 0,
                        type: 'text',
                        indent: true,
                        margin: 0,
                        url: null,
                        bullet: null,
                        detail: 'ปัจจัยที่มีผลทำให้การเสียชีวิตของเด็กมีรูปแบบที่เปลี่ยนไปจากเดิม คือ การพัฒนาของเทคโนโลยี ซึ่งในอดีต 20 ปีก่อน ประเทศสวีเดน ก็ได้มีการแก้ไขปัญหาโดยใช้วิธี empower community และ การสร้างความเท่าเทียม equity โดยสามารถแก้ปัญหาจากกลุ่มเด็กประถมวัยได้ก่อนกลุ่มเด็กวัยเรียนและก่อนกลุ่มเด็กวัยรุ่น ตามลำดับ',
                    },
                    {
                        paragraph: 1,
                        type: 'text',
                        indent: true,
                        margin: 0,
                        url: null,
                        bullet: null,
                        detail: 'อาจารย์อดิศักดิ์เองก็ใช้วิธีการเหล่านี้ในการขับเคลื่อนเกี่ยวกับเด็กและเยาวชนมาโดยตลอด ผลที่ได้จะเห็นว่า การแก้ปัญหาในระยะแรกจะได้ผลลัพธ์ที่ดี จนถึงจุดหนึ่งที่ผลลัพธ์จะคงที่ ต้องวิเคราะห์ให้เห็นจริงว่าเกิดจากสาเหตุใด และส่วนใหญ่จะเป็นเรื่องที่ยากลำบากในการดำเนินการ',
                    },
                    {
                        paragraph: 2,
                        type: 'text',
                        indent: true,
                        margin: 0,
                        url: null,
                        bullet: null,
                        detail: 'ด้วยความที่เราล้าหลังจากประเทศสวีเดนในเรื่องนี้กว่า 20 ปี หากเราศึกษาให้ดีจะเห็นแนวโน้มของสาเหตุของปัญหาที่คล้ายคลึงกันเมื่อยุคสมัยที่เปลี่ยนไป เปรียบเสมือนเรามีตัวอย่างให้เห็นแล้ว ดังนั้น จึงพอที่จะคาดการณ์เหตุการณ์ ที่จะเกิดขึ้นต่อเด้กในประเทศไทยจากนี้ได้บ้าง',
                    },
                    {
                        paragraph: 3,
                        type: 'text',
                        indent: true,
                        margin: 0,
                        url: null,
                        bullet: null,
                        detail: 'ตัวอย่างเช่น การบาดเจ็บเรื้อรังทางสมอง การเสียพัฒนาการด้านการพูดของเด็กที่เข้าถึงสื่อสังคมออนไลน์ตั้งแต่เด็ก ความรุนแรงในสังคมก่อให้เกิดการก่อการร้าย เป็นต้น',
                    },
                ]
            },
            {
                topic: 'ปัจจัยสำคัญที่จะผลักดันไปสู่ความสำเร็จ',
                detail: [
                    {
                        paragraph: 0,
                        type: 'bullet_number',
                        indent: false,
                        margin: 0,
                        url: null,
                        bullet: 1,
                        detail: 'ความตั้งใจของเราเอง อาจเกิดจากความชำนาญที่เรามีอยู่',
                    },
                    {
                        paragraph: 1,
                        type: 'bullet_number',
                        indent: false,
                        margin: 0,
                        url: null,
                        bullet: 2,
                        detail: 'สนุกไปกับความสำเร็จของผลงานเพียงเล็กน้อย ก็สร้างความภาคภูมิใจให้กับเราได้ทำต่อไป',
                    },
                    {
                        paragraph: 2,
                        type: 'bullet_number',
                        indent: false,
                        margin: 0,
                        url: null,
                        bullet: 3,
                        detail: 'ผู้บริหารองค์กรก็ให้ความสำคัญ มีทุนสนับสนุนมากขึ้น',
                    },
                ]
            },
            {
                topic: 'ข้อคิดที่ฝากไว้',
                detail: [
                    {
                        paragraph: 0,
                        type: 'text',
                        indent: true,
                        margin: 0,
                        url: null,
                        bullet: null,
                        detail: 'การทำงานเพื่อสาธารณะ คือ การทำงานให้ผู้คนที่อยู่ร่วมกันบนโลกอย่างสมดุล',
                    },
                    {
                        paragraph: 1,
                        type: 'image',
                        indent: false,
                        margin: 0,
                        bullet: null,
                        url: 'https://drive.google.com/thumbnail?id=1NuDGZY4mtGFuETQZ6IhVPh3haKS5_wZV&sz=w1500',
                        detail: 'การทำงานเพื่อสาธารณะ คือ การทำงานให้ผู้คนที่อยู่ร่วมกันบนโลกอย่างสมดุล',
                    },
                    {
                        paragraph: 2,
                        type: 'video',
                        indent: false,
                        margin: 0,
                        bullet: null,
                        url: 'https://www.youtube.com/embed/qIbMXVJYHKI?si=BP6XsFoNQm40zw8J',
                        detail: 'การทำงานเพื่อสาธารณะ คือ การทำงานให้ผู้คนที่อยู่ร่วมกันบนโลกอย่างสมดุล',
                    },
                ]
            }
        ],
    }
];

export default default_items;