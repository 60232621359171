import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../../assets/logo/logo.jpg'

import UilClose from '@iconscout/react-unicons/icons/uil-times';
import UilBars from '@iconscout/react-unicons/icons/uil-bars';

const DropdownSubMenu = ({label, url}) => {
    const [ ishover, setIsHover ] = useState(false);

    return (
        <div className="relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50" onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            <Link className="flex-auto text-base" to={url}><span className={ishover ? "text-web-gold" : "text-web-black"}>{label}</span></Link>
        </div>
    )
}

const DropdownMenu = ({menu, submenus}) => {
    const [show, setShow] = useState(false);
    const [menu_active, setMenuActive] = useState(false);

    return (
        <div className="relative" onMouseEnter={() => setMenuActive(true)} onMouseLeave={() => setMenuActive(false)}>
            <button type="button" className="flex items-center gap-x-1" aria-expanded="false" onClick={() => setShow(!show)}>
                <span className={"notosan-reg " + (menu_active ? "text-web-gold" : "text-web-black")}>{menu}</span>
                <svg className="h-5 w-5 flex-none text-gray-400 transform" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                </svg>
            </button>

            <div className="absolute -left-8 top-full z-50 mt-3 w-screen max-w-80 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-gray-900/5 transition-opacity duration-500" 
            style={show ? {opacity: "1", visibility: "visible"} : {opacity: "0.8", visibility: "hidden"}}
            onMouseLeave={() => setShow(false)}>
                <div className="p-4">
                    {
                        submenus.map((x, i) => {
                            return (
                                <DropdownSubMenu key={i} label={x.menu} url={x.url} />
                            );
                        })
                    }
                </div>
            </div>
        </div>
    )
}

const Header = () => {
    const [ show_mobile_nav, setShow_mobile_nav ] = useState(false);
    const navigate = useNavigate();

    const menu_about = [
        {
            menu: "เกี่ยวกับเรา",
            url: "/about"
        },
        {
            menu: "พันธกิจและวิสัยทัศน์",
            url: "/about/mission"
        },
        {
            menu: "บุคลากรของศูนย์ฯ",
            url: "/team"
        }
    ];

    const Menu = ({menu, url}) => (
        <Link className='notosan-reg text-web-black cursor-pointer hover:text-web-gold'
        to={url}>
            {menu}
        </Link>
    )

    return (
        <div className='w-full h-fit flex justify-between px-10 py-3 border-b border-grey-300'>
            <div className='w-fit h-fit'>
                <div className='w-full h-fit flex justify-center'>
                    <img src={logo} className='w-28 h-auto cursor-pointer' onClick={() => console.log(navigate("/"))}/>
                </div>

                <div className='relative py-1 px-2'>
                    <h3 className='relative text-web-black text-xs notosan-bold text-center'>small, but MIGHTY</h3>
                </div>
            </div>

            <div className='w-fit h-auto justify-end items-center gap-x-10 hidden xl:flex'>
                <Menu menu={'หน้าแรก'} url={'/'}/>
                <DropdownMenu menu={'เกี่ยวกับเรา'} submenus={menu_about} />
                <Menu menu={'ผลงานของเรา'} url={'/testimonial'}/>
                <Menu menu={'การจัดการความรู้'} url={'/km'}/>

                {
                    /*
                    <Menu menu={'กิจกรรม'} url={'/activities'}/>
                    */
                }

                <Menu menu={'ข่าวสารองค์กร'} url={'/news'}/>
                <Menu menu={'ติดต่อเรา'} url={'/contact'}/>
            </div>

            <div className='flex justify-end self-center block xl:hidden' onClick={() => setShow_mobile_nav(true)}>
                <div className='cursor-pointer'>
                    <UilBars size="30" color='#524A4E' />
                </div>
            </div>

            <div className="lg:hidden" role="dialog" aria-modal="true" style={show_mobile_nav ? {display: "block"} : {display: "none"}}>
                <div className="fixed inset-0"></div>

                <div className="fixed inset-y-0 right-0 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 z-50">
                    <div className='flex justify-end my-3'>
                        <div className='cursor-pointer' onClick={() => setShow_mobile_nav(false)}>
                            <UilClose size="30" color='#524A4E' />
                        </div>
                    </div>

                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                <div className="notosan-reg text-web-black px-3 py-2"><Link to="/">หน้าแรก</Link></div>
                                <div className="notosan-reg text-web-black px-3 py-2"><Link to="/about">เกี่ยวกับเรา</Link></div>
                                <div className="notosan-reg text-web-black px-3 py-2"><Link to="/testimonial">ผลงานของเรา</Link></div>
                                <div className="notosan-reg text-web-black px-3 py-2"><Link to="/km">การจัดการความรู้</Link></div>

                                {
                                    /*
                                    <div className="notosan-reg text-web-black px-3 py-2"><Link to="/activities">กิจกรรม</Link></div>
                                    */
                                }

                                <div className="notosan-reg text-web-black px-3 py-2"><Link to="/news">ข่าวสารองค์กร</Link></div>
                                <div className="notosan-reg text-web-black px-3 py-2"><Link to="/contact">ติดต่อเรา</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header