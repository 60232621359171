import React from 'react';
import { Link } from 'react-router-dom';

const Cover = ({breadcrumbs, cover}) => {
    return (
        <div className='relative w-full h-60'>
            <div className='absolute top-0 left-0 w-full h-14 bg-black'>
                <div className='relative w-full h-full'>
                    <div className='absolute top-0 left-0 w-full h-full overflow-x-scroll no-scrollbar'>
                        <div className='relative w-fit h-full flex justify-start items-center gap-5 z-20 px-10'>
                            {
                                breadcrumbs.map((x, i) => {
                                    return (
                                        (i === breadcrumbs.length - 1) ?
                                            <p className='notosan-reg text-white -translate-y-0.5 text-nowrap'>{x.label}</p>
                                        :
                                            <>
                                                <Link to={x.url}><p className='notosan-reg text-white -translate-y-0.5 cursor-pointer text-nowrap'>{x.label}</p></Link>
                                                <p className='notosan-reg text-white -translate-y-0.5'>/</p>
                                            </>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className='absolute top-0 left-0 w-full h-full bg-gradient-to-r from-black opacity-80 z-10 py-3 px-4'></div>
                </div>
            </div>

            <div className='absolute bottom-0 left-0 w-full h-4/6 bg-gradient-to-t from-black opacity-30 z-10'></div>
            <img src={cover} className='relative w-full h-full object-cover'/>
        </div>
    )
}

export default Cover