import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import KM_paragraph from '../../KM/KM_paragraph';
import Admin_add_button from '../../Components/Button/Admin_add_button';
import Admin_KM_reorderable_list from './Admin_KM_Reorderable_list';
import { motion, useAnimationControls } from "framer-motion";

import how_to_insert_video1 from './../../../assets/admin/km/how_to_insert_video1.png';

const Detail_option = ({label, active, disabled, handle_click}) => {
    const [ hover, setHover ] = useState(false);

    return (
        <>
            {
                (disabled) ? 
                    <div className={'w-fit h-fit border rounded-full px-3 py-1.5 cursor-not-allowed bg-gray-100 border-gray-300 text-gray-400'}>{label}</div>
                :
                    <div className={'w-fit h-fit border rounded-full px-3 py-1.5 cursor-pointer transition-color duration-300 ' + ((hover || active) ? 'bg-zinc-700 text-white border-zinc-700' : 'bg-white text-web-black border-gray-300')}
                    onClick={handle_click} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>{label}</div>
            }
        </>
    )
}

const Detail_list_title = ({title}) => {
    const [ hover, setHover ] = useState(false);

    return (
        <div className={'w-full h-fit px-4 py-2 border border-gray-300 rounded-md my-px line-clamp-1 ' + (hover ? 'bg-zinc-700 ' : 'bg-white ')}
        onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <p className={'notosan-semi w-full h-fit line-clamp-1 ' + (hover ? 'text-white' : 'text-web-gold')}>{title}</p>
        </div>
    )
}

const Detail_type_Input_Image = forwardRef((props, ref) => {
    const google_drive_format = 'https://drive.google.com/thumbnail?id=IMAGEID&sz=w1200';
    const google_video_format = 'https://www.youtube.com/embed/VIDEOID?si=ugOH42VxbaxCsT5d';

    const [ url, setURL ] = useState('');
    const [ des, setDes ] = useState('');
    const [ text_detail_type, setText_detail_type ] = useState('');

    const ref_url = useRef(null);
    const ref_des = useRef(null);

    useEffect(() => {
        if(props.detail_type) {
            setURL('');
            setDes('');
            setText_detail_type('');

            if(props.detail_type === 'image') {
                setText_detail_type('รูปภาพ');
            }
            else if(props.detail_type === 'video') {
                setText_detail_type('วิดีโอ');
            }

            if(ref_url.current) {
                ref_url.current.value = '';
            }

            if(ref_des.current) {
                ref_des.current.value = '';
            }
        }
    }, [props.detail_type]);

    useEffect(() => {
        props.handle_update(url, des);
    }, [url, des]);

    useImperativeHandle(ref, () => ({
        clear_inputs: () => {
            if(ref_url.current) {
                ref_url.current.value = '';
            }

            if(ref_des.current) {
                ref_des.current.value = '';
            }
        },
        add_highlight: () => {
            if(ref_des.current) {
                let text = ref_des.current.value;
                let start = ref_des.current.selectionStart;
                let end = ref_des.current.selectionEnd;
                ref_des.current.value = text.slice(0, start) + '<HL><HL>' + text.slice(end);
            }
        },
        add_link: () => {
            if(ref_des.current) {
                let text = ref_des.current.value;
                let start = ref_des.current.selectionStart;
                let end = ref_des.current.selectionEnd;
                ref_des.current.value = text.slice(0, start) + '<LINK><URL><URL><LINK>' + text.slice(end);
            }
        },
        insert_inputs: (insert_url, insert_des) => {
            if(ref_url.current) {
                ref_url.current.value = insert_url;
            }

            if(ref_des.current) {
                ref_des.current.value = insert_des;
            }
        },
    }))

    return (
        <>
            <p className='notosan-reg text-web-black text-xs'>ลิงก์{text_detail_type}</p>
            <input ref={ref_url} type='text' placeholder={'กรุณาระบุ URL ของ' + text_detail_type + 'ที่ต้องการเพิ่ม'}
            className='notosan-reg w-full h-hit border border-gray-300 rounded-sm py-1.5 px-4 my-4 focus:outline focus:outline-mahidol-blue focus:outline-2 focus:outline-offset-1'
            disabled={props.paragraph_index === -1}
            onChange={(e) => setURL(e.target.value)}/>

            {
                (props.detail_type === 'image') ? 
                    <p className='notosan-reg text-web-black'><u>หมายเหตุ</u> 
                    <br/> - แนะนำให้เลือกใช้รูปที่ถ่ายภาพในแนวนอนเพื่อความสวยงามของเว็บไซต์
                    <br/> - กรณีต้องการอัพรูปจาก Google Drive ต้องทำการแปลงลิงก์ที่ได้จาก Google Drive เป็น 
                    <span className='notosan-reg text-web-gold cursor-pointer' onClick={() => {
                        if(ref_url.current) {
                            ref_url.current.value = google_drive_format;
                        }
                    }}><u>{google_drive_format}</u></span> แล้วเปลี่ยน <span className='notosan-reg text-web-gold'>IMAGEID</span> เป็น id รูปภาพจาก Google Drive</p>
                :
                    null
            }

            {
                (props.detail_type === 'video') ?
                    <>
                        <p className='notosan-reg text-web-black'><u>หมายเหตุ</u> 
                            <br/> - อัพโหลดวิดีโอลง Youtube หรือเข้าไปที่วิดีโอที่ต้องการบน Youtube
                            <br/> - ที่ลิงก์ของวิดีโอ เลือก id ของวิดีโอซึ่งจะอยู่ระหว่าง ?v=... และ ...&t= ของลิงก์
                        </p>

                        <img src={how_to_insert_video1} className='w-fit h-auto border border-gray-300 rounded-md my-4'></img>

                        <p className='notosan-reg text-web-black'>- นำ id ของวิดีโอที่ได้มาแทนที่ลงใน 
                            <span className='notosan-reg text-web-gold cursor-pointer'  
                            onClick={() => {
                                if(ref_url.current) {
                                    ref_url.current.value = google_video_format;
                                }
                            }}><u>{google_video_format}</u></span> แล้วเปลี่ยน <span className='notosan-reg text-web-gold'>VIDEOID</span> ให้เป็น id ที่ได้จากลิงก์ Youtube</p>
                    </>
                :
                    null
            }

            <p className='notosan-reg text-web-black text-xs mt-4'>คำอธิบายใต้{text_detail_type}</p>
            <textarea ref={ref_des} type='text' rows={4} placeholder={'โปรดระบุคำอธิบายใต้' + text_detail_type}
            className={'notosan-reg w-full h-hit border border-gray-300 rounded-md py-1.5 px-4 py-1 mt-2 focus:outline focus:outline-mahidol-blue focus:outline-2 focus:outline-offset-1 ' + (props.paragraph_index === -1 ? 'cursor-not-allowed' : '')}
            disabled={props.paragraph_index === -1}
            onChange={(e) => setDes(e.target.value)}/>
        </>
    )
})

const Detail_type_Text_Area = forwardRef((props, ref) => {
    const ref_textarea = useRef(null);

    useEffect(() => {
        if(
        props.detail_type === 'text' ||
        props.detail_type === 'bullet_number' ||
        props.detail_type === 'bullet_point' ||
        props.detail_type === 'bullet_dash' ||
        props.detail_type === 'quote'
        ) {
            if(ref_textarea.current) {
                if(props.modal_details && props.paragraph_index) {
                    if(props.paragraph_index !== -1) {
                        ref_textarea.current.value = props.modal_details[props.paragraph_index]['detail'];
                    }
                }
            }
        }
        else {
            if(ref_textarea.current) {
                ref_textarea.current.value = '';
            }
        }
    }, [props.detail_type]);

    useImperativeHandle(ref, () => ({
        clear_textarea: () => {
            if(ref_textarea.current) {
                ref_textarea.current.value = '';
            }
        },
        add_highlight: () => {
            if(ref_textarea.current) {
                let text = ref_textarea.current.value;
                let start = ref_textarea.current.selectionStart;
                let end = ref_textarea.current.selectionEnd;
                ref_textarea.current.value = text.slice(0, start) + '<HL><HL>' + text.slice(end);
            }
        },
        add_link: () => {
            if(ref_textarea.current) {
                let text = ref_textarea.current.value;
                let start = ref_textarea.current.selectionStart;
                let end = ref_textarea.current.selectionEnd;
                ref_textarea.current.value = text.slice(0, start) + '<LINK><URL><URL><LINK>' + text.slice(end);
            }
        },
        add_alignment_center: () => {
            if(ref_textarea.current) {
                let prev = ref_textarea.current.value;
                prev = prev.replace(/<CENTER>/g, '');
                ref_textarea.current.value = '<CENTER>' + prev + '<CENTER>';
            }
        },
        add_alignment_left: () => {
            if(ref_textarea.current) {
                let prev = ref_textarea.current.value;
                prev = prev.replace(/<CENTER>/g, '');
                ref_textarea.current.value = prev;
            }
        },
        insert_textarea: (insert_value) => {
            if(ref_textarea.current) {
                ref_textarea.current.value = insert_value;
            }
        },
    }));

    return (
        <>
            <p className='notosan-reg text-web-black text-xs'>รายละเอียด</p>
            <textarea ref={ref_textarea} type='text' rows={10} placeholder={(props.modal_details.length === 0) ? 'โปรดกดปุ่ม เพิ่มรายการ เพื่อให้สามารถกรอกข้อมูลได้' : 'โปรดระบุรายละเอียด (สำหรับ 1 ย่อหน้า)'} 
            className={'notosan-reg w-full h-hit border border-gray-300 rounded-md py-1.5 px-4 py-1 mt-2 focus:outline focus:outline-mahidol-blue focus:outline-2 focus:outline-offset-1 ' + (props.modal_details.length === 0 ? 'cursor-not-allowed ' : '') + ((props.paragraph_index === -1) ? 'cursor-not-allowed' : '')}
            disabled={props.paragraph_index === -1}
            onChange={(e) => props.handle_update(e.target.value)}/>
        </>
    );
})

const Select_detail_type = forwardRef((props, ref) => {
    const [ hover, setHover ] = useState(false);

    const ref_detail_type = useRef(null);

    useImperativeHandle(ref, () => ({
        update_detail_type: (type) => {
            const interval = setInterval(() => {
               if(ref_detail_type.current) {
                    const options = Array.from(ref_detail_type.current.options).map(x => x.value);
                    const selected_index = options.indexOf(type);
                    ref_detail_type.current.selectedIndex = selected_index;
                    
                    clearInterval(interval);
               }
            }, 100);
        },
    }));

    return (
        <>
            {
                (props.disabled) ?
                    <select disabled={true}
                    className={'w-fit h-fit border rounded-full px-3 py-1.5 cursor-not-allowed bg-gray-100 border-gray-300 text-gray-500'}>
                        <option value={'text'}>ข้อความ</option>
                        <option value={'bullet_number'}>ข้อความ - รายการ (หมายเลข)</option>
                        <option value={'bullet_point'}>ข้อความ - รายการ (•)</option>
                        <option value={'bullet_dash'}>ข้อความ - รายการ (-)</option>
                        <option value={'image'}>รูปภาพ</option>
                        <option value={'video'}>วิดีโอ</option>
                        <option value={'quote'}>ประโยคสำคัญ</option>
                        <option value={'space'}>บรรทัดว่าง</option>
                    </select>
                :
                    <select ref={ref_detail_type}
                    className={'w-fit h-fit border rounded-full px-3 py-1.5 cursor-pointer transition-color duration-300 ' + ((hover) ? 'bg-zinc-700 text-white border-zinc-700' : 'bg-white text-web-black border-gray-300')}
                    onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
                    onChange={(e) => props.handle_update(e.target.value)}>
                        <option value={'text'}>ข้อความ</option>
                        <option value={'bullet_number'}>ข้อความ - รายการ (หมายเลข)</option>
                        <option value={'bullet_point'}>ข้อความ - รายการ (•)</option>
                        <option value={'bullet_dash'}>ข้อความ - รายการ (-)</option>
                        <option value={'image'}>รูปภาพ</option>
                        <option value={'video'}>วิดีโอ</option>
                        <option value={'quote'}>ประโยคสำคัญ</option>
                        <option value={'space'}>บรรทัดว่าง</option>
                    </select>
            }
        </>
    )
})

const Select_margin = forwardRef((props, ref) => {
    const [ hover, setHover ] = useState(false);

    const ref_margin = useRef(null);

    useImperativeHandle(ref, () => ({
        update_margin: (value) => {
            if(ref_margin.current) {
                const options = Array.from(ref_margin.current.options).map(x => parseInt(x.value));
                const selected_index = options.indexOf(parseInt(value));
                ref_margin.current.selectedIndex = selected_index;
            }
        },
    }));

    return (
        <>
            {
                (props.disabled) ?
                    <select disabled={props.disabled}
                    className={'w-fit h-fit border rounded-full px-3 py-1.5 cursor-not-allowed bg-gray-100 border-gray-300 text-gray-500'}>
                        <option value={0}>ระยะขอบ x 0</option>
                    </select>
                :
                    <select 
                    ref={ref_margin}
                    className={'w-fit h-fit border rounded-full px-3 py-1.5 cursor-pointer transition-color duration-300 ' + ((hover) ? 'bg-zinc-700 text-white border-zinc-700' : 'bg-white text-web-black border-gray-300')}
                    onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
                    onChange={(e) => props.handle_update(e.target.value)}>
                        <option value={0}>ระยะขอบ x 0</option>
                        <option value={1}>ระยะขอบ x 1</option>
                        <option value={2}>ระยะขอบ x 2</option>
                        <option value={3}>ระยะขอบ x 3</option>
                        <option value={4}>ระยะขอบ x 4</option>
                        <option value={5}>ระยะขอบ x 5</option>
                        <option value={6}>ระยะขอบ x 6</option>
                    </select>
            }
        </>
    )
})

const Input_bullet = forwardRef((props, ref) => {
    const [ bullet, setBullet ] = useState(1);

    useImperativeHandle(ref, () => ({
        insert_value: (value) => setBullet(value),
    }))

    return (
        <input type='number' className={'w-16 h-full border border-gray-300 rounded-lg px-2 ' + (props.active ? 'visible' : 'invisible')} value={bullet}
        onChange={(e) => {
            setBullet(e.target.value);
            props.handle_update(e.target.value);
        }}></input>
    ) 
})

const Select_alignment = forwardRef((props, ref) => {
    const [ hover, setHover ] = useState(false);

    const ref_margin = useRef(null);

    useImperativeHandle(ref, () => ({
        update_margin: (value) => {
            if(ref_margin.current) {
                const options = Array.from(ref_margin.current.options).map(x => parseInt(x.value));
                const selected_index = options.indexOf(parseInt(value));
                ref_margin.current.selectedIndex = selected_index;
            }
        },
    }));

    return (
        <>
            {
                (props.isShow) ?
                    (props.disabled) ? 
                        <select 
                        disabled={props.disabled}
                        className={'w-fit h-fit border rounded-full px-3 py-1.5 cursor-not-allowed bg-gray-100 border-gray-300 text-gray-500'}>
                            <option value={0}>ชิดซ้าย</option>
                            <option value={1}>กึ่งกลาง</option>
                        </select>
                    :
                        <select 
                        ref={ref_margin}
                        className={'w-fit h-fit border rounded-full px-3 py-1.5 cursor-pointer transition-color duration-300 ' + ((hover) ? 'bg-zinc-700 text-white border-zinc-700' : 'bg-white text-web-black border-gray-300')}
                        onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
                        onChange={(e) => props.handle_update(e.target.value)}>
                            <option value={0}>ชิดซ้าย</option>
                            <option value={1}>กึ่งกลาง</option>
                        </select>
                :
                    null
            }
        </>
    )
})

const Modal_Admin_KM_add_detail = ({isShow, title, details, handle_cancel, handle_submit}) => {
    const [ modal_details, setModal_details ] = useState([]);
    const [ modal_indent, setModal_indent ] = useState(false);
    const [ paragraph_index, setParagraph_index ] = useState(-1);
    const [ detail_type, setDetail_type ] = useState('text');
    const [ margin, setMargin ] = useState(0);
    const [ bullet, setBullet ] = useState(1);

    const [ mode_add_text, setMode_add_text ] = useState(false);
    const [ mode_add_bullet, setMode_add_bullet ] = useState(false);
    const [ mode_add_img, setMode_add_img ] = useState(false);
    const [ mode_add_video, setMode_add_video ] = useState(false);
    const [ mode_add_quote, setMode_add_quote ] = useState(false);
    const [ mode_add_space, setMode_add_space ] = useState(false);

    const [ disable_alignment, setDisable_opt_alignment ] = useState(true);
    const [ disable_detail_type, setDisable_opt_detail_type ] = useState(true);
    const [ disable_margin, setDisable_opt_margin ] = useState(true);
    const [ disable_opt_indent, setDisable_opt_indent ] = useState(true);
    const [ disable_opt_highlight, setDisable_opt_highlight ] = useState(true);
    const [ disable_opt_url, setDisable_opt_url ] = useState(true);

    const [ active_submit_btn, setActive_submit_btn ] = useState(false);

    const ani_controls_paragraph = useAnimationControls();
    const ani_controls_image = useAnimationControls();

    const ref_select_type = useRef(null);
    const ref_text_area = useRef(null);
    const ref_url = useRef(null);
    const ref_bullet = useRef(null);
    const ref_margin = useRef(null);

    const ani_obj = {
        hidden: {
            opacity: 0,
            transition: { duration: 0.2 }
        },
        visible: {
            opacity: 1,
            transition: { duration: 0.8 }
        }
    }

    useEffect(() => {
        if(isShow) {
            if(details.length > 0) {
                const deepCopy = (arr) => arr.map((item) => ({ ...item }));
                setModal_details(deepCopy(details));
                setParagraph_index(-1);
            }

            //automatic component change when detail type changed
            //However, automatic component changed somehow not be triggered when close the modal, although setDetail_type('text') is set on closed
            //so, set component again once open
            if(ref_select_type.current) {
                ref_select_type.current.update_detail_type(detail_type);
            }

            //again, modes will be automatically set when detail_type changed
            //all modes are set 'false' once closed
            //since detail_type changed cannot be triggered during open, modes cannot be changed on open too
            //we have to change mode manually here
            if(details.length === 0) {
                setMode_add_text(false);
                setMode_add_bullet(false);
                setMode_add_img(false);
                setMode_add_video(false);
                setMode_add_quote(false);
                setMode_add_space(false);
            }
            else {
                update_mode(detail_type);
            }

            //disable options if list empty
            if(modal_details.length === 0) {
                setDisable_opt_alignment(true);
                setDisable_opt_detail_type(true);
                setDisable_opt_margin(true);
                setDisable_opt_indent(true);
                setDisable_opt_highlight(true);
                setDisable_opt_url(true);
            }
        }
        else {
            setModal_details([]);
            setModal_indent(false);
            setParagraph_index(-1);
            setDetail_type('text');
            setMargin(0);
            setBullet(1);

            setMode_add_text(false);
            setMode_add_bullet(false);
            setMode_add_img(false);
            setMode_add_video(false);
            setMode_add_quote(false);
            
            setDisable_opt_alignment(true);
            setDisable_opt_detail_type(true);
            setDisable_opt_margin(true);
            setDisable_opt_indent(true);
            setDisable_opt_highlight(true);
            setDisable_opt_url(true);

            setActive_submit_btn(false);

            if(ref_text_area.current) {
                ref_text_area.current.clear_textarea();
            }

            if(ref_url.current) {
                ref_url.current.clear_inputs();
            }
        }
    }, [isShow]);
    
    useEffect(() => {
        //console.log('type');
        //console.log(detail_type);

        //refresh mode
        update_mode(detail_type);

        //set active of submit button
        let active_btn = false;

        if(modal_details.length > 0) {
            for(let i = 0; i < modal_details.length; i++) {
                if(modal_details[i].detail !== null) {
                    active_btn = true;
                    break;
                }
            }
        }

        setActive_submit_btn(active_btn);
    }, [modal_details]);

    useEffect(() => {
        //update modal detail
        if((paragraph_index !== -1) && (paragraph_index === modal_details.length - 1)) {
            let prev = [...modal_details];
            
            if(detail_type === 'space') {
                prev[paragraph_index] =  {
                    paragraph: paragraph_index,
                    type: 'space',
                    indent: false,
                    margin: 0,
                    url: null,
                    bullet: null,
                    detail: '',
                }
            }
            else {
                prev[paragraph_index].type = detail_type;
                prev[paragraph_index].bullet = (paragraph_index !== -1 && (detail_type === 'bullet_number' || detail_type === 'bullet_point' || detail_type === 'bullet_dash')) ? bullet : null;
            }

            setModal_details(prev);
        }

        //refresh mode
        update_mode(detail_type);

        //update component
        if(ref_select_type.current) {
            ref_select_type.current.update_detail_type(detail_type);
        }

        //reset necessary state
        setBullet(1);
    }, [detail_type]);

    useEffect(() => {
        console.log('mode_add_text');
        console.log(mode_add_text);

        if(mode_add_text) {
            console.log('mode_add_text');

            setModal_indent(true);

            //only mode_add_text, select component for text align will appear
            setDisable_opt_alignment(false);
            setDisable_opt_detail_type(false);
            setDisable_opt_margin(false);
            setDisable_opt_indent(false);
            setDisable_opt_highlight(false);
            setDisable_opt_url(false);

            ani_controls_paragraph.start("hidden");
            ani_controls_image.start("hidden");

            setTimeout(() => {
                ani_controls_paragraph.start("visible");
            }, 500);
        }
    }, [mode_add_text]);

    useEffect(() => {
        if(mode_add_bullet) {
            setModal_indent(false);

            //only mode_add_text, select component for text align will appear
            setDisable_opt_alignment(true);
            setDisable_opt_detail_type(false);
            setDisable_opt_margin(false);
            setDisable_opt_indent(true);
            setDisable_opt_highlight(false);
            setDisable_opt_url(false);

            ani_controls_paragraph.start("hidden");
            ani_controls_image.start("hidden");

            setTimeout(() => {
                ani_controls_paragraph.start("visible");
            }, 500);
        }
    }, [mode_add_bullet]);

    useEffect(() => {
        if(mode_add_img) {
            setModal_indent(false);

            //only mode_add_text, select component for text align will appear
            setDisable_opt_alignment(true);
            setDisable_opt_detail_type(false);
            setDisable_opt_margin(true);
            setDisable_opt_indent(true);
            setDisable_opt_highlight(true);
            setDisable_opt_url(true);

            ani_controls_paragraph.start("hidden");
            ani_controls_image.start("hidden");

            setTimeout(() => {
                ani_controls_image.start("visible");
            }, 500);
        }
    }, [mode_add_img]);

    useEffect(() => {
        if(mode_add_video) {
            setModal_indent(false);

            //only mode_add_text, select component for text align will appear
            setDisable_opt_alignment(true);
            setDisable_opt_detail_type(false);
            setDisable_opt_margin(true);
            setDisable_opt_indent(true);
            setDisable_opt_highlight(true);
            setDisable_opt_url(true);

            ani_controls_paragraph.start("hidden");
            ani_controls_image.start("hidden");

            setTimeout(() => {
                ani_controls_image.start("visible");
            }, 500);
        }
    }, [mode_add_video]);

    useEffect(() => {
        if(mode_add_quote) {
            setModal_indent(false);

            //only mode_add_text, select component for text align will appear
            setDisable_opt_alignment(true);
            setDisable_opt_detail_type(false);
            setDisable_opt_margin(true);
            setDisable_opt_indent(true);
            setDisable_opt_highlight(false);
            setDisable_opt_url(true);

            ani_controls_paragraph.start("hidden");
            ani_controls_image.start("hidden");

            setTimeout(() => {
                ani_controls_paragraph.start("visible");
            }, 500);
        }
    }, [mode_add_quote]);

    useEffect(() => {
        if(mode_add_space) {
            setModal_indent(false);

            //only mode_add_text, select component for text align will appear
            setDisable_opt_alignment(true);
            setDisable_opt_detail_type(false);
            setDisable_opt_margin(true);
            setDisable_opt_indent(true);
            setDisable_opt_highlight(true);
            setDisable_opt_url(true);

            ani_controls_paragraph.start("hidden");
            ani_controls_image.start("hidden");
            //space type has no tool to present
        }
    }, [mode_add_space]);

    useEffect(() => {
        if(ref_bullet.current) {
            ref_bullet.current.insert_value(bullet);
        }
    }, [bullet]);

    useEffect(() => {
        if(paragraph_index !== -1) {
            const selected_detail_type = modal_details[paragraph_index]['type'];
            update_mode(selected_detail_type);

            if(ref_select_type.current) {
                ref_select_type.current.update_detail_type(selected_detail_type);
            }
        }
    }, [paragraph_index]);

    useEffect(() => {
        if(ref_margin.current) {
            ref_margin.current.update_margin(margin);
        }
    }, [margin]);

    function handle_click_info() {
        alert('test');
    }

    async function update_mode(type) {
        await reset_modes();
        await set_modes(type);

        function reset_modes() {
            return new Promise((resolve) => {
                setMode_add_text(false);
                setMode_add_bullet(false);
                setMode_add_img(false);
                setMode_add_video(false);
                setMode_add_quote(false);
                setMode_add_space(false);

                resolve();
            })
        }

        function set_modes(type) {
            return new Promise((resolve) => {
                if(paragraph_index !== -1) {
                    setMode_add_text(type === 'text');
                    setMode_add_bullet(type === 'bullet_number' || type === 'bullet_point' || type === 'bullet_dash');
                    setMode_add_img(type === 'image');
                    setMode_add_video(type === 'video');
                    setMode_add_quote(type === 'quote');
                    setMode_add_space(type === 'space');
                }

                resolve();
            })
        }
    }

    return (
        <div className={isShow ? 'block' : 'hidden'}>
            <div className='absolute top-0 left-0 w-screen h-screen flex justify-center items-center z-50'>
                <div className='w-11/12 h-full flex flex-col px-6 sm:px-10 py-10 bg-white rounded-md shadow-md overflow-y-scroll overflow-x-visible'>
                    <p className='notosan-reg text-web-black' onClick={() => handle_click_info()}>โปรดระบุรายละเอียด (สำหรับ 1 ย่อหน้า) 
                        <span className='inline-block cursor-pointer px-px scale-75 -translate-y-1.5'><box-icon type='solid' name='info-circle'></box-icon></span>
                    </p>

                    <div className='w-full h-96 grow my-4 grid grid-cols-3 gap-x-5'>
                        <div className='w-full h-full overflow-y-scroll col-span-2'>
                            <p className='notosan-reg text-web-black text-xs'>ตัวอย่าง</p>

                            {
                                (modal_details.length > 0) ?
                                    <div className='w-full h-fit my-4 pr-3'>
                                        <h3 className='text-web-gold text-2xl notosan-med'>{title}</h3>
                                        <KM_paragraph details={modal_details} last={true}/>
                                    </div>
                                :
                                    <p className='notosan-reg text-web-black text-center'>ยังไม่มีตัวอย่าง</p>
                            }
                        </div>

                        <div className='w-full h-full overflow-y-scroll'>
                            <p className='notosan-reg text-web-black text-xs'>รายการ {modal_details.length}</p>

                            <Detail_list_title title={title}/>

                            <Admin_KM_reorderable_list 
                            list={modal_details}
                            paragraph_index={paragraph_index}
                            handle_list_select={(index) => {
                                let prev = [...modal_details];

                                if(index === -1) {
                                    prev[paragraph_index].type = 'text';
                                    setModal_details(prev);

                                    setMode_add_img(false);
                                    setMode_add_quote(false);
                                }
                                else {
                                    const selected_type = prev[index].type;
                                    const selected_bullet = prev[index].bullet;
                                    const selected_margin = prev[index].margin;

                                    setDetail_type(selected_type);
                                    setBullet(selected_bullet);
                                    setMargin(selected_margin);
                                }

                                setParagraph_index(index);
                            }}
                            handle_reorder={(reorder_array) => {
                                let result = [];

                                for(let i = 0; i < reorder_array.length; i++) {
                                    let obj = reorder_array[i];

                                    obj.paragraph = i;
                                    result.push(obj);
                                }

                                setModal_details(result);
                            }}
                            handle_delete={(delete_index) => {
                                let prev = [...modal_details];
                                prev.splice(delete_index, 1);
                                setModal_details(prev);
                            }}/>

                            <div className={'w-full h-10 mt-2'}>
                                <Admin_add_button label={'เพิ่มรายการ'} handle_click={() => {
                                    //set bullet
                                    const new_bullet = (mode_add_bullet) ? bullet + 1 : bullet;
                                    setBullet(new_bullet);

                                    //set paragraph
                                    let paragraph = 0;

                                    if(paragraph_index === -1) {
                                        //if === -1, it could mean either empty or not
                                        paragraph = (modal_details.length === 0) ? 0 : modal_details.length + 1;
                                    }
                                    else {
                                        //if !== -1, it always means that modal_details is not empty
                                        paragraph = paragraph_index + 1;
                                    }

                                    const obj_detail = {
                                        paragraph: paragraph,
                                        type: detail_type,
                                        indent: modal_indent,
                                        margin: margin,
                                        url: null,
                                        bullet: mode_add_bullet ? new_bullet : null,
                                        detail: null,
                                    };

                                    const obj_space = {
                                        paragraph: (paragraph_index === -1) ? 0 : paragraph_index + 1,
                                        type: detail_type,
                                        indent: false,
                                        margin: 0,
                                        url: null,
                                        bullet: null,
                                        detail: '',
                                    };
                                    
                                    setModal_details([...modal_details, (detail_type === 'space') ? obj_space : obj_detail]);
                                    setParagraph_index(modal_details.length);
                                    setMargin((detail_type === 'space') ? obj_space.margin : obj_detail.margin);

                                    if(ref_text_area.current) {
                                        ref_text_area.current.clear_textarea();
                                    }

                                    if(ref_url.current) {
                                        ref_url.current.clear_inputs();
                                    }
                                }}/>
                            </div>
                        </div>
                    </div>

                    <hr className='w-full my-8'/>

                    <div className='w-full h-fit mt-4'>
                        <motion.div
                        className={'w-full ' + ((mode_add_text || mode_add_bullet || mode_add_quote) ? 'h-fit' : 'h-0 overflow-hidden')}
                        variants={ani_obj}
                        initial="hidden"
                        animate={ani_controls_paragraph}>
                            <Detail_type_Text_Area
                            ref={ref_text_area}
                            paragraph_index={paragraph_index}
                            modal_details={modal_details}
                            detail_type={detail_type}
                            handle_update={(value) => {
                                if(paragraph_index !== -1) {
                                    let prev = [...modal_details];
                                    prev[paragraph_index].type = detail_type;
                                    prev[paragraph_index].indent = modal_indent;
                                    prev[paragraph_index].url = null;
                                    prev[paragraph_index].detail = (value === '') ? null : value;
                                    setModal_details(prev);
                                }
                            }}/>
                        </motion.div>

                        <motion.div
                        className={'w-full ' + ((mode_add_img || mode_add_video) ? 'h-fit' : 'h-0 overflow-hidden')}
                        variants={ani_obj}
                        initial="hidden"
                        animate={ani_controls_image}>
                            <Detail_type_Input_Image
                            ref={ref_url}
                            paragraph_index={paragraph_index}
                            modal_details={modal_details}
                            detail_type={detail_type}
                            handle_update={(url, des) => {
                                if(paragraph_index !== -1) {
                                    let prev = [...modal_details];
                                    prev[paragraph_index].type = detail_type;
                                    prev[paragraph_index].indent = false;
                                    prev[paragraph_index].url = url;
                                    prev[paragraph_index].detail = des;
                                    setModal_details(prev);
                                }
                            }}/>
                        </motion.div>
                    </div>

                    <div className='w-full h-fit flex justify-end gap-x-1.5 mt-px'>
                        <Input_bullet 
                        ref={ref_bullet}
                        active={mode_add_bullet}
                        handle_update={(value) => {
                            setBullet(value);

                            let new_bullet = null;

                            if(detail_type === 'bullet_number' || detail_type === 'bullet_point' || detail_type === 'bullet_dash') {
                                new_bullet = value;
                            }

                            let prev = [...modal_details];
                            prev[paragraph_index].bullet = new_bullet;
                            setModal_details(prev);
                        }}/>

                        <Select_alignment
                        isShow={mode_add_text}
                        disabled={disable_alignment}
                        handle_update={(value) => {
                            if(ref_text_area.current) {
                                let prev = [...modal_details];
                                let prev_text = prev[paragraph_index]['detail'];

                                if(parseInt(value) === 0) { //align left
                                    prev_text = prev_text.replace(/<CENTER>/g, '');
                                    ref_text_area.current.add_alignment_left();
                                }
                                else if(parseInt(value) === 1) { //align center
                                    prev_text = '<CENTER>' + prev_text + '<CENTER>';
                                    ref_text_area.current.add_alignment_center();
                                }

                                prev[paragraph_index]['detail'] = prev_text;
                                setModal_details([...prev]);
                            }
                        }}/>

                        <Select_detail_type 
                        ref={ref_select_type}
                        disabled={disable_detail_type}
                        handle_update={(detail_type) => {
                            setDetail_type(detail_type);
                        }}/>

                        <Select_margin 
                        ref={ref_margin}
                        disabled={disable_margin} 
                        handle_update={(margin_value) => {
                            if(paragraph_index !== -1) {
                                let prev = [...modal_details];
                                prev[paragraph_index].margin = margin_value;
                                setModal_details(prev);
                                setMargin(parseInt(margin_value));
                            }
                        }}/>

                        <Detail_option label={'การเยื้อง'} 
                        active={modal_indent}
                        disabled={disable_opt_indent}
                        handle_click={() => {
                            if(paragraph_index !== -1) {
                                const new_indent = !modal_indent;
                                setModal_indent(new_indent);

                                let prev = [...modal_details];
                                prev[paragraph_index].indent = new_indent;
                                setModal_details(prev);
                            }
                        }}/>

                        <Detail_option label={'เพิ่มส่วนไฮไลท์'} 
                        active={false}
                        disabled={disable_opt_highlight}
                        handle_click={() => {
                            if(paragraph_index !== -1) {
                                let prev = [...modal_details];
                                prev[paragraph_index].detail = prev[paragraph_index].detail + '<HL><HL>';
                                setModal_details(prev);
                            }

                            if(ref_text_area.current) {
                                ref_text_area.current.add_highlight();
                            }
                        }}/>

                        <Detail_option label={'เพิ่มลิงก์'} 
                        active={false}
                        disabled={disable_opt_url}
                        handle_click={() => {
                            if(paragraph_index !== -1) {
                                let prev = [...modal_details];
                                prev[paragraph_index].detail = prev[paragraph_index].detail + '<LINK><URL><URL><LINK>';
                                setModal_details(prev);
                            }

                            if(ref_text_area.current) {
                                ref_text_area.current.add_link();
                            }
                        }}/>
                    </div>

                    <div className={'flex flex-col-reverse sm:grid sm:grid-cols-2 gap-3 w-full h-auto min-h-12 mt-8'}>
                        <button className='notosan-med w-full h-full bg-gray-300 hover:bg-gray-500 py-3 rounded-md' 
                        onClick={() => handle_cancel()}>ยกเลิก</button>

                        <button type='button' 
                        className={'notosan-med w-full h-full py-3 rounded-md ' + (active_submit_btn ? 'bg-mahidol-blue hover:bg-mahidol-yellow text-white hover:text-web-black cursor-pointer' : 'bg-gray-300 text-gray-500 cursor-not-allowed')}
                        onClick={() => handle_submit(title, modal_details)}>ยืนยัน</button>
                    </div>
                </div>
            </div>

            <div className='absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-40'></div>
        </div>
    )
}

export default Modal_Admin_KM_add_detail