import React, { useEffect, useRef, useState } from 'react';
import '../../Testimonial/testimonial.css';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import cover from '../../../assets/cover/cover_testimonial.png';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import HomeTestimonial_Card from '../../Home/HomeTestimonial/HomeTestimonial_Card';
import { status_list } from '../../Testimonial/status_list';
import { php_list } from '../../../php/php_list';
import axios from 'axios';

import { motion, useAnimationControls } from 'framer-motion';
import TestimonialIntroduction from '../../Testimonial/TestimonialIntroduction';
import SearchInput from '../../Components/Input/SearchInput';

import { default as Web_pagination } from '../../Components/Pagination/Pagination';
import ModalTestimonial_detail from '../../Components/Modal/ModalTestimonial/ModalTestimonial_detail';

import { UilEye } from '@iconscout/react-unicons'
import ModalTestimonial_detail_admin from '../../Components/Modal/ModalTestimonial/ModalTestimonial_detail_admin';
import Admin_add_button from '../../Components/Button/Admin_add_button';
import ModalConfirmForm_admin from '../../Components/Modal/ModalConfirmForm/ModalConfirmForm_admin';
import ModalTestimonial_admin_add_favorite from '../../Components/Modal/ModalTestimonial/ModalTestimonial_admin_add_favorite';
import Testimonial_Card_Favorite from '../../Testimonial/Testimonial_Card_Favorite';
import title_decorate from '../../../assets/decorate/title_decorate.svg';

const Testimonial_items = ({image, title, founder}) => {
    const [ hover, setHover ] = useState(false);

    return (
        <div className='w-full h-fit cursor-pointer' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <div className='w-full h-fit flex justify-center items-center'>
                <img src={image} className={'w-40 h-40 object-cover rounded-full transition duration-300 ' + (hover ? '-translate-y-3' : 'translate-y-0')}></img>
            </div>
            
            <p className={'notosan-semi mt-4 line-clamp-2 ' + (hover ? 'underline text-web-gold' : 'text-web-black')}>{title}</p>
            <p className={'notosan-reg text-web-black text-sm line-clamp-2 ' + (hover ? 'text-web-gold' : 'text-web-black')}>โดย {founder.join(', ')}</p>
        </div>
    )
}

const Admin_testimonial_item = (props) => {
    return (
        <div className='relative w-full h-full' onClick={props.handle_clicked}>
            <motion.div className='w-full h-fit'
            variants={props.motion_obj}
            initial={'hidden'}
            animate={props.motion_control}
            custom={props.motion_index}
            >
                <Testimonial_items image={props.image} title={props.title} founder={props.founder}/>
            </motion.div>

            <div className={'absolute top-0 left-0 w-full h-full rounded-md flex justify-center items-center bg-black/50 z-10 ' + (props.active ? 'block' : 'hidden')}>
                <div className='w-fit h-fit flex gap-3'>
                    <Admin_testimonial_item_icon_look active={props.active} handle_clicked={props.handle_clicked_look}/>
                    <Admin_testimonial_item_icon_edit active={props.active} handle_clicked={props.handle_clicked_edit}/>
                    <Admin_testimonial_item_icon_delete active={props.active} handle_clicked={props.handle_clicked_delete}/>
                </div>
            </div>
        </div>
    )
}

const Admin_testimonial_item_icon_look = ({active ,handle_clicked}) => {
    const [ hover, setHover ] = useState(false);

    const animation_controls = useAnimationControls();
    const ani_obj = {
        hidden: {
            opacity: 0,
            y: 0,
            transition: { duration: 0 }
        },
        visible: (index) => ({
            opacity: 1,
            y: -2,
            transition: { duration: 0.5, delay: index * 0.1 }
        })
    }

    useEffect(() => {
        animation_controls.start((active) ? "visible" : "hidden")
    }, [active]);

    return (
        <motion.div className='w-fit h-fit cursor-pointer z-20' onClick={handle_clicked}
        onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        variants={ani_obj}
        initial={"hidden"}
        animate={animation_controls}
        custom={0}>
            <div className={'w-10 h-10 rounded-full bg-white flex justify-center items-center shadow transition duration-300 ' + (hover ? '-translate-y-1.5' : '')}>
                <UilEye size="25" color="#555555"/>
            </div>

            <div className={'w-10 h-fit transition duration-300 ' + (hover ? 'opacity-1' : 'opacity-0')}>
                <p className='notosan-reg text-white text-sm text-center pt-2'>ดู</p>
            </div>
        </motion.div>
    )
}

const Admin_testimonial_item_icon_edit = ({active, handle_clicked}) => {
    const [ hover, setHover ] = useState(false);

    const animation_controls = useAnimationControls();
    const ani_obj = {
        hidden: {
            opacity: 0,
            y: 0,
            transition: { duration: 0 }
        },
        visible: (index) => ({
            opacity: 1,
            y: -2,
            transition: { duration: 0.5, delay: index * 0.1 }
        })
    }

    useEffect(() => {
        animation_controls.start((active) ? "visible" : "hidden");
    }, [active]);

    return (
        <motion.div className='w-fit h-fit cursor-pointer z-20' onClick={handle_clicked}
        onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        variants={ani_obj}
        initial={"hidden"}
        animate={animation_controls}
        custom={1}>
            <div className={'w-10 h-10 rounded-full bg-white flex justify-center items-center shadow duration-300 ' + (hover ? '-translate-y-1.5' : '')}>
                <box-icon name='edit' type='solid' color='#555555'></box-icon>
            </div>

            <div className={'w-10 h-fit transition duration-300 ' + (hover ? 'opacity-1' : 'opacity-0')}>
                <p className='notosan-reg text-white text-sm text-center pt-2'>แก้ไข</p>
            </div>
        </motion.div>
    )
}

const Admin_testimonial_item_icon_delete = ({active, handle_clicked}) => {
    const [ hover, setHover ] = useState(false);

    const animation_controls = useAnimationControls();
    const ani_obj = {
        hidden: {
            opacity: 0,
            y: 0,
            transition: { duration: 0 }
        },
        visible: (index) => ({
            opacity: 1,
            y: -2,
            transition: { duration: 0.5, delay: index * 0.1 }
        })
    }

    useEffect(() => {
        animation_controls.start((active) ? "visible" : "hidden");
    }, [active]);

    return (
        <motion.div className='w-fit h-fit cursor-pointer z-20' onClick={handle_clicked}
        onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        variants={ani_obj}
        initial={"hidden"}
        animate={animation_controls}
        custom={2}>
            <div className={'w-10 h-10 rounded-full bg-white flex justify-center items-center shadow duration-300 ' + (hover ? '-translate-y-1.5' : '')}>
                <box-icon name='trash' color="#555555"></box-icon>
            </div>

            <div className={'w-10 h-fit transition duration-300 ' + (hover ? 'opacity-1' : 'opacity-0')}>
                <p className='notosan-reg text-white text-sm text-center pt-2'>ลบ</p>
            </div>
        </motion.div>
    )
}

const Admin_testimonial = () => {
    const [ page, setPage ] = useState(1);
    const [ items_per_page, setItems_per_page ] = useState(20);
    const [ max_page, setMax_page ] = useState(1);

    const [ modal_is_show, setModal_is_show ] = useState(false);
    const [ modal_add_is_show, setModal_add_is_show ] = useState(false);
    const [ modal_detail, setModal_detail ] = useState({});
    const [ modal_add_favorite, setModal_add_favorite ] = useState(false);

    const [ db, setDB ] = useState([]);
    const [ favoriteItems, setFavoriteItems ] = useState([]);
    const [ favoriteItems_for_update_db, setFavoriteItems_for_update_db ] = useState([]);
    const [ items, setItems ] = useState([]);
    const ref_favorite_item = useRef(null);
    const [ favoriteItems_width, setFavoriteItems_width ] = useState(0);
    const ref_favorite_item_mobile = useRef(null);
    const [ favoriteItems_width_mobile, setFavoriteItems_width_mobile ] = useState(0);

    const [ search_text, setSearch_text ] = useState('');
    const [ filter_sort, setFilster_sort ] = useState('default');
    const [ filter_status, setFilter_status ] = useState('default');
    const ref_filter_opt = useRef(null);

    const [ active_items, setActive_items ] = useState([]);
    const [ selectedID, setSelectedID ] = useState(-1);

    const [ cover_for_add_db, setCover_for_add_db ] = useState('');
    const [ title_for_add_db, setTitle_for_add_db ] = useState('');
    const [ founder_for_add_db, setFounder_for_add_db ] = useState([]);
    const [ founder_org_for_add_db, setFounder_org_for_add_db ] = useState([]);
    const [ url_for_add_db, setURL_for_add_db ] = useState([]);
    const [ status_for_add_db, setStatus_for_add_db ] = useState('');
    const [ description_for_add_db, setDescription_for_add_db ] = useState([]);
    const [ timeline_for_add_db, setTimeline_for_add_db ] = useState([]);
    const [ language_for_add_db, setLanguage_for_add_db ] = useState(-1);

    const navigate = useNavigate();
    const { authorised } = useParams('authorised');

    useEffect(() => {
        if(authorised !== 'authorised') {
            navigate("/admin");
        }

        let abortcontroller = new AbortController();

        axios.get(php_list.php_testimonials_read_all, { signal: abortcontroller.signal })
        .then((response) => {
            // Handle the success response
            //console.log('xxxyyyzzz');
            console.log(response.data);

            /*
            {
    "success": true,
    "message": "Data loaded successfully.",
    "results": [
        {
            "id": "69",
            "title": "การพัฒนาการตรวจวินิจฉัยสาเหตุทางพันธุกรรมในผู้ป่วยกลุ่มออทิซึม พัฒนาการล่าช้า/สติปัญญาบกพร่อง และพิการซ้ำซ้อนแต่กำเนิด ด้วยวิธีโครโมโซมอะเรย์ ความละเอียดสูง (Chromosomal Microarray: CMA)",
            "status": "เสร็จสิ้นการดำเนินงาน",
            "image": "https://drive.google.com/thumbnail?id=1MnnPS9pGYcE5Q05-bgwVhE6tgC8Vh6G0&sz=w1000",
            "favorite": "1",
            "founders": "ดร.พญ.ณฐินี  จินาวัฒน์ หลักสูตรเวชศาสตร์ปริวรรต   สำนักงานวิจัย วิชาการและนวัตกรรม คณะแพทยศาสตร์โรงพยาบาลรามาธิบดี,หลักสูตรเวชศาสตร์ปริวรรต และหน่วยห้องปฏิบัติการชีวโมเลกุล สำนักงานวิจัย วิชาการและนวัตกรรม หน่วยเวชพันธุศาสตร์  ภาควิชากุมารเวชศาสตร์ หน่วยพัฒนาการเด็ก  ภาควิชากุมารเวชศาสตร์",
            "founders_type": "1,1",
            "language": "0",
            "founder": [
                "ดร.พญ.ณฐินี  จินาวัฒน์ หลักสูตรเวชศาสตร์ปริวรรต   สำนักงานวิจัย วิชาการและนวัตกรรม คณะแพทยศาสตร์โรงพยาบาลรามาธิบดี",
                "หลักสูตรเวชศาสตร์ปริวรรต และหน่วยห้องปฏิบัติการชีวโมเลกุล สำนักงานวิจัย วิชาการและนวัตกรรม หน่วยเวชพันธุศาสตร์  ภาควิชากุมารเวชศาสตร์ หน่วยพัฒนาการเด็ก  ภาควิชากุมารเวชศาสตร์"
            ]
        },
        {
            "id": "68",
            "title": "ผลักดันแนวทางการดูแลภาวะโภชนาการและสุขาภิบาลอาหารในระดับท้องถิ่น",
            "status": "เสร็จสิ้นการดำเนินงาน",
            "image": "https://drive.google.com/thumbnail?id=1rVpfv0PHdi7szMjIJxV4tovWWji1ZD7O&sz=w1000",
            "favorite": "0",
            "founders": "ศูนย์นโยบายและการจัดการสุขภาพ,ศ.ดร.นพวรรณ เปียซื่อ และทีมงาน,สถาบันโภชนาการ กรมอนามัย กระทรวงสาธารณสุข",
            "founders_type": "1,0,1",
            "language": "0",
            "founder": [
                "ศูนย์นโยบายและการจัดการสุขภาพ",
                "ศ.ดร.นพวรรณ เปียซื่อ และทีมงาน",
                "สถาบันโภชนาการ กรมอนามัย กระทรวงสาธารณสุข"
            ]
        },
        {
            "id": "67",
            "title": "การผลักดันแนวทางการคัดกรองภาวะความดันเลือดสูงในเด็กที่มีโรคอ้วนสำหรับสถานบริการสาธารณสุข และสำหรับสถานศึกษา",
            "status": "เสร็จสิ้นการดำเนินงาน",
            "image": "https://drive.google.com/thumbnail?id=1VM6WqJtEM3bUGMZzccZ-RJK6MCNAvPHj&sz=w1000",
            "favorite": "1",
            "founders": "รศ.นพ.ขวัญชัย ไพโรจน์สกุล และ อ.พญ.สุธาทิพย์ เอมเปรมศิลป์,ราชวิทยาลัยกุมารแพทย์แห่งประเทศไทย,สำนักโภชนาการ กรมอนามัย กระทรวงสาธารณสุข,ชมรมโรคไตเด็กแห่งประเทศไทย",
            "founders_type": "0,1,1,1",
            "language": "0",
            "founder": [
                "รศ.นพ.ขวัญชัย ไพโรจน์สกุล และ อ.พญ.สุธาทิพย์ เอมเปรมศิลป์",
                "ราชวิทยาลัยกุมารแพทย์แห่งประเทศไทย",
                "สำนักโภชนาการ กรมอนามัย กระทรวงสาธารณสุข",
                "ชมรมโรคไตเด็กแห่งประเทศไทย"
            ]
        },
        {
            "id": "66",
            "title": "การผลักดันนโยบายการป้องกันและแก้ไขปัญหาการตั้งครรภ์ในวัยรุ่น",
            "status": "เสร็จสิ้นการดำเนินงาน",
            "image": "https://drive.google.com/thumbnail?id=19524eWDEys1SBdMmfbTY7IGKSBzwjpVF&sz=w1000",
            "favorite": "1",
            "founders": "ศูนย์นโยบายและการจัดการสุขภาพ โดย นพ.วิวัฒน์ โรจนพิทยากร,คณาจารย์จากภาควิชากุมารเวชศาสตร์ และภาควิชาสูติศาสตร์-นรีเวชวิทยา คณะแพทยศาสตร์โรงพยาบาลรามาธิบดี,ภาคีเครือข่ายร่วม ได้แก่ เช่น สำนักงานกองทุนสนับสนุนการสร้างเสริมสุขภาพ กรมอนามัย กระทรวงสาธารณสุข กระทรวงการพัฒนาสังคมและความมั่นคงของมนุษย์ และกระทรวงศึกษาธิการ มูลนิธิสร้างความเข้าใจเรื่องสุขภาพผู้หญิง (สคส.) มูลนิธิแพธทูเฮลท์ และกองทุนประชากรแห่งสหประชาชาติ เป็นต้น",
            "founders_type": "1,0,1",
            "language": "0",
            "founder": [
                "ศูนย์นโยบายและการจัดการสุขภาพ โดย นพ.วิวัฒน์ โรจนพิทยากร",
                "คณาจารย์จากภาควิชากุมารเวชศาสตร์ และภาควิชาสูติศาสตร์-นรีเวชวิทยา คณะแพทยศาสตร์โรงพยาบาลรามาธิบดี",
                "ภาคีเครือข่ายร่วม ได้แก่ เช่น สำนักงานกองทุนสนับสนุนการสร้างเสริมสุขภาพ กรมอนามัย กระทรวงสาธารณสุข กระทรวงการพัฒนาสังคมและความมั่นคงของมนุษย์ และกระทรวงศึกษาธิการ มูลนิธิสร้างความเข้าใจเรื่องสุขภาพผู้หญิง (สคส.) มูลนิธิแพธทูเฮลท์ และกองทุนประชากรแห่งสหประชาชาติ เป็นต้น"
            ]
        },
        {
            "id": "65",
            "title": "โปรแกรมการเสริมสร้างความแข็งแกร่งในชีวิตของนักศึกษาพยาบาล",
            "status": "เสร็จสิ้นการดำเนินงาน",
            "image": "https://drive.google.com/thumbnail?id=1tXeTBpKBxr_tUohoLTsUGU3Rahd2vyDc&sz=w1000",
            "favorite": "0",
            "founders": "ศูนย์นโยบายและการจัดการสุขภาพ,รศ.ดร.พัชรินทร์ นินทจันทร์ และทีมงาน,สถาบันพระบรมราชชนก กระทรวงสาธารณสุข",
            "founders_type": "1,0,1",
            "language": "0",
            "founder": [
                "ศูนย์นโยบายและการจัดการสุขภาพ",
                "รศ.ดร.พัชรินทร์ นินทจันทร์ และทีมงาน",
                "สถาบันพระบรมราชชนก กระทรวงสาธารณสุข"
            ]
        },
        {
            "id": "64",
            "title": "นโยบายการตรวจสุขภาพที่จำเป็นและเหมาะสมสำหรับประชาชน",
            "status": "เสร็จสิ้นการดำเนินงาน",
            "image": "https://drive.google.com/thumbnail?id=13sS1bSl0JILxgAKarLilrH5_zNqzw3xm&sz=w800",
            "favorite": "0",
            "founders": "ศูนย์นโยบายและการจัดการสุขภาพ,มูลนิธิหมอชาวบ้าน,สำนักงานกองทุนสนับสนุนการสร้างเสริมสุขภาพ (สสส.)",
            "founders_type": "1,1,1",
            "language": "0",
            "founder": [
                "ศูนย์นโยบายและการจัดการสุขภาพ",
                "มูลนิธิหมอชาวบ้าน",
                "สำนักงานกองทุนสนับสนุนการสร้างเสริมสุขภาพ (สสส.)"
            ]
        },
        {
            "id": "63",
            "title": "การผลักดันนโยบายรวมพลังชุมชนต้านมะเร็ง",
            "status": "เสร็จสิ้นการดำเนินงาน",
            "image": "https://drive.google.com/thumbnail?id=1_8mfeCU7LsqYdFYCBpwBZjKgqcmWsSoY&sz=w1000",
            "favorite": "1",
            "founders": "ศูนย์นโยบายและการจัดการสุขภาพ โดย นพ.วิวัฒน์ โรจนพิทยากร,กระทรวงสาธารณสุข,สำนักงานคณะกรรมการสุขภาพแห่งชาติ,ภาคีเครือข่ายร่วม ได้แก่ สื่อทั้งภาครัฐและสื่อเอกชน เครือข่ายผู้ป่วย องค์กรศาสนา ภาคการศึกษา ภาคประชาสังคม และเครือข่ายผู้บริโภค เป็นต้น,กลุ่มเครือข่ายวิชาการ ได้แก่ คณะแพทยศาสตร์ศิริราชพยาบาล คณะแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย วิทยาลัยวิทยาศาสตร์การแพทย์เจ้าฟ้าจุฬาภรณ์ และคณะแพทยศาสตร์โรงพยาบาลรามาธิบดีโดย รศ.นพ.เอกภพ สิระชัยนันท์ อาจารย์ประจำภาควิชาอายุรศาสตร์ เป็นผู้แทน",
            "founders_type": "1,1,1,1,1",
            "language": "0",
            "founder": [
                "ศูนย์นโยบายและการจัดการสุขภาพ โดย นพ.วิวัฒน์ โรจนพิทยากร",
                "กระทรวงสาธารณสุข",
                "สำนักงานคณะกรรมการสุขภาพแห่งชาติ",
                "ภาคีเครือข่ายร่วม ได้แก่ สื่อทั้งภาครัฐและสื่อเอกชน เครือข่ายผู้ป่วย องค์กรศาสนา ภาคการศึกษา ภาคประชาสังคม และเครือข่ายผู้บริโภค เป็นต้น",
                "กลุ่มเครือข่ายวิชาการ ได้แก่ คณะแพทยศาสตร์ศิริราชพยาบาล คณะแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย วิทยาลัยวิทยาศาสตร์การแพทย์เจ้าฟ้าจุฬาภรณ์ และคณะแพทยศาสตร์โรงพยาบาลรามาธิบดีโดย รศ.นพ.เอกภพ สิระชัยนันท์ อาจารย์ประจำภาควิชาอายุรศาสตร์ เป็นผู้แทน"
            ]
        },
        {
            "id": "62",
            "title": "การผลักดันมาตรการภาษีโซเดียมในอาหาร",
            "status": "เสร็จสิ้นการดำเนินงาน",
            "image": "https://drive.google.com/thumbnail?id=1F14u_mQOP3tithKbpDxMTXqApKNe8cev&sz=w1000",
            "favorite": "1",
            "founders": "ศูนย์นโยบายและการจัดการสุขภาพ,กรมสรรพสามิต กระทรวงการคลัง,กรมควบคุมโรค กระทรวงสาธารณสุข,สำนักงานกองทุนสนับสนุนการสร้างเสริมสุขภาพ (สสส.),เครือข่ายลดบริโภคเค็ม (โดย รองศาสตราจารย์ นายแพทย์สุรศักดิ์ กันตชูเวสศิริ),สมาคมเพื่อนโรคไตแห่งประเทศไทย",
            "founders_type": "1,1,1,1,1,1",
            "language": "0",
            "founder": [
                "ศูนย์นโยบายและการจัดการสุขภาพ",
                "กรมสรรพสามิต กระทรวงการคลัง",
                "กรมควบคุมโรค กระทรวงสาธารณสุข",
                "สำนักงานกองทุนสนับสนุนการสร้างเสริมสุขภาพ (สสส.)",
                "เครือข่ายลดบริโภคเค็ม (โดย รองศาสตราจารย์ นายแพทย์สุรศักดิ์ กันตชูเวสศิริ)",
                "สมาคมเพื่อนโรคไตแห่งประเทศไทย"
            ]
        },
        {
            "id": "59",
            "title": "การสังเคราะห์นโยบายเพื่อขับเคลื่อนกลยุทธ์การปรับขยายมาตรการใช้เครื่องตรวจวัดโซเดียมคลอไรด์ในอาหารร่วมกับการให้ความรู้ในชุมชนของประเทศไทยนอกพื้นที่นำร่อง",
            "status": "เสร็จสิ้นการดำเนินงาน",
            "image": "https://drive.google.com/thumbnail?id=13g7sa7-fAA0okuEyzogumzpi_gIqJT-x&sz=w1000",
            "favorite": "1",
            "founders": "รศ.นพ. สุรศักดิ์ กันตชูเวสศิริ,รองศาสตราจารย์ ดร. นายแพทย์บวรศม ลีระพันธ์",
            "founders_type": "0,0",
            "language": "0",
            "founder": [
                "รศ.นพ. สุรศักดิ์ กันตชูเวสศิริ",
                "รองศาสตราจารย์ ดร. นายแพทย์บวรศม ลีระพันธ์"
            ]
        },
        {
            "id": "58",
            "title": "การเตรียมยาฉีดจากขวดยาชนิดหลายโดส กรณีศึกษาการเตรียมโควิดวัคซีนของไฟเซอร์ชนิด 6 โดส",
            "status": "เสร็จสิ้นการดำเนินงาน",
            "image": "https://media.istockphoto.com/id/540609236/photo/doctor-hand-in-white-glove-hold-syringe-with-preparation-jet.jpg?s=612x612&w=0&k=20&c=aipJmFAKrSwC3H6Xsz25j_iw_dibp8jcbk0YgVsDUSA=",
            "favorite": "1",
            "founders": "ศ. เกียรติคุณ นพ.พรชัย สิมะโรจน์,ภญ. สุธิตา ด่านอุดมชาญ,ภญ. ปถมาภรณ์ ตั้งธีระคุณ",
            "founders_type": "0,0,0",
            "language": "0",
            "founder": [
                "ศ. เกียรติคุณ นพ.พรชัย สิมะโรจน์",
                "ภญ. สุธิตา ด่านอุดมชาญ",
                "ภญ. ปถมาภรณ์ ตั้งธีระคุณ"
            ]
        },
        {
            "id": "57",
            "title": "การสังเคราะห์ข้อเสนอเพื่อสนับสนุนกระบวนการตัดสินใจเชิงนโยบายและการวางแผนการปรับตัวหลังวิกฤตของระบบสุขภาพของประเทศไทย โดยประยุกต์ใช้แบบจำลองสถานการณ์พลวัตระบบของการระบาดของโรคติดเชื้อไวรัสโคโรนา 2019: การจัดการห่วงโซ่อุปทานของวัคซีนป้องกันการติดเชื้อไวรัสโคโรนา 2019",
            "status": "เสร็จสิ้นการดำเนินงาน",
            "image": "https://drive.google.com/thumbnail?id=1kfhBBo8V_gfYu9CFjLArdg0KUTUAdE-S&sz=w1000",
            "favorite": "1",
            "founders": null,
            "founders_type": null,
            "language": "0"
        }
    ]
}
            */

            if (response.data.success) {
                let format_db = [...response.data.results];

                //console.log('xxxyyyzzz');
                //console.log(format_db);

                /*
                [
    {
        "id": "69",
        "title": "การพัฒนาการตรวจวินิจฉัยสาเหตุทางพันธุกรรมในผู้ป่วยกลุ่มออทิซึม พัฒนาการล่าช้า/สติปัญญาบกพร่อง และพิการซ้ำซ้อนแต่กำเนิด ด้วยวิธีโครโมโซมอะเรย์ ความละเอียดสูง (Chromosomal Microarray: CMA)",
        "status": "เสร็จสิ้นการดำเนินงาน",
        "image": "https://drive.google.com/thumbnail?id=1MnnPS9pGYcE5Q05-bgwVhE6tgC8Vh6G0&sz=w1000",
        "favorite": "1",
        "founders": "ดร.พญ.ณฐินี  จินาวัฒน์ หลักสูตรเวชศาสตร์ปริวรรต   สำนักงานวิจัย วิชาการและนวัตกรรม คณะแพทยศาสตร์โรงพยาบาลรามาธิบดี,หลักสูตรเวชศาสตร์ปริวรรต และหน่วยห้องปฏิบัติการชีวโมเลกุล สำนักงานวิจัย วิชาการและนวัตกรรม หน่วยเวชพันธุศาสตร์  ภาควิชากุมารเวชศาสตร์ หน่วยพัฒนาการเด็ก  ภาควิชากุมารเวชศาสตร์",
        "founders_type": "1,1",
        "language": "0",
        "founder": [
            "ดร.พญ.ณฐินี  จินาวัฒน์ หลักสูตรเวชศาสตร์ปริวรรต   สำนักงานวิจัย วิชาการและนวัตกรรม คณะแพทยศาสตร์โรงพยาบาลรามาธิบดี",
            "หลักสูตรเวชศาสตร์ปริวรรต และหน่วยห้องปฏิบัติการชีวโมเลกุล สำนักงานวิจัย วิชาการและนวัตกรรม หน่วยเวชพันธุศาสตร์  ภาควิชากุมารเวชศาสตร์ หน่วยพัฒนาการเด็ก  ภาควิชากุมารเวชศาสตร์"
        ]
    },
    {
        "id": "68",
        "title": "ผลักดันแนวทางการดูแลภาวะโภชนาการและสุขาภิบาลอาหารในระดับท้องถิ่น",
        "status": "เสร็จสิ้นการดำเนินงาน",
        "image": "https://drive.google.com/thumbnail?id=1rVpfv0PHdi7szMjIJxV4tovWWji1ZD7O&sz=w1000",
        "favorite": "0",
        "founders": "ศูนย์นโยบายและการจัดการสุขภาพ,ศ.ดร.นพวรรณ เปียซื่อ และทีมงาน,สถาบันโภชนาการ กรมอนามัย กระทรวงสาธารณสุข",
        "founders_type": "1,0,1",
        "language": "0",
        "founder": [
            "ศูนย์นโยบายและการจัดการสุขภาพ",
            "ศ.ดร.นพวรรณ เปียซื่อ และทีมงาน",
            "สถาบันโภชนาการ กรมอนามัย กระทรวงสาธารณสุข"
        ]
    },
    {
        "id": "67",
        "title": "การผลักดันแนวทางการคัดกรองภาวะความดันเลือดสูงในเด็กที่มีโรคอ้วนสำหรับสถานบริการสาธารณสุข และสำหรับสถานศึกษา",
        "status": "เสร็จสิ้นการดำเนินงาน",
        "image": "https://drive.google.com/thumbnail?id=1VM6WqJtEM3bUGMZzccZ-RJK6MCNAvPHj&sz=w1000",
        "favorite": "1",
        "founders": "รศ.นพ.ขวัญชัย ไพโรจน์สกุล และ อ.พญ.สุธาทิพย์ เอมเปรมศิลป์,ราชวิทยาลัยกุมารแพทย์แห่งประเทศไทย,สำนักโภชนาการ กรมอนามัย กระทรวงสาธารณสุข,ชมรมโรคไตเด็กแห่งประเทศไทย",
        "founders_type": "0,1,1,1",
        "language": "0",
        "founder": [
            "รศ.นพ.ขวัญชัย ไพโรจน์สกุล และ อ.พญ.สุธาทิพย์ เอมเปรมศิลป์",
            "ราชวิทยาลัยกุมารแพทย์แห่งประเทศไทย",
            "สำนักโภชนาการ กรมอนามัย กระทรวงสาธารณสุข",
            "ชมรมโรคไตเด็กแห่งประเทศไทย"
        ]
    },
    {
        "id": "66",
        "title": "การผลักดันนโยบายการป้องกันและแก้ไขปัญหาการตั้งครรภ์ในวัยรุ่น",
        "status": "เสร็จสิ้นการดำเนินงาน",
        "image": "https://drive.google.com/thumbnail?id=19524eWDEys1SBdMmfbTY7IGKSBzwjpVF&sz=w1000",
        "favorite": "1",
        "founders": "ศูนย์นโยบายและการจัดการสุขภาพ โดย นพ.วิวัฒน์ โรจนพิทยากร,คณาจารย์จากภาควิชากุมารเวชศาสตร์ และภาควิชาสูติศาสตร์-นรีเวชวิทยา คณะแพทยศาสตร์โรงพยาบาลรามาธิบดี,ภาคีเครือข่ายร่วม ได้แก่ เช่น สำนักงานกองทุนสนับสนุนการสร้างเสริมสุขภาพ กรมอนามัย กระทรวงสาธารณสุข กระทรวงการพัฒนาสังคมและความมั่นคงของมนุษย์ และกระทรวงศึกษาธิการ มูลนิธิสร้างความเข้าใจเรื่องสุขภาพผู้หญิง (สคส.) มูลนิธิแพธทูเฮลท์ และกองทุนประชากรแห่งสหประชาชาติ เป็นต้น",
        "founders_type": "1,0,1",
        "language": "0",
        "founder": [
            "ศูนย์นโยบายและการจัดการสุขภาพ โดย นพ.วิวัฒน์ โรจนพิทยากร",
            "คณาจารย์จากภาควิชากุมารเวชศาสตร์ และภาควิชาสูติศาสตร์-นรีเวชวิทยา คณะแพทยศาสตร์โรงพยาบาลรามาธิบดี",
            "ภาคีเครือข่ายร่วม ได้แก่ เช่น สำนักงานกองทุนสนับสนุนการสร้างเสริมสุขภาพ กรมอนามัย กระทรวงสาธารณสุข กระทรวงการพัฒนาสังคมและความมั่นคงของมนุษย์ และกระทรวงศึกษาธิการ มูลนิธิสร้างความเข้าใจเรื่องสุขภาพผู้หญิง (สคส.) มูลนิธิแพธทูเฮลท์ และกองทุนประชากรแห่งสหประชาชาติ เป็นต้น"
        ]
    },
    
    {
        "id": "57",
        "title": "การสังเคราะห์ข้อเสนอเพื่อสนับสนุนกระบวนการตัดสินใจเชิงนโยบายและการวางแผนการปรับตัวหลังวิกฤตของระบบสุขภาพของประเทศไทย โดยประยุกต์ใช้แบบจำลองสถานการณ์พลวัตระบบของการระบาดของโรคติดเชื้อไวรัสโคโรนา 2019: การจัดการห่วงโซ่อุปทานของวัคซีนป้องกันการติดเชื้อไวรัสโคโรนา 2019",
        "status": "เสร็จสิ้นการดำเนินงาน",
        "image": "https://drive.google.com/thumbnail?id=1kfhBBo8V_gfYu9CFjLArdg0KUTUAdE-S&sz=w1000",
        "favorite": "1",
        "founders": null,
        "founders_type": null,
        "language": "0"
    }
]
                */

                for(let i = 0; i < format_db.length; i++) {
                    let founder = ['-'];

                    if(format_db[i].founders) {
                        founder = format_db[i].founders.split(",");
                    }

                    format_db[i].founder = founder;

                    if(i === format_db.length - 1) {
                        setDB([...format_db]);

                        let favorite = [];
                        format_db.forEach((x, j) => {
                            if(x.favorite === '1') {
                                favorite.push(x);
                            }

                            if(j === format_db.length - 1) {
                                setFavoriteItems([...favorite]);
                            }
                        });
                    }
                }
            } 
            else {
                console.log('Error loading data: ' + response.data.message);
            }
        })
        .catch((error) => {
            console.log(error);
        });

        return () => abortcontroller.abort();
    }, []);

    useEffect(() => {
        handle_filter();
    }, [search_text, filter_sort, filter_status]);

    useEffect(() => {
        //Set page
        //setItems_per_page((screen_width >= 1024 && screen_width < 1536) ? 9 : 8);
        setItems_per_page(20);

        setActive_items([]);
        for(let i = 0; i < 20; i++) {
            setActive_items(oldArray => [...oldArray, false]);
        }

        const index_start = (page * items_per_page) - items_per_page; //Or (page * 20) - 20
        const index_end = (page * items_per_page > db.length) ? db.length : page * items_per_page;

        setItems(db?.slice(index_start, index_end));
        setMax_page(Math.ceil(db.length / items_per_page));

        //scroll to top of container
        document.getElementById('list_container').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });

         //add animation into page
         animation_controls.start("hidden");

         setTimeout(() => {
             animation_controls.start("visible");
         }, 500);
    }, [page, db]);

    useEffect(() => {
        if(ref_favorite_item.current) {
            setFavoriteItems_width(ref_favorite_item.current.scrollWidth);
        }

        if(ref_favorite_item_mobile.current) {
            setFavoriteItems_width_mobile(ref_favorite_item_mobile.current.scrollWidth);
        }
    }, [favoriteItems]);

    async function handle_filter() {
        const search_result = await search_items();
        const filter_status_result = await filter_by_status(search_result);
        await sort_items(filter_status_result);

        function search_items() {
            return new Promise((resolve, reject) => {
                //hide all items first
                animation_controls.start("hidden");

                //normal filter process
                let new_result = [];

                if(search_text === '') {
                    new_result = [...db];
                }
                else {
                    for(let i = 0; i < items.length; i++) {
                        const project_title = items[i].title;
    
                        if(project_title.toLowerCase().includes(search_text)) {
                            new_result.push(items[i]);
                            continue;
                        }
    
                        const project_founder = [...items[i].founder];
                        for(let j = 0; j < project_founder.length; j++) {
                            const founder_name = project_founder[j];
    
                            if(founder_name.toLowerCase().includes(search_text)) {
                                new_result.push(items[i]);
                                break;
                            }
                        }
                    }
                }

                resolve(new_result);
            });
        }

        function filter_by_status(search_result) {
            return new Promise((resolve, reject) => {
                let new_result = [];

                if(filter_status === 'default') {
                    new_result = [...search_result];
                }
                else {
                    for(let i = 0; i < search_result.length; i++) {
                        const project_status = search_result[i].status;

                        if(project_status.toLowerCase().includes(filter_status)) {
                            new_result.push(search_result[i]);
                            continue;
                        }
                    }
                }
                
                resolve(new_result);
            });
        }

        function sort_items(result) {
            return new Promise((resolve, reject) => {
                let new_result = [...result];

                if(filter_sort === 'title') {
                    new_result = new_result.sort((a, b) => a.title.localeCompare(b.title));
                }
                else if(filter_sort === 'founder') {
                    new_result = new_result.sort((a, b) => a.founder.join(",").localeCompare(b.founder.join(",")));
                }
                
                setItems(Array.isArray(new_result) ? [...new_result] : []);

                //set page back to page1
                setPage(1);
                const index_start = (page * items_per_page) - items_per_page; //Or (page * 7) - 7
                const index_end = (page * items_per_page > db.length) ? db.length : page * items_per_page;

                setItems(new_result?.slice(index_start, index_end));
                setMax_page(Math.ceil(new_result.length / items_per_page));

                //run animation of page change effect after finishing filter process
                setTimeout(() => {
                    animation_controls.start("visible");
                }, 500);
                resolve();
            });
        }
    }

    const animation_controls = useAnimationControls();
    const ani_obj = {
        hidden: {
            opacity: 0,
            y: 10,
            transition: { duration: 0.2 }
        },
        visible: (index) => ({
            opacity: 1,
            y: 0,
            transition: { duration: 0.8, delay: index * 0.1 }
        })
    }

    const [ is_SubmitConfirm, setIsSubmitConfirm ] = useState(false);
    const [ is_SubmitLoading, setIsSubmitLoading ] = useState(false);
    const [ is_SubmitSuccess, setIsSubmitSuccess ] = useState(null);
    const [ modal_submit_process, setModal_submit_process ] = useState("โปรดรอสักครู่");

    const [ is_SubmitConfirm_delete, setIsSubmitConfirm_delete ] = useState(false);
    const [ is_SubmitLoading_delete, setIsSubmitLoading_delete ] = useState(false);
    const [ is_SubmitSuccess_delete, setIsSubmitSuccess_delete ] = useState(null);
    const [ modal_submit_process_delete, setModal_submit_process_delete ] = useState("โปรดรอสักครู่");

    const [ is_SubmitConfirm_update, setIsSubmitConfirm_update ] = useState(false);
    const [ is_SubmitLoading_update, setIsSubmitLoading_update ] = useState(false);
    const [ is_SubmitSuccess_update, setIsSubmitSuccess_update ] = useState(null);
    const [ modal_submit_process_update, setModal_submit_process_update ] = useState("โปรดรอสักครู่");

    const [ is_SubmitConfirm_update_favorite, setIsSubmitConfirm_update_favorite ] = useState(false);
    const [ is_SubmitLoading_update_favorite, setIsSubmitLoading_update_favorite ] = useState(false);
    const [ is_SubmitSuccess_update_favorite, setIsSubmitSuccess_update_favorite ] = useState(null);
    const [ modal_submit_process_update_favorite, setModal_submit_process_update_favorite ] = useState("โปรดรอสักครู่");

    async function submit_data() {
        const latestId = await send_main_data();
        await send_timeline_detail(latestId);

        function send_main_data() {
            setIsSubmitLoading(true);
            let latestId = -1;
            let sending_founder = [];

            founder_for_add_db.map(x => {
                sending_founder.push({
                    name: x,
                    type: 0,
                });
            });

            founder_org_for_add_db.map(x => {
                sending_founder.push({
                    name: x,
                    type: 1,
                });
            });

            return new Promise((resolve, reject) => {
                axios.post(php_list.php_testimonials_write, {
                    title: title_for_add_db,
                    cover: cover_for_add_db,
                    status: status_for_add_db,
                    founder: sending_founder,
                    url: url_for_add_db,
                    description: description_for_add_db,
                    timeline: timeline_for_add_db,
                    available: 1,
                    language: language_for_add_db
                })
                .then(response => {
                    // Handle the success response
                    console.log(response.data);
        
                    if (response.data.success) {
                        latestId = response.data.latestId;
        
                        //setIsSubmitLoading(false);
                        //setIsSubmitSuccess('success');
                    } else {
                        setIsSubmitLoading(false);
                        setIsSubmitSuccess('failed');
                    }
                })
                .catch(errors => {
                    // Handle the error
                    console.error('Error:', errors);
        
                    setIsSubmitLoading(false);
                    setIsSubmitSuccess('failed');
                })
                .finally(() => {
                    resolve(latestId);
                });
            });
        }

        function send_timeline_detail(latestId) {
            return new Promise((resolve, reject) => {
                if(latestId === -1) {
                    resolve();
                    return;
                }

                for(let i = 0; i < timeline_for_add_db.length; i++) {
                    const detail = timeline_for_add_db[i].detail;

                    for(let j = 0; j < detail.length; j++) {
                        axios.post(php_list.php_testimonials_write_timeline_detail, {
                            latestId: latestId,
                            year: timeline_for_add_db[i].year,
                            title: timeline_for_add_db[i].title,
                            title_index: i,
                            paragraph: j,
                            detail: detail[j].detail,
                        })
                        .then(response => {
                            // Handle the success response
                            console.log(response.data);
                
                            if (response.data.success) {
                                //setIsSubmitLoading(false);
                                //setIsSubmitSuccess('success');
                            } else {
                                setIsSubmitLoading(false);
                                setIsSubmitSuccess('failed');
                            }
                        })
                        .catch(errors => {
                            // Handle the error
                            console.error('Error:', errors);
                
                            setIsSubmitLoading(false);
                            setIsSubmitSuccess('failed');
                        })
                        .finally(() => {
                            if(i === timeline_for_add_db.length - 1 && j === detail.length - 1) {
                                setIsSubmitLoading(false);
                                setIsSubmitSuccess('success');
                                resolve();
                            }
                        });
                    }
                }

                resolve();
            });
        }
    }

    async function update_data() {
        let update_main_finish = false;
        let update_timeline_finish = false;
        setIsSubmitLoading_update(true);

        let sending_founder = [];

        founder_for_add_db.map(x => {
            sending_founder.push({
                name: x,
                type: 0,
            });
        });

        founder_org_for_add_db.map(x => {
            sending_founder.push({
                name: x,
                type: 1,
            });
        });

        //console.log('timeline_for_add_db');
        //console.log(timeline_for_add_db);

        /*
        [
            {
                "year": 2568,
                "title": "xxx",
                "title_index": 0,
                "detail": [
                    {
                        "paragraph": 0,
                        "detail": "xxx"
                    },
                    {
                        "paragraph": 1,
                        "detail": "yyy"
                    }
                ]
            },
            {
                "year": 2568,
                "title": "yyy",
                "title_index": 1,
                "detail": [
                    {
                        "paragraph": 0,
                        "detail": "yyy"
                    },
                    {
                        "paragraph": 1,
                        "detail": "xxx"
                    },
                    {
                        "paragraph": 2,
                        "detail": "zzz"
                    }
                ]
            }
        ]
        */

        await axios.post(php_list.php_testimonials_update, JSON.stringify({
            id: selectedID,
            title: title_for_add_db,
            cover: cover_for_add_db,
            status: status_for_add_db,
            founder: sending_founder,
            url: url_for_add_db,
            description: description_for_add_db,
            //timeline: timeline_for_add_db,
            available: 1,
            language: language_for_add_db,
        }))
        .then(response => {
            // Handle the success response
            console.log(response.data);

            if (response.data.success) {
                update_main_finish = true;

                //setIsSubmitLoading_update(false);
                //setIsSubmitSuccess_update('success');
            } else {
                setIsSubmitLoading_update(false);
                setIsSubmitSuccess_update('failed');
            }
        })
        .catch(errors => {
            // Handle the error
            console.error('Error:', errors);

            setIsSubmitLoading_update(false);
            setIsSubmitSuccess_update('failed');
        })
        .finally(() => {
            if(update_main_finish && update_timeline_finish) {
                setIsSubmitLoading_update(false);
                setIsSubmitSuccess_update('success');
            }
        });

        //delete previous timeline events first
        const deleteTimelineResponse = await axios.post(php_list.php_testimonials_delete_timeline, JSON.stringify({
            id: selectedID,
        }));

        if (!deleteTimelineResponse.data.success) {
            setIsSubmitLoading_update(false);
            setIsSubmitSuccess_update('failed');
            return;  // Exit the function if the deletion fails
        }

        for(let i = 0; i < timeline_for_add_db.length; i++) {
            const detail = timeline_for_add_db[i].detail;

            for(let j = 0; j < detail.length; j++) {
                axios.post(php_list.php_testimonials_write_timeline_detail, {
                    latestId: selectedID,
                    year: timeline_for_add_db[i].year,
                    title: timeline_for_add_db[i].title,
                    title_index: i,
                    paragraph: j,
                    detail: detail[j].detail,
                })
                .then(response => {
                    // Handle the success response
                    console.log(response.data);
        
                    if (response.data.success) {
                        //setIsSubmitLoading(false);
                        //setIsSubmitSuccess('success');
                    } else {
                        setIsSubmitLoading(false);
                        setIsSubmitSuccess('failed');
                    }
                })
                .catch(errors => {
                    // Handle the error
                    console.error('Error:', errors);
        
                    setIsSubmitLoading(false);
                    setIsSubmitSuccess('failed');
                })
                .finally(() => {
                    if(i === timeline_for_add_db.length - 1 && j === detail.length - 1) {
                        setIsSubmitLoading(false);
                        setIsSubmitSuccess('success');
                    }
                });
            }
        }

        /*
        for(let i = 0; i < timeline_for_add_db.length; i++) {
            const detail = timeline_for_add_db[i].detail;

            for(let j = 0; j < detail.length; j++) {
                const writeTimelineResponse  = await axios.post(php_list.php_testimonials_write_timeline_detail, JSON.stringify({
                    latestId: selectedID,
                    year: timeline_for_add_db[i].year,
                    paragraph: j,
                    detail: detail[j],
                }));

                if(!writeTimelineResponse.data.success) {
                    setIsSubmitLoading(false);
                    setIsSubmitSuccess('failed');
                }
            }
        }
        */

        // If everything succeeded
        setIsSubmitLoading_update(false);
        setIsSubmitSuccess_update('success');
    }

    async function update_data_favorite() {
        setIsSubmitLoading_update_favorite(true);

        console.log(favoriteItems);

        await axios.post(php_list.php_testimonials_update_favorite, JSON.stringify({
            favoriteItems: favoriteItems_for_update_db,
        }))
        .then(response => {
            // Handle the success response
            console.log(response.data);

            if (response.data.success) {
                setIsSubmitLoading_update_favorite(false);
                setIsSubmitSuccess_update_favorite('success');
            } else {
                setIsSubmitLoading_update_favorite(false);
                setIsSubmitSuccess_update_favorite('failed');
            }
        })
        .catch(errors => {
            // Handle the error
            console.error('Error:', errors);

            setIsSubmitLoading_update_favorite(false);
            setIsSubmitSuccess_update_favorite('failed');
        });
    }

    async function delete_data() {
        await send_request();

        function send_request() {
            setIsSubmitLoading_delete(true);

            return new Promise((resolve, reject) => {
                axios.post(php_list.php_testimonials_delete, JSON.stringify({
                    id: selectedID,
                }))
                .then(response => {
                    // Handle the success response
                    console.log(response.data);
        
                    if (response.data.success) {
                        setIsSubmitLoading_delete(false);
                        setIsSubmitSuccess_delete('success');
                    } else {
                        setIsSubmitLoading_delete(false);
                        setIsSubmitSuccess_delete('failed');
                    }
                })
                .catch(errors => {
                    // Handle the error
                    console.error('Error:', errors);
    
                    setIsSubmitLoading_delete(false);
                    setIsSubmitSuccess_delete('failed');
                })
                .finally(() => {
                    setSelectedID(-1);
                    resolve();
                });
            });
        }
    }

    return (
        <div className='relative w-screen h-screen z-0'>
            { /* Content of Home */ }
            <div className='w-screen h-screen overflow-y-scroll'>
                <Header/>

                <div className='relative w-full h-60'>
                    <div className='absolute bottom-0 left-0 w-full h-4/6 bg-gradient-to-t from-black opacity-30 z-10'></div>
                    <img src={cover} className='relative w-full h-full object-cover'/>
                </div>

                {/* Display on large screen */}
                <div className='hidden lg:block mx-10 md:mx-20 xl:mx-32 pt-14 sm:pt-28'>
                    <div className='w-full h-fit flex flex-col justify-center my-6'>
                        <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med text-center'>ผลงานของเรา</h3>
                        <img src={title_decorate} className='w-28 h-auto self-center my-4'/>
                    </div>

                    <TestimonialIntroduction db={db}/>

                    <div className='w-full h-fit flex flex-col mt-14 mb-8'>
                        <div className='w-full sm:w-fit h-fit flex gap-3 items-center justify-center sm:justify-start'>
                            <h3 className='text-web-gold text-2xl notosan-med'>ผลงานทั้งหมดของเรา</h3>
                            <div className='hidden sm:block w-20 h-0.5 bg-web-gold'></div>
                        </div>
                    </div>

                    <Swiper
                        className='relative w-full h-fit'
                        spaceBetween={0}
                        slidesPerView={1}
                        breakpoints={{
                            // when window width is >= 460px
                            460: {
                               spaceBetween: 20,
                               slidesPerView: 2,
                           },
                           // when window width is >= 640px
                           640: {
                               spaceBetween: 10,
                               slidesPerView: 3,
                           },
                           // when window width is >= 768px
                           1024: {
                               spaceBetween: 20,
                               slidesPerView: 4,
                           },
                           // when window width is >= 1024px
                           1280: {
                               spaceBetween: 20,
                               slidesPerView: 5,
                           },
                       }}
                        pagination={{ clickable: true, dynamicBullets: true, }}
                        modules={[Pagination]}>
                            <div className='absolute top-0 left-0 h-full bg-white z-10' style={{width: favoriteItems_width + "px"}}>
                                <div className='w-full h-full z-20'>
                                    <Admin_add_button label={'เพิ่มรายการ'} handle_click={() => setModal_add_favorite(true)}/>
                                </div>
                            </div>

                            {
                                (favoriteItems.length > 0) ?
                                    <SwiperSlide ref={ref_favorite_item} className='invisible'>
                                        <HomeTestimonial_Card info={favoriteItems[0]} custom={0}/>
                                    </SwiperSlide>
                                :
                                    null
                            }

                            {
                                favoriteItems.map((x, i) => {
                                    return (
                                        <SwiperSlide key={i} onClick={() => {
                                            setModal_detail(favoriteItems[i]);
                                            setModal_is_show(true);
                                        }}>
                                            <HomeTestimonial_Card info={x} custom={0}/>
                                        </SwiperSlide>
                                    )
                                })
                            }
                    </Swiper>
                </div>

                {/* Display on mobile screen */}
                <div className='block lg:hidden mx-10 md:mx-20 xl:mx-32 pt-14 sm:pt-28'>
                    <div className='w-full h-fit flex flex-col justify-center my-6'>
                        <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med text-center'>ผลงานของเรา</h3>
                        <img src={title_decorate} className='w-28 h-auto self-center my-4'/>
                    </div>

                    <TestimonialIntroduction db={db}/>

                    <div className='w-full h-fit flex flex-col mt-14 mb-8'>
                        <div className='w-full sm:w-fit h-fit flex gap-3 items-center justify-center sm:justify-start'>
                            <h3 className='text-web-gold text-2xl notosan-med'>ผลงานทั้งหมดของเรา</h3>
                            <div className='hidden sm:block w-20 h-0.5 bg-web-gold'></div>
                        </div>
                    </div>

                    <Swiper
                        className='relative w-full h-56'
                        spaceBetween={10}
                        slidesPerView={2}
                        breakpoints={{
                           // when window width is >= 640px
                           640: {
                               spaceBetween: 10,
                               slidesPerView: 3,
                           },
                           // when window width is >= 768px
                           1024: {
                               spaceBetween: 20,
                               slidesPerView: 4,
                           },
                           // when window width is >= 1024px
                           1280: {
                               spaceBetween: 20,
                               slidesPerView: 5,
                           },
                       }}
                        pagination={{ clickable: true, dynamicBullets: true, }}
                        modules={[Pagination]}>
                            <div className='absolute top-0 left-0 h-full bg-white z-10' style={{width: favoriteItems_width_mobile + "px"}}>
                                <div className='w-full h-full z-20'>
                                    <Admin_add_button label={'เพิ่มรายการ'} handle_click={() => setModal_add_favorite(true)}/>
                                </div>
                            </div>

                            {
                                (favoriteItems.length > 0) ?
                                    <SwiperSlide ref={ref_favorite_item_mobile} className='invisible'>
                                        <Testimonial_Card_Favorite info={favoriteItems[0]}/>
                                    </SwiperSlide>
                                :
                                    null
                            }

                            {
                                favoriteItems.map((x, i) => {
                                    return (
                                        <SwiperSlide key={i} onClick={() => {
                                            setModal_detail(favoriteItems[i]);
                                            setModal_is_show(true);
                                        }}>
                                            <Testimonial_Card_Favorite info={x} custom={0}/>
                                        </SwiperSlide>
                                    )
                                })
                            }
                    </Swiper>
                </div>

                <div className='mx-10 md:mx-20 xl:mx-32 pb-14 sm:pb-28'>
                    <div className='w-full h-fit grid grid-cols-1 sm:grid-cols-2 gap-2 lg:flex lg:justify-between lg:items-end my-14 mb-10'>
                        <div className='w-full lg:w-96 h-fit'>
                            <SearchInput placeholder={'ค้นหาคำสำคัญ'} handle_search={(value) => { 
                                setSearch_text(value) 
                            }}/>
                        </div>
                        
                        <div className='w-fit h-fit grid grid-cols-2 lg:flex lg:justify-end gap-2'>
                            <select ref={ref_filter_opt} className='notosan-reg px-4 py-1.5 rounded-full border border-gray-300 cursor-pointer'
                            onChange={(e) => setFilter_status(e.target.value)}>
                                <option value={'default'}>คัดกรองจากสถานะการดำเนินงาน...</option>
                                
                                {
                                    status_list.map((x, i) => {
                                        return (
                                            <option key={i} value={x}>{x}</option>
                                        )
                                    })
                                }
                            </select>

                            <select className='notosan-reg px-4 py-1.5 rounded-full border border-gray-300 cursor-pointer'
                            onChange={(e) => setFilster_sort(e.target.value)}>
                                <option value={'default'}>จัดเรียงรายการ...</option>
                                <option value={'title'}>จัดเรียงตามหัวข้อ</option>
                                <option value={'founder'}>จัดเรียงตามเจ้าของโครงการ</option>
                            </select>
                        </div>
                    </div>

                    { /* Display on large screen */ }
                    <div id='list_container' className='hidden md:grid w-full h-fit grid-cols-1 md:grid-cols-3 md:grid-cols-4 xl:grid-cols-7 gap-x-5 gap-y-10 my-10'>
                        <Admin_add_button label={"เพิ่มรายการ"} handle_click={() => {
                            setSelectedID(-1);
                            setModal_add_is_show(true);
                        }}/>

                        {
                            items.map((x, i) => {
                                return (
                                    <Admin_testimonial_item 
                                        key={i}
                                        image={x.image} 
                                        title={x.title} 
                                        founder={x.founder} 
                                        motion_obj={ani_obj}
                                        motion_control={animation_controls}
                                        motion_index={Math.ceil(i/7)}
                                        active={active_items[i]}
                                        handle_clicked={() => {
                                            let new_result = [...active_items];
                                            for(let j = 0; j < new_result.length; j++) {
                                                new_result[j] = (j === i);
                                            }

                                            setActive_items(new_result);
                                        }}
                                        handle_clicked_look={() => {
                                            setModal_detail(items[i]);
                                            setModal_is_show(true);
                                        }}
                                        handle_clicked_edit={() => {
                                            setSelectedID(items[i].id);
                                            setModal_add_is_show(true);
                                        }}
                                        handle_clicked_delete={() => {
                                            setSelectedID(items[i].id);
                                            setIsSubmitConfirm_delete(true);
                                        }}/>
                                )
                            })
                        }
                    </div>

                    { /* Display on mobile screen */ }
                    <div className='block md:hidden my-8 sm:my-14'>
                        <div className='w-full h-56'>
                            <Admin_add_button label={"เพิ่มรายการ"} handle_click={() => {
                                setSelectedID(-1);
                                setModal_add_is_show(true);
                            }}/>
                        </div>

                        {
                            items.map((x, i) => {
                                return (
                                    <div key={i} className='my-6'
                                    onClick={() => {
                                        setModal_detail(items[i]);
                                        setModal_is_show(true);
                                    }}>
                                        <img src={x.image} className='w-full h-56 object-cover rounded-md'/>
                                        <p className='notosan-med text-web-black mt-4'>{x.title}</p>
                                        <p className='notosan-light text-web-black-secondary text-xs'>{x.founder}</p>
                                    </div>
                                );
                            })
                        }
                    </div>

                    <div className='w-full h-fit flex justify-end items-center gap-2'>
                        <Web_pagination current_page={page} max_page={max_page} 
                        handle_change_page={(x) => setPage(x)}/>
                    </div>
                </div>

                <Footer form={false}/>
            </div>

            { /* Any modal */ }
            <ModalTestimonial_detail isShow={modal_is_show} detail={modal_detail} handle_close={() => setModal_is_show(false)}/>

            <ModalTestimonial_detail_admin 
            isShow={modal_add_is_show} 
            selectedID={selectedID}
            handle_close={() => {
                setCover_for_add_db('');
                setTitle_for_add_db('');
                setFounder_for_add_db([]);
                setFounder_org_for_add_db([]);
                setURL_for_add_db([]);
                setStatus_for_add_db('');
                setDescription_for_add_db([]);
                setTimeline_for_add_db([]);
                setLanguage_for_add_db(-1);

                setModal_add_is_show(false);
            }}
            handle_submit={(cover, title, founder, founder_org, url, status, description, timeline, language) => {
                setCover_for_add_db(cover);
                setTitle_for_add_db(title);
                setFounder_for_add_db(founder);
                setFounder_org_for_add_db(founder_org);
                setURL_for_add_db(url);
                setStatus_for_add_db(status);
                setDescription_for_add_db(description);
                setTimeline_for_add_db(timeline);
                setLanguage_for_add_db(language);

                setIsSubmitConfirm(true);
            }}
            handle_update={(cover, title, founder, founder_org, url, status, description, timeline, language) => {
                setCover_for_add_db(cover);
                setTitle_for_add_db(title);
                setFounder_for_add_db(founder);
                setFounder_org_for_add_db(founder_org);
                setURL_for_add_db(url);
                setStatus_for_add_db(status);
                setDescription_for_add_db(description);
                setTimeline_for_add_db(timeline);
                setLanguage_for_add_db(language);

                setIsSubmitConfirm_update(true);
            }}/>

            <ModalConfirmForm_admin isShow={is_SubmitConfirm} isLoading={is_SubmitLoading} isSuccess={is_SubmitSuccess} process={modal_submit_process} 
            onConfirm={submit_data} onCancel={() => {
                setIsSubmitConfirm(false);
                setIsSubmitLoading(false);
                setIsSubmitSuccess(null);
                setModal_submit_process('โปรดรอสักครู่');
            }}
            label={"ยืนยันการเพิ่มข้อมูลใช่หรือไม่?"}
            label_success={{
                title: 'เพิ่มข้อมูลสำเร็จ',
                subtitle: null,
            }}
            label_failed={{
                title: 'ล้มเหลว พบปัญหาในการส่งข้อมูล',
                subtitle: null,
            }}/>

            <ModalConfirmForm_admin isShow={is_SubmitConfirm_update} isLoading={is_SubmitLoading_update} isSuccess={is_SubmitSuccess_update} process={modal_submit_process_update} 
            onCancel={() => {
                setIsSubmitConfirm_update(false);
                setIsSubmitLoading_update(false);
                setIsSubmitSuccess_update(null);
                setModal_submit_process_update('โปรดรอสักครู่');
            }}
            onConfirm={() => {
                if(selectedID === -1) {
                    alert("พบข้อผิดพลาด ไม่สามารถส่งข้อมูลได้");
                }
                else {
                    update_data();
                }
            }}
            label={"ยืนยันการอัพเดทข้อมูลใช่หรือไม่?"}
            label_success={{
                title: 'อัพเดทข้อมูลสำเร็จ',
                subtitle: null,
            }}
            label_failed={{
                title: 'ล้มเหลว พบปัญหาในการส่งข้อมูล',
                subtitle: null,
            }}/>

            <ModalConfirmForm_admin isShow={is_SubmitConfirm_update_favorite} 
            isLoading={is_SubmitLoading_update_favorite} 
            isSuccess={is_SubmitSuccess_update_favorite} 
            process={modal_submit_process_update_favorite} 
            onCancel={() => {
                setIsSubmitConfirm_update_favorite(false);
                setIsSubmitLoading_update_favorite(false);
                setIsSubmitSuccess_update_favorite(null);
                setModal_submit_process_update_favorite('โปรดรอสักครู่');
            }}
            onConfirm={() => {
                update_data_favorite();
            }}
            label={"ยืนยันการอัพเดทข้อมูลใช่หรือไม่?"}
            label_success={{
                title: 'อัพเดทข้อมูลสำเร็จ',
                subtitle: null,
            }}
            label_failed={{
                title: 'ล้มเหลว พบปัญหาในการส่งข้อมูล',
                subtitle: null,
            }}/>

            <ModalConfirmForm_admin isShow={is_SubmitConfirm_delete} isLoading={is_SubmitLoading_delete} 
            isSuccess={is_SubmitSuccess_delete} process={modal_submit_process_delete} 
            onCancel={() => {
                setIsSubmitConfirm_delete(false);
                setIsSubmitLoading_delete(false);
                setIsSubmitSuccess_delete(null);
                setModal_submit_process_delete('โปรดรอสักครู่');
            }}
            onConfirm={() => {
                if(selectedID === -1) {
                    alert("พบข้อผิดพลาด ไม่สามารถส่งข้อมูลได้");
                }
                else {
                    delete_data();
                }
            }}
            label={"ยืนยันการดำเนินการใช่หรือไม่?"}
            label_success={{
                title: 'ลบข้อมูลสำเร็จ',
                subtitle: null,
            }}
            label_failed={{
                title: 'ล้มเหลว พบปัญหาในการลบข้อมูล',
                subtitle: null,
            }}/>

            <ModalTestimonial_admin_add_favorite isShow={modal_add_favorite} 
            favor_items={favoriteItems} items={db}
            handle_close={() => setModal_add_favorite(false)}
            handle_submit={(items) => {
                setFavoriteItems_for_update_db([...items]);
                setIsSubmitConfirm_update_favorite(true);
            }}/>
        </div>
    )
}

export default Admin_testimonial