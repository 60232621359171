import React, { useEffect, useRef, useState } from 'react';
import { UilDraggabledots } from '@iconscout/react-unicons';

const Item = ({text, type, show, active, handle_delete}) => {
    const [ hover, setHover ] = useState(false);
    const [ edit_text, setEdit_text ] = useState('');

    useEffect(() => {
        if(type === 'space') {
            setEdit_text('บรรทัดว่าง');
        }
        else {
            if(text) {
                const exclude_url = text.replace(/<URL>.*?<URL>/g, '');
        
                let result = '';
                result = exclude_url.replace(/<LINK>/g, '');
                result = result.replace(/<HL>/g, '');
                result = result.replace(/<CENTER>/g, '');

                setEdit_text(result);
            }
        }
    }, [text, type]);

    return (
        <div className={'w-full border-gray-300 px-4 rounded-md cursor-pointer overflow-hidden flex justify-between items-center gap-x-2 ' + (show ? 'h-fit py-2 border ' : 'h-0 ') + ((hover || active) ? 'bg-zinc-700' : 'bg-white')}
        onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <div className='w-full h-fit flex justify-start'>
                <div className='w-fit h-fit cursor-grab'>
                    <UilDraggabledots size="20" color="#A9A9A9"/>
                </div>

                <span className={'notosan-reg line-clamp-1 ' + ((hover || active) ? 'text-white' : 'text-web-black')}>{(edit_text.length === 0) ? 'ยังไม่มีข้อความ' : edit_text}</span>
            </div>

            <div className='w-fit h-full flex justify-center items-center' onClick={handle_delete}>
                <box-icon name='x' color={(hover || active) ? '#ffffff' : '#3f3f46'} ></box-icon>
            </div>
        </div>
    )
}

const Empty_Item = ({show}) => {
    return (
        <div className={'w-full border-gray-300 px-6 rounded-md cursor-grab overflow-hidden bg-gray-300 ' + (show ? 'h-fit py-2 border' : 'h-0')}>
            <span className='invisible'>Empty</span>
        </div>
    )
}

const Admin_KM_reorderable_list = ({list, paragraph_index, handle_list_select, handle_reorder, handle_delete}) => {
    const [ item_selected_index, setItem_selected_index ] = useState(-1);
    const [ mouse_position, setMouse_position ] = useState({ x: 0, y: 0 });
    const [ item_width, setItem_width ] = useState(0);
    const [ drop_index, setDrop_index ] = useState(-1);
    const [ drop_at_first, setDrop_at_first ] = useState(false);

    const [ isDragging, setIsDragging ] = useState(false);
    const [ dragStartPosition, setDragStartPosition ] = useState(null);

    const refs = useRef([]);
    const ref_empty_first = useRef(null);
    
    useEffect(() => {
        if(item_width === 0) {
            const widths = refs.current
            .filter((e) => e)
            .map((e) => e.scrollWidth);
            
            if (widths.length > 0) {
                setItem_width(Math.max(...widths)); // Use the spread operator for `Math.max`
            }
        }
    }, [list]);

    useEffect(() => {
        const handler_mouse_move = (e) => {
            setMouse_position({ x: e.x, y: e.y });
        };
        document.addEventListener("mousemove", handler_mouse_move);
        
        return () => document.removeEventListener("mousemove", handler_mouse_move);
    }, []);

    useEffect(() => {
        const handler = (e) => {
            e.preventDefault();

            if (item_selected_index !== -1 && drop_index !== -1 && item_selected_index !== drop_index) {
                //Create a copy of the items array
                //Deep copy array
                const deepCopy = (arr) => arr.map((item) => ({ ...item }));
                let new_result = deepCopy(list);
                //let new_result = [...list];
                const moving_item = list[item_selected_index];
                new_result.splice(item_selected_index, 1);

                //Adjust drop_index if necessary
                let adjusted_drop_index =
                    drop_index < item_selected_index ? drop_index + 1 : drop_index;

                //paragraph_index is the state that decide which item is selected by click
                //once the list is reorder, the paragraph_index should also be reordered
                //so, get text from array. after reordering, use saved text to find index of saved text from reordered list
                
                //there are 2 cases that will make paragraph_index to be changed
                //first when draged item is selected, in other word, when item_selected_index === paragraph_index
                //second when active index is between dragged and drop index and selected item is moved down, selected_item < drop index
                const saved_selected_item = list[paragraph_index];

                //Handle when users try to move item to the first index of list
                //state drop_index has lowest value as 0 and -1
                //Once 0 refers to second <Empty_Item> and -1 refers to defause value
                //Therefore, although drop_index is 0, we have to determine further if value should be place at first or second <Empty_Item>
                //We can use state drop_at_first to determine if first or second <Empty_Item> should handle this
                adjusted_drop_index = drop_at_first ? 0 : adjusted_drop_index;

                new_result.splice(adjusted_drop_index, 0, moving_item);

                handle_list_select(new_result.indexOf(saved_selected_item));
                handle_reorder(new_result);
            }

            //Clear state
            setItem_selected_index(-1);
            setDrop_index(-1);
            setDrop_at_first(false);
        };
    
        document.addEventListener("mouseup", handler);
        return () => document.removeEventListener("mouseup", handler);
    });

    useEffect(() => {
        if(item_selected_index !== -1 && refs.current.length > 0) {
            const positions = refs.current.map((e) => e.getBoundingClientRect().top);
            const absDifferences = positions.map((v) => Math.abs(v - mouse_position.y));

            //Empty_Item will not be hidden, just h-0 to compare position to mouse
            //get the item closest to the mouse
            let closest_index = absDifferences.indexOf(Math.min(...absDifferences));
            setDrop_index(closest_index);

            //When users users want to move any items to place in first index of the list
            //Then, <Empty_Item> will not appear because the lowest value of drop_index is 0, and default value is -1
            //Because of drop_index is created follow <Item>, and using show={i === drop_index} to determine if it should appear
            //Then, problem will happen because the lowest value of i is 0 but first <Empty_Item> is not created from index 0
            if(ref_empty_first.current) {
                //To handle this, we assign drop_at_first to the first <Empty_Item> and compare mouse position with the second <Empty_Item>
                const position_of_second_empty = refs.current[0].getBoundingClientRect().top;
                const position_of_first_empty = ref_empty_first.current.getBoundingClientRect().top;
                setDrop_at_first(Math.abs(mouse_position.y - position_of_first_empty) < Math.abs(mouse_position.y - position_of_second_empty));
            }
            else {
                //Or if ref_empty_first is not available, the first <Empty_Item> will be determined from if mouse position y is lower than the top of <Empty_Item> index 0
                //If drop_index is 0 but mouse position y is less than <Empty_Item> index 0, then the first <Empty_Item> will appear
                const position_of_second_empty = refs.current[0].getBoundingClientRect().top;
                setDrop_at_first(mouse_position.y < position_of_second_empty);
            }
        }
    }, [mouse_position]);

    return (
        <div className='w-auto h-fit'>
            {/* ----------FLOATING ITEM---------- */}
            <div className={'absolute h-fit bg-white rounded-md ' + ((item_selected_index !== -1 && isDragging) ? 'block' : 'hidden')} 
            style={{top: mouse_position.y + 'px', left: mouse_position.x + 'px', width: item_width + 'px'}}>
                <div className={'w-full border-gray-300 px-4 rounded-md cursor-grab overflow-hidden flex items-center gap-x-2 h-fit py-2 border'}>
                    <UilDraggabledots size="20" color="#555555"/>
                    <span className='notosan-reg text-web-black'>{(item_selected_index === -1) ? '' : (list[item_selected_index].detail ? list[item_selected_index].detail : 'ยังไม่มีข้อความ')}</span>
                </div>
            </div>

            {/* ----------MAIN ITEM---------- i !== item_selected_index */}
            <div ref={ref_empty_first} className='w-full h-fit'>
                <Empty_Item show={drop_at_first}/>
            </div>

            {
                list.map((x, i) => {
                    return (
                        <div 
                        key={i} 
                        className='w-full h-fit my-0.5 rounded-md'
                        onMouseDown={(e) => {
                            e.preventDefault();

                            setItem_selected_index(i);
                            setDrop_index(i);

                            // Record the initial mouse position
                            setDragStartPosition({ x: e.clientX, y: e.clientY });
                            setIsDragging(false); // Reset dragging status
                        }}
                        onMouseMove={(e) => {
                            e.preventDefault();

                            if (dragStartPosition) {
                                const distanceMoved =
                                Math.sqrt(
                                    Math.pow(e.clientX - dragStartPosition.x, 2) +
                                    Math.pow(e.clientY - dragStartPosition.y, 2)
                                );
                    
                                if (distanceMoved > 5) {
                                    setIsDragging(true); // Threshold to differentiate drag from click
                                }
                            }
                        }}
                        onMouseUp={(e) => {
                            e.preventDefault();

                            if (!isDragging) {
                                handle_list_select((i === paragraph_index) ? -1 : i);
                            }
                    
                            //Clean up
                            //states related to drag drop function are already reset onMouseup in onEffect
                            setIsDragging(false);
                            setDragStartPosition(null);
                        }}>
                            <Item 
                            text={x.detail}
                            type={x.type}
                            show={true} 
                            active={i === paragraph_index}
                            handle_delete={() => {
                                handle_delete(i);
                            }}/>

                            <div className='h-px'></div>

                            <div ref={(element) => refs.current[i] = element} className='w-full h-fit'>
                                <Empty_Item show={i === drop_index && !drop_at_first}/>
                            </div>
                        </div>
                    )
                })
            }

            { /* add modal to confirm to delete */ }
        </div>
    )
}

export default Admin_KM_reorderable_list