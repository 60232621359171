import React, { useEffect, useState } from 'react';

const Testimonial_Card_Favorite = ({info}) => {
    const [ founders, setFounders ] = useState([]);

    useEffect(() => {
        if(Object.keys(info).length > 0) {
            if(info.founder) {
                info.founder.map(x => setFounders([...founders, x.name]));
            }
        }
    }, [info]);

    return (
        <div className='relative w-full h-full rounded-sm overflow-hidden cursor-pointer'>
            <div className='absolute bottom-0 left-0 mx-6 my-6 z-20'>
                <div>
                    <p className='text-xs sm:text-base notosan-med text-white line-clamp-5'>{info.title}</p>
                </div>
                
                <div className='mt-2'>
                    <p className='text-xs sm:text-base notosan-light text-white text-xs line-clamp-2'>{founders.join(', ')}</p>
                </div>
            </div>

            <div className='absolute top-0 left-0 w-full h-full bg-black/70 z-10'></div>
            <img src={info.image} className='w-full h-full min-h-80 object-cover rounded-sm'/>
        </div>
    )
}

export default Testimonial_Card_Favorite