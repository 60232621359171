import React, { useEffect, useRef, useState } from 'react';
import './testimonial.css';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import cover from '../../assets/cover/cover_testimonial.png';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import HomeTestimonial_Card from '../Home/HomeTestimonial/HomeTestimonial_Card';
import { status_list } from './status_list';
import axios from 'axios';
import { php_list } from '../../php/php_list';

import SearchInput from '../Components/Input/SearchInput';
import { default as Web_pagination } from '../Components/Pagination/Pagination';
import { motion, useAnimationControls } from "framer-motion";
import TestimonialIntroduction from './TestimonialIntroduction';
import ModalTestimonial_detail from '../Components/Modal/ModalTestimonial/ModalTestimonial_detail';
import title_decorate from '../../assets/decorate/title_decorate.svg';
import Testimonial_Card_Favorite from './Testimonial_Card_Favorite';
import Cover from '../Components/Header/Cover';

const Testimonial = () => {
    const [ page, setPage ] = useState(1);
    const [ items_per_page, setItems_per_page ] = useState(21);
    const [ max_page, setMax_page ] = useState(1);

    const [ modal_is_show, setModal_is_show ] = useState(false);
    const [ modal_detail, setModal_detail ] = useState({});
    const [ db, setDB ] = useState([]);
    const [ favoriteItems, setFavoriteItems ] = useState([]);
    const [ items, setItems ] = useState([]);

    const [ search_text, setSearch_text ] = useState('');
    const [ filter_sort, setFilster_sort ] = useState('default');
    const [ filter_status, setFilter_status ] = useState('default');

    const ref_sort = useRef(null);
    const ref_filter_opt = useRef(null);

    const breadcrumbs = [
        {
            label: 'หน้าแรก',
            url: '/',
        },
        {
            label: 'ผลงานของเรา',
            url: '',
        },
    ];

    useEffect(() => {
        let abortcontroller = new AbortController();

        axios.get(php_list.php_testimonials_read_all, { signal: abortcontroller.signal })
        .then((response) => {
            // Handle the success response
            console.log(response.data);

            if (response.data.success) {
                //console.log('response.data.results');
                //console.log(response.data.results);
                setDB([...response.data.results]);

                /*
                let format_db = [...response.data.results];

                for(let i = 0; i < format_db.length; i++) {
                    let founders_info = [];

                    if(format_db[i].founders && format_db[i].founders_type) {
                        let founder = format_db[i].founders.split(",");
                        let founder_type = format_db[i].founders_type.split(",");

                        if(founder.length === founder_type.length) {
                            if(founder.length > 0 && founder_type.length > 0) {
                                for(let j = 0; j < founder.length; j++) {
                                    const obj = {
                                        name: founder[j],
                                        type: founder_type[j],
                                    };
    
                                    founders_info.push(obj);
                                }
                            }
                            else {
                                founders_info.push({
                                    name: '-',
                                    type: 0,
                                });
                            }
                        }
                    }

                    format_db[i].founder = founders_info;

                    if(i === format_db.length - 1) {
                        setDB([...format_db]);

                        let favorite = [];
                        format_db.forEach((x, j) => {
                            if(x.favorite === '1') {
                                favorite.push(x);
                            }

                            if(j === format_db.length - 1) {
                                setFavoriteItems([...favorite]);
                            }
                        })
                    }
                }
                */
            } 
            else {
                console.log('Error loading data: ' + response.data.message);
            }
        })
        .catch((error) => {
            console.log(error);
        });

        return () => abortcontroller.abort();
    }, []);

    useEffect(() => {
        handle_filter();
    }, [search_text, filter_sort, filter_status]);

    useEffect(() => {
        //Set page
        //setItems_per_page((screen_width >= 1024 && screen_width < 1536) ? 9 : 8);
        setItems_per_page(21);

        const index_start = (page * items_per_page) - items_per_page; //Or (page * 7) - 7
        const index_end = (page * items_per_page > db.length) ? db.length : page * items_per_page;

        let format_db = db?.slice(index_start, index_end);

        for(let i = 0; i < format_db.length; i++) {
            let founders_info = [];

            if(format_db[i].founders && format_db[i].founders_type) {
                let founder = format_db[i].founders.split(",");
                let founder_type = format_db[i].founders_type.split(",");

                if(founder.length === founder_type.length) {
                    if(founder.length > 0 && founder_type.length > 0) {
                        for(let j = 0; j < founder.length; j++) {
                            const obj = {
                                name: founder[j],
                                type: founder_type[j],
                            };

                            founders_info.push(obj);
                        }
                    }
                    else {
                        founders_info.push({
                            name: '-',
                            type: 0,
                        });
                    }
                }
            }

            format_db[i].founder = founders_info;

            if(i === format_db.length - 1) {
                let favorite = [];
                format_db.forEach((x, j) => {
                    if(x.favorite === '1') {
                        favorite.push(x);
                    }

                    if(j === format_db.length - 1) {
                        setFavoriteItems([...favorite]);
                    }
                })
            }
        }

        setItems([...format_db]);
        setMax_page(Math.ceil(db.length / items_per_page));

        //scroll to top of container
        document.getElementById('list_container').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });

         //add animation into page
         animation_controls.start("hidden");

         setTimeout(() => {
             animation_controls.start("visible");
         }, 500);
    }, [page, db]);

    const Testimonial_items = ({image, title, founder, year}) => {
        const [ hover, setHover ] = useState(false);
        const [ founders, setFounders ] = useState([]);

        useEffect(() => {
            let result = [];

            if(founder) {
                founder.map(x => result.push(x.name));
            }

            setFounders([...result]);
        }, [founder]);

        return (
            <div className='w-full h-fit cursor-pointer' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <div className='w-full h-fit flex justify-center items-center'>
                    <img src={image} className={'w-40 h-40 object-cover rounded-full transition duration-300 ' + (hover ? '-translate-y-3' : 'translate-y-0')}></img>
                </div>
                
                <p className={'notosan-semi mt-4 line-clamp-2 ' + (hover ? 'underline text-web-gold' : 'text-web-black')}>{year + title}</p>
                <p className={'notosan-reg text-web-black text-sm line-clamp-2 ' + (hover ? 'text-web-gold' : 'text-web-black')}>โดย {founders.join(', ')}</p>
            </div>
        )
    }

    async function handle_filter() {
        const search_result = await search_items();
        const filter_status_result = await filter_by_status(search_result);
        await sort_items(filter_status_result);

        function search_items() {
            return new Promise((resolve, reject) => {
                //hide all items first
                animation_controls.start("hidden");

                //normal filter process
                let new_result = [];

                if(search_text === '') {
                    new_result = [...db];
                }
                else {
                    for(let i = 0; i < items.length; i++) {
                        const project_title = items[i].title;
    
                        if(project_title.toLowerCase().includes(search_text)) {
                            new_result.push(items[i]);
                            continue;
                        }
    
                        const project_founder = [...items[i].founder];
                        for(let j = 0; j < project_founder.length; j++) {
                            const founder_name = project_founder[j];
    
                            if(founder_name.toLowerCase().includes(search_text)) {
                                new_result.push(items[i]);
                                break;
                            }
                        }
                    }
                }

                resolve(new_result);
            });
        }

        function filter_by_status(search_result) {
            return new Promise((resolve, reject) => {
                let new_result = [];

                if(filter_status === 'default') {
                    new_result = [...search_result];
                }
                else {
                    for(let i = 0; i < search_result.length; i++) {
                        const project_status = search_result[i].status;

                        if(project_status.toLowerCase().includes(filter_status)) {
                            new_result.push(search_result[i]);
                            continue;
                        }
                    }
                }
                
                resolve(new_result);
            });
        }

        function sort_items(result) {
            return new Promise((resolve, reject) => {
                let new_result = [...result];

                if(filter_sort === 'title_ascending') {
                    new_result = new_result.sort((a, b) => a.title.localeCompare(b.title));
                }
                else if(filter_sort === 'title_descending') {
                    new_result = new_result.sort((a, b) => b.title.localeCompare(a.title));
                }
                else if(filter_sort === 'year_ascending') {
                    new_result = new_result.sort((a, b) => parseInt(a.year) - parseInt(b.year));
                }
                else if(filter_sort === 'year_descending') {
                    new_result = new_result.sort((a, b) => parseInt(b.year) - parseInt(a.year));
                }
                
                setItems(Array.isArray(new_result) ? [...new_result] : []);

                //set page back to page1
                setPage(1);
                const index_start = (page * items_per_page) - items_per_page; //Or (page * 7) - 7
                const index_end = (page * items_per_page > db.length) ? db.length : page * items_per_page;

                setItems(new_result?.slice(index_start, index_end));
                setMax_page(Math.ceil(db.length / items_per_page));

                //run animation of page change effect after finishing filter process
                setTimeout(() => {
                    animation_controls.start("visible");
                }, 500);
                resolve();
            });
        }
    }

    const animation_controls = useAnimationControls();
    const ani_obj = {
        hidden: {
            opacity: 0,
            y: 10,
            transition: { duration: 0.2 }
        },
        visible: (index) => ({
            opacity: 1,
            y: 0,
            transition: { duration: 0.8, delay: index * 0.1 }
        })
    }

    return (
        <div className='relative w-screen h-screen z-0'>
            { /* Content of Home */ }
            <div className='w-screen h-screen overflow-y-scroll'>
                <Header/>
                <Cover breadcrumbs={breadcrumbs} cover={cover}/>

                {/* Display on large screen */}
                <div className='hidden lg:block mx-10 md:mx-20 xl:mx-32 pt-14 sm:pt-28'>
                    <div className='w-full h-fit flex flex-col justify-center my-6'>
                        <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med text-center'>ผลงานของเรา</h3>
                        <img src={title_decorate} className='w-28 h-auto self-center my-4'/>
                    </div>

                    <TestimonialIntroduction db={db}/>

                    <div className='w-full h-fit flex flex-col mt-14 mb-8'>
                        <div className='w-full sm:w-fit h-fit flex gap-3 items-center justify-center sm:justify-start'>
                            <h3 className='text-web-gold text-2xl notosan-med'>ผลงานทั้งหมดของเรา</h3>
                            <div className='hidden sm:block w-20 h-0.5 bg-web-gold'></div>
                        </div>
                    </div>

                    <Swiper
                        spaceBetween={0}
                        slidesPerView={1}
                        breakpoints={{
                             // when window width is >= 460px
                             460: {
                                spaceBetween: 10,
                                slidesPerView: 2,
                            },
                            // when window width is >= 640px
                            640: {
                                spaceBetween: 20,
                                slidesPerView: 3,
                            },
                            // when window width is >= 768px
                            768: {
                                spaceBetween: 20,
                                slidesPerView: 4,
                            },
                            // when window width is >= 1024px
                            1024: {
                                spaceBetween: 20,
                                slidesPerView: 5,
                            },
                        }}
                        pagination={{ clickable: true, dynamicBullets: true, }}
                        modules={[Pagination, Autoplay]}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                        }}
                        loop={true}>
                        {
                            favoriteItems.map((x, i) => {
                                return (
                                    <SwiperSlide key={i} onClick={() => {
                                        setModal_detail(favoriteItems[i]);
                                        setModal_is_show(true);
                                    }}>
                                        <HomeTestimonial_Card info={x} custom={0}/>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>

                {/* Display on mobile screen */}
                <div className='block lg:hidden mx-10 md:mx-20 xl:mx-32 pt-14 sm:pt-28'>
                    <TestimonialIntroduction db={db}/>

                    <div className='w-full h-fit my-10'></div>

                    <Swiper
                        spaceBetween={0}
                        slidesPerView={1}
                        breakpoints={{
                             // when window width is >= 460px
                             460: {
                                spaceBetween: 10,
                                slidesPerView: 2,
                            },
                            // when window width is >= 640px
                            640: {
                                spaceBetween: 20,
                                slidesPerView: 3,
                            },
                            // when window width is >= 768px
                            768: {
                                spaceBetween: 20,
                                slidesPerView: 4,
                            },
                            // when window width is >= 1024px
                            1024: {
                                spaceBetween: 20,
                                slidesPerView: 5,
                            },
                        }}
                        pagination={{ clickable: true, dynamicBullets: true, }}
                        modules={[Pagination, Autoplay]}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                        }}
                        loop={true}>
                        {
                            favoriteItems.map((x, i) => {
                                return (
                                    <SwiperSlide key={i} onClick={() => {
                                        setModal_detail(favoriteItems[i]);
                                        setModal_is_show(true);
                                    }}>
                                        <Testimonial_Card_Favorite info={x}/>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>

                <div className='mx-10 md:mx-20 xl:mx-32 pb-14 sm:pb-28'>
                    <div className='w-full h-fit grid grid-cols-1 sm:grid-cols-2 gap-2 lg:flex lg:justify-between lg:items-end my-14 mb-10'>
                        <div className='w-full lg:w-96 h-fit'>
                            <SearchInput placeholder={'ค้นหาคำสำคัญ'} handle_search={(value) => { 
                                setSearch_text(value) 
                            }}/>
                        </div>
                        
                        <div className='w-fit h-fit grid grid-cols-2 lg:flex lg:justify-end gap-2'>
                            <select ref={ref_filter_opt} className='notosan-reg px-4 py-1.5 rounded-full border border-gray-300 cursor-pointer'
                            onChange={(e) => {
                                setFilter_status(e.target.value);
                            }}>
                                <option value={'default'}>คัดกรองจากสถานะการดำเนินงาน...</option>

                                {
                                    status_list.map((x, i) => {
                                        return (
                                            <option key={i} value={x}>{x}</option>
                                        )
                                    })
                                }
                            </select>

                            <select ref={ref_sort} className='notosan-reg px-4 py-1.5 rounded-full border border-gray-300 cursor-pointer'
                            onChange={(e) => setFilster_sort(e.target.value)}>
                                <option value={'default'}>จัดเรียงรายการ...</option>
                                <option value={'title_ascending'}>จัดเรียงตามหัวข้อ &#40;ก - ฮ&#41;</option>
                                <option value={'title_descending'}>จัดเรียงตามหัวข้อ &#40;ฮ - ก&#41;</option>
                                <option value={'year_ascending'}>จัดเรียงตามปี &#40;น้อย - มาก&#41;</option>
                                <option value={'year_descending'}>จัดเรียงตามปี &#40;มาก - น้อย&#41;</option>
                            </select>
                        </div>
                    </div>

                    { /* Display on large screen */ }
                    <div id='list_container' className='hidden sm:grid w-full h-fit grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-7 gap-x-5 gap-y-10 my-10'>
                        {
                            items.map((x, i) => {
                                let title_year = '';

                                if(ref_sort.current) {
                                    if(ref_sort.current.value === 'year_ascending' || ref_sort.current.value === 'year_descending') {
                                        title_year = '(พ.ศ. ' + x.year + ') ';
                                    }
                                }

                                return (
                                    <motion.div key={i} className='w-full h-fit' 
                                    variants={ani_obj}
                                    initial={'hidden'}
                                    animate={animation_controls}
                                    custom={Math.ceil(i/7)}
                                    onClick={() => { 
                                        setModal_detail(items[i]);
                                        setModal_is_show(true);
                                    }}>
                                        <Testimonial_items image={x.image} title={x.title} founder={x.founder} year={title_year}/>
                                    </motion.div>
                                )
                            })
                        }
                    </div>

                    { /* Display on mobile screen */ }
                    <div className='block sm:hidden my-8 sm:my-14'>
                        {
                            items.map((x, i) => {
                                let mobile_founders = [];

                                if(x.founder) {
                                    x.founder.map(x => mobile_founders.push(x.name));
                                }

                                let title_year = '';

                                if(ref_sort.current) {
                                    if(ref_sort.current.value === 'year_ascending' || ref_sort.current.value === 'year_descending') {
                                        title_year = '(พ.ศ. ' + x.year + ') ';
                                    }
                                }

                                return (
                                    <div key={i} className='my-6'
                                    onClick={() => {
                                        setModal_detail(items[i]);
                                        setModal_is_show(true);
                                    }}>
                                        <img src={x.image} className='w-full h-56 object-cover rounded-md'/>
                                        <p className='notosan-med text-web-black mt-4 line-clamp-3'>{title_year + x.title}</p>
                                        <p className='notosan-light text-web-black-secondary text-xs line-clamp-2'>{mobile_founders.join(', ')}</p>
                                    </div>
                                );
                            })
                        }
                    </div>

                    <div className='w-full h-fit flex justify-end items-center gap-2'>
                        <Web_pagination current_page={page} max_page={max_page} 
                        handle_change_page={(x) => setPage(x)}/>
                    </div>
                </div>

                <Footer form={false}/>
            </div>

            { /* Any modal */ }
            <ModalTestimonial_detail isShow={modal_is_show} detail={modal_detail} handle_close={() => setModal_is_show(false)}/>
        </div>
    )
}

export default Testimonial