import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Timeline from '../../Timeline/Timeline';
import axios from 'axios';
import { php_list } from '../../../../php/php_list';
import Rama_front from '../../../../assets/testimonial/rama_front.jpg';

const ModalTestimonial_detail = ({isShow, detail, handle_close}) => {
    const [ cover, setCover ] = useState('');
    const [ title, setTitle ] = useState('');
    const [ founder, setFounder ] = useState([]);
    const [ founder_org, setFounder_org ] = useState([]);
    const [ url, setURL ] = useState([]);
    const [ status, setStatus ] = useState('');
    const [ description, setDescription ] = useState([]);
    const [ timeline, setTimeline ] = useState([]);
    const [ loading, setLoading ] = useState(false);

    const [ img_height, setImg_height ] = useState(0);
    const [ img_width, setImg_width ] = useState(10);

    const [ content_opacity, setContent_opacity ] = useState(0);
    const [ content_marginL, setContent_marginL ] = useState(10);

    const ref_img = useRef(null);

    useEffect(() => {
        if(detail) {
            setCover(detail.image);
            setTitle(detail.title);
            setURL(Array.isArray(detail.url) ? [...detail.url] : []);
            setStatus(detail.status);
            setDescription(Array.isArray(detail.description) ? [...detail.description] : []);
            setTimeline(Array.isArray(detail.timeline) ? [...detail.timeline] : []);

            if(detail.founders && detail.founders_type) {
                //console.log('detail.founders');
                //console.log(detail.founders);
                //console.log('detail.founders_type');
                //console.log(detail.founders_type);

                let ori_founder = detail.founders.split(",");
                let ori_founder_type = detail.founders_type.split(",");
                let result_founder = [];
                let result_founder_org = [];

                if(ori_founder.length === ori_founder_type.length) {
                    for(let i = 0; i < ori_founder.length; i++) {
                        const founder_name = ori_founder[i];
                        const founder_type = parseInt(ori_founder_type[i]);

                        if(founder_type === 0) { //individual
                            result_founder.push(founder_name);
                        }
                        else if(founder_type === 1) { //org
                            result_founder_org.push(founder_name);
                        }
                    }
                }

                setFounder(result_founder);
                setFounder_org(result_founder_org);
            }
        }

        document.getElementById('btn_close').scrollIntoView();
    }, [detail]);

    useEffect(() => {
        //scroll to top before close modal
        if(!isShow) {
            document.getElementById("btn_close").scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }

        //set animation
        if(ref_img.current) {
            setImg_height(isShow ? ref_img.current.scrollHeight : 0);
            setImg_width(isShow ? ref_img.current.scrollWidth : 10);
        }

        //load data
        let abortcontroller = new AbortController();

        if(isShow) {
            setLoading(true);

            const fetch_data = async () => {
                await axios.get(php_list.php_testimonials_read_id_url, {
                    signal: abortcontroller.signal,
                    params: {
                        id: detail.id
                    }
                })
                .then((response) => {
                    // Handle the success response
                    console.log(response.data);
        
                    if (response.data.success) {
                        setURL([...response.data.result]);
                    } 
                    else {
                        console.log('Error loading data: ' + response.data.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    console.log('Error loading data: ' + error);
                });

                await axios.get(php_list.php_testimonials_read_id_des, {
                    signal: abortcontroller.signal,
                    params: {
                        id: detail.id
                    }
                })
                .then((response) => {
                    // Handle the success response
                    console.log(response.data);
        
                    if (response.data.success) {
                        let result = [...response.data.result];
                        result.sort((a, b) => a.paragraph - b.paragraph);
                        
                        let result_description = [];
                        for(let i = 0; i < result.length; i++) {
                            result_description.push(result[i].description);
                        }

                        setDescription(result_description);
                    } 
                    else {
                        console.log('Error loading data: ' + response.data.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    console.log('Error loading data: ' + error);
                });

                await axios.get(php_list.php_testimonials_read_id_timeline, {
                    signal: abortcontroller.signal,
                    params: {
                        id: detail.id
                    }
                })
                .then((response) => {
                    // Handle the success response
                    console.log(response.data);
        
                    if (response.data.success) {
                        /*
                        const sort_original = [...response.data.result].sort((a, b) => {
                            // Sort by year (ascending)
                            const yearDiff = Number(a.year) - Number(b.year);
                            if (yearDiff !== 0) return yearDiff;
                        
                            // If years are the same, sort by title_index (ascending)
                            return Number(a.title_index) - Number(b.title_index);
                        });

                        let result = [];

                        //get unique title
                        sort_original.map(x => {
                            const target_year = parseInt(x.year);
                            const target_title = x.title;
                            const index_year = result.findIndex(x => parseInt(x.year) === target_year);
                            const index_title = result.findIndex(x => x.title === target_title);
                            
                            if(index_year === -1 && index_title === -1) {
                                result.push({
                                    year: target_year,
                                    title: target_title,
                                    title_index: parseInt(x.title_index),
                                })
                            }
                        });

                        //collect result based on unique title
                        for(let i = 0; i < result.length; i++) {
                            const target_title_index = parseInt(result[i]['title_index']);
                            let collection = [];
                            
                            for(let j = 0; j < sort_original.length; j++) {
                                if(parseInt(sort_original[j]['title_index']) === target_title_index) {
                                    collection.push({
                                        paragraph: parseInt(sort_original[j]['paragraph']),
                                        detail: sort_original[j]['detail'],
                                    })
                                }
                            }

                            result[i]['detail'] = collection.sort((a, b) => a.paragraph - b.paragraph);
                        }

                        setTimeline(result);
                        */

                        setTimeline([...response.data.result]);
                    } 
                    else {
                        console.log('Error loading data: ' + response.data.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    console.log('Error loading data: ' + error);
                });
            }

            fetch_data();
            setContent_opacity(1);
            setContent_marginL(0);
        }
        else {
            console.log('close');

            setLoading(false);
            setContent_opacity(0);
            setContent_marginL(10);
        }

        return () => abortcontroller.abort();
    }, [isShow]);

    useEffect(() => {
        //console.log('timeline');
        //console.log(timeline);

        /*
        [
            {
                "id": "81",
                "year": "2556",
                "title": "ผลักดันอาจารย์ที่เกี่ยวข้องเข้ามีส่วนร่วมกับภาคีเครือข่ายในการประมวลองค์ความรู้",
                "title_index": "0",
                "paragraph": "0",
                "type": "text",
                "detail": "ศูนย์ฯ ผลักดันอาจารย์ที่เกี่ยวข้องเข้ามีส่วนร่วมกับภาคีเครือข่ายในการประมวลองค์ความรู้ จัดทำเป็นเอกสารวิชาการและข้อเสนอเชิงนโยบาย นำเข้าที่ประชุมพิจารณาของคณะกรรมการสุขภาพแห่งชาติ เมื่อวันที่ 8 พฤศจิกายน 2556 โดยมีมติเห็นชอบให้บรรจุเรื่องนี้นำเข้าที่ประชุมสมัชชาสุขภาพแห่งชาติ ครั้งที่ 6 ต่อไป"
            },
            {
                "id": "81",
                "year": "2559",
                "title": "จัดทำแนวทางการตรวจสุขภาพที่จำเป็นและเหมาะสมสำหรับประชาชน",
                "title_index": "3",
                "paragraph": "0",
                "type": "text",
                "detail": "ศ. (เกียรติคุณ) พญ.สยมพร ศิรินาวิน ร่วมกับสถาบันวิจัยและประเมินเทคโนโลยีทางการแพทย์ กรมการแพทย์ กระทรวงสาธารณสุข จัดทำแนวทางการตรวจสุขภาพที่จำเป็นและเหมาะสมสำหรับประชาชนขึ้นมาเป็นฉบับแรก โดยจะมีการปรับปรุงข้อมูลให้มีความทันสมัยเป็นระยะ มุ่งหวังให้สถานบริการสุขภาพทั้งภาครัฐและเอกชน ใช้เป็นเครื่องมือส่งเสริมคุณภาพของการบริการด้านสุขภาพที่เหมาะสมให้กับประชาชน ตลอดจนและกองทุนสุขภาพต่าง ๆ จะนำแนวทางนี้ไปปรับใช้ในการกำหนดสิทธิประโยชน์ ส่งเสริมให้ประชาชนได้มีโอกาสเข้าถึงการตรวจสุขภาพที่จำเป็นและเหมาะสมอย่างเท่าเทียมกัน"
            },
            {
                "id": "81",
                "year": "2557",
                "title": "ได้รับฉันทมติจากที่ประชุมสมัชชาสุขภาพแห่งชาติ ครั้งที่ 6",
                "title_index": "1",
                "paragraph": "0",
                "type": "text",
                "detail": "ได้รับฉันทมติจากที่ประชุมสมัชชาสุขภาพแห่งชาติ ครั้งที่ 6 เมื่อวันที่ 18 มิถุนายน 2557 โดยมีการแต่งตั้ง รศ.นพ. สุรเกียรติ อาชานานุภาพ เป็นประธานคณะทำงานเฉพาะประเด็น ให้กับสำนักงานคณะกรรมการสุขภาพแห่งชาติ ขณะที่กรมการแพทย์ กระทรวงสาธารณสุข ได้มีการแต่งตั้งคณะกรรมการพัฒนาการตรวจสุขภาพที่จำเป็นและเหมาะสมสำหรับประชาชน ซึ่งมีอธิบดีเป็นประธาน มีการแต่งตั้ง นพ.วิวัฒน์ โรจนพิทยากร เป็นประธานคณะทำงานติดตามการขับเคลื่อนนโยบายการตรวจสุขภาพฯ เมื่อวันที่ 6 พฤศจิกายน 2557"
            },
            {
                "id": "81",
                "year": "2557",
                "title": "ได้รับฉันทมติจากที่ประชุมสมัชชาสุขภาพแห่งชาติ ครั้งที่ 6",
                "title_index": "1",
                "paragraph": "1",
                "type": "text",
                "detail": "โครงการประเมินเทคโนโลยีและนโยบายด้านสุขภาพ ร่วมประมวลองค์ความรู้จากหลากหลายงานวิจัย จัดทำเป็นหนังสือ ชื่อ “เช็คระยะสุขภาพ ตรวจดีได้ ตรวจร้ายเสีย” เพื่อให้ความรู้ ความเข้าใจ ในการเลือกรับบริการตรวจคัดกรองสุขภาพได้อย่างเหมาะสมแก่ประชาชนกลุ่มวัยรุ่น ผู้ใหญ่และผู้สูงอายุ ซึ่งจะช่วยหลีกเลี่ยงผลเสียต่อสุขภาพและการสิ้นเปลืองค่าใช้จ่ายที่ไม่จำเป็น"
            },
            {
                "id": "81",
                "year": "2559",
                "title": "จัดทำแนวทางการตรวจสุขภาพที่จำเป็นและเหมาะสมสำหรับประชาชน",
                "title_index": "3",
                "paragraph": "1",
                "type": "text",
                "detail": "นอกจากนี้ อ.พญ.สุธาทิพย์ เอมเปรมศิลป์ ยังได้ร่วมกับสำนักงานกองทุนสนับสนุนการสร้างเสริมสุขภาพ จัดทำชุดความรู้ด้านการตรวจสุขภาพที่จำเป็นและเหมาะสมสำหรับประชาชน เพื่อช่วยสร้างความเข้าใจในการสร้างเสริมสุขภาพของตนเองด้วยการตรวจร่างกายอย่างสม่ำเสมอ นำไปสู่การเข้าใจและปรับเปลี่ยนพฤติกรรมก่อนเกิดโรคโดยเฉพาะกลุ่มโรคไม่ติดต่อเรื้อรัง"
            },
            {
                "id": "81",
                "year": "2558",
                "title": "ได้รับมติเห็นชอบจากคณะกรรมการสุขภาพแห่งชาติ",
                "title_index": "2",
                "paragraph": "0",
                "type": "text",
                "detail": "ได้รับมติเห็นชอบจากคณะกรรมการสุขภาพแห่งชาติรับรอง เมื่อวันที่ 20 กุมภาพันธ์ 2558 นำเข้าเสนอคณะรัฐมนตรี มีมติรับทราบมติสมัชชาสุขภาพแห่งชาติ ครั้งที่ 6 เมื่อวันที่ 7 พฤษภาคม 2558 พร้อมมอบหมายให้หน่วยงานที่เกี่ยวข้องพิจารณาดำเนินการตามอำนาจหน้าที่ และลำดับความสำคัญเร่งด่วน โดยยึดหลัก คือ (1) นโยบายของรัฐบาล (2) กฎหมาย ระเบียบหลักเกณฑ์ และมติครม. ที่เกี่ยวข้อง (3) งบประมาณและทรัพยากรที่มีอยู่ของหน่วยงาน โดยขอชื่นชมในนโยบายระดับชาติที่สนับสนุนการตรวจสุขภาพทั้งในทุกระบบประกันสุขภาพ และกฎกระทรวงแรงงาน ตลอดจนภาคีองค์กรต่าง ๆ ที่ร่วมกันผลักดันองค์ความรู้นี้ ซึ่งเห็นว่าจะเป็นประโยชน์ในการสร้างเสริมสุขภาพและการป้องกันโรคให้กับประชาชน"
            },
            {
                "id": "81",
                "year": "2565",
                "title": "ปรับปรุงข้อมูลให้เท่าทันเทคโนโลยีและองค์ความรู้ทางการแพทย์แบบใหม่",
                "title_index": "4",
                "paragraph": "0",
                "type": "text",
                "detail": "สถาบันวิจัยและประเมินเทคโนโลยีทางการแพทย์ กรมการแพทย์ กระทรวงสาธารณสุข มีการปรับปรุงข้อมูลแนวทางการตรวจสุขภาพที่จำเป็นและเหมาะสมสำหรับประชาชน ให้เท่าทันเทคโนโลยีและองค์ความรู้ทางการแพทย์แบบใหม่เป็นฉบับล่าสุด"
            }
        ]
        */
    }, [timeline]);

    function green_status(status) {
        return (
            <p className='notosan-med text-green-700'>{status}</p>
        )
    }

    function red_status(status) {
        return (
            <p className='notosan-med text-red-700'>{status}</p>
        )
    }

    function yellow_status(status) {
        return (
            <p className='notosan-med text-yellow-700'>{status}</p>
        )
    }

    return (
        <>
            { /* Modal confirm submit */ }
            <div className={'absolute top-0 left-0 w-full h-full z-20 ' + (isShow ? 'flex justify-center items-center' : 'hidden')}>
                { /* testimonial modal in classlist is used to remove scrollbar as code imported from css file */ }
                <div className='testimonial modal w-full sm:w-11/12 h-full overflow-y-scroll'>
                    <div className='relative w-full h-fit bg-white drop-shadow-md rounded-md px-8 sm:px-20 py-20 sm:pt-32 pb-28 my-10 sm:my-20'>
                        <div className='absolute top-5 right-5 cursor-pointer z-50' id='btn_close' onClick={handle_close}>
                            <box-icon name='x' color='#555555'></box-icon>
                        </div>

                        <div className='w-full h-fit block lg:flex gap-10'>
                            <div className={'hidden lg:block w-60 sm:w-full h-96 min-w-60 object-cover rounded-md overflow-hidden transition-all duration-500 z-50'}
                            style={{height: img_height + 'px', width: img_width + 'px'}}>
                                <img ref={ref_img} src={cover} className='w-60 h-96 object-cover rounded-md'/>
                            </div>

                            <div className={'block lg:hidden w-full h-60 object-cover rounded-md overflow-hidden transition-all duration-500 z-50'}>
                                <img src={cover} className='w-full h-60 object-cover rounded-md'/>
                            </div>

                            <div className='grow mt-12 lg:mt-0 z-50'>
                                <div className='relative w-full h-40 hidden'>
                                    <img src={Rama_front} className='w-full h-40 object-cover rounded-l-md'/>

                                    <div className='absolute top-0 left-0 w-full h-40 bg-web-gold mix-blend-multiply rounded-l-md opacify-70'></div>
                                    <div className='absolute top-0 left-0 w-full h-40 bg-web-gold mix-blend-multiply rounded-l-md'></div>

                                    <div className='absolute bottom-0 left-0 px-8 py-4'>
                                        <p className='notosan-bold text-2xl text-white'>{title}</p>
                                    </div>
                                </div>

                                <div className='w-full h-fit transition-all duration-1000' 
                                style={{opacity: content_opacity, marginLeft: content_marginL}}>
                                    <p className='notosan-bold text-xl text-web-black'>โครงการ/กิจกรรม</p>
                                    <p className='notosan-reg text-web-black'>{title}</p>
                                </div>

                                <div className={'w-full h-fit mt-6 transition-all duration-1000 delay-100 ' + ((founder.length > 0) ? 'block' : 'hidden')}
                                style={{opacity: content_opacity, marginLeft: content_marginL}}>
                                    <p className='notosan-bold text-xl text-web-black'>ผู้รับผิดชอบโครงการ/กิจกรรม</p>

                                    {
                                        founder.map((x, i) => {
                                            return (
                                                <div key={i} className='flex gap-1 items-start'>
                                                    <p className='notosan-reg text-web-black'>{i + 1}.</p>
                                                    <p className='notosan-reg text-web-black grow'>{x}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                <div className={'w-full h-fit mt-6 transition-all duration-1000 delay-100 ' + ((founder_org.length > 0) ? 'block' : 'hidden')}
                                style={{opacity: content_opacity, marginLeft: content_marginL}}>
                                    <p className='notosan-bold text-xl text-web-black'>หน่วยงานที่ร่วมดำเนินการ</p>

                                    {
                                        founder_org.map((x, i) => {
                                            return (
                                                <div key={i} className='flex gap-1 items-start'>
                                                    <p className='notosan-reg text-web-black'>{i + 1}.</p>
                                                    <p className='notosan-reg text-web-black grow'>{x}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                <div className='w-full h-fit mt-6 transition-all duration-1000 delay-200'
                                style={{opacity: content_opacity, marginLeft: content_marginL}}>
                                    <p className='notosan-bold text-xl text-web-black'>เว็บไซต์ที่เกี่ยวข้อง</p>
                                    
                                    {
                                        (url.length === 0) ?
                                            (!loading) ? 
                                                <div role="status" className="animate-pulse my-4">
                                                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                                                    <div className="w-48 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                                                </div>
                                            :
                                                <p className='notosan-reg text-web-black'>ไม่มีลิงก์ที่เกี่ยวข้อง</p>
                                        :
                                            url.map((x, i) => {
                                                return (
                                                    <Link key={i} to={x.url} target="_blank" rel="noopener noreferrer">
                                                        <p className='notosan-reg text-web-black cursor-default'>{i + 1}. <span className='notosan-reg text-web-black cursor-pointer hover:text-web-gold hover:underline'>{x.title}</span></p>
                                                    </Link>
                                                );
                                            })
                                    }
                                </div>

                                <div className='w-full h-fit mt-6 transition-all duration-1000 delay-300'
                                style={{opacity: content_opacity, marginLeft: content_marginL}}>
                                    <p className='notosan-bold text-xl text-web-black'>สถานะการดำเนินโครงการ/กิจกรรม</p>

                                    { (status === 'กำลังดำเนินการ') ? green_status(status) : null }
                                    { (status === 'เสร็จสิ้นการดำเนินงาน') ? green_status(status) : null }
                                    { (status === 'เสร็จสิ้นการดำเนินงาน และอยู่ระหว่างการขยายผล') ? green_status(status) : null }
                                    { (status === 'ยกเลิกโครงการ') ? red_status(status) : null }
                                    { (status === 'ระงับชั่วคราว') ? yellow_status(status) : null }
                                </div>

                                <div className='w-full h-fit mt-6 transition-all duration-1000 delay-500'
                                style={{opacity: content_opacity, marginLeft: content_marginL}}>
                                    <p className='notosan-bold text-xl text-web-black'>รายละเอียดของโครงการ/กิจกรรม</p>

                                    {
                                        (description.length === 0) ?
                                            (!loading) ? 
                                                <div role="status" className="max-w-sm animate-pulse my-6">
                                                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                                                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                                                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                                                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
                                                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
                                                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
                                                </div>
                                            :
                                                <p className='notosan-reg text-web-black'>ยังไม่มีรายละเอียดของโครงการ/กิจกรรม</p>
                                        :
                                            null
                                    }
                                    
                                    {
                                        description.map((x, i) => {
                                            return (
                                                <p key={i} className='notosan-reg text-web-black indent-10 sm:indent-20'>{x}</p>
                                            )
                                        })
                                    }
                                </div>

                                <div className='w-full h-fit mt-6 transition-all duration-1000 delay-700'
                                style={{opacity: content_opacity, marginLeft: content_marginL}}>
                                    <p className='notosan-bold text-xl text-web-black mb-2'>รายละเอียดการดำเนินการ</p>

                                    {
                                        (timeline.length === 0) ?
                                            (!loading) ? 
                                                <div role="status" className="max-w-sm animate-pulse my-6">
                                                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                                                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                                                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                                                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
                                                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
                                                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
                                                </div>
                                            :
                                                <p className='notosan-reg text-web-black'>ยังไม่มีรายละเอียดการดำเนินการ</p>
                                        :
                                            null
                                    }

                                    <Timeline events={timeline} small_size={false}/>
                                </div>
                            </div>
                        </div>

                        <button className='notosan-med w-full h-full bg-mahidol-blue hover:bg-mahidol-yellow py-3 text-white rounded-md mt-16' onClick={handle_close}>ปิดหน้านี้</button>
                    </div>
                </div>
            </div>

            { /* Modal backdrop */ }
            <div className={'absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-10 ' + (isShow ? 'block' : 'hidden')}></div>
        </>
    )
}

export default ModalTestimonial_detail